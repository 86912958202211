import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TagManager from 'react-gtm-module';
import { ComponentType } from "react";

const GTM_ID = 'GTM-KJ5VPHFZ';
TagManager.initialize({ gtmId: GTM_ID });

// WithTrackerOptionsインターフェースを定義
interface WithTrackerOptions {
  [key: string]: any;
}

// window.dataLayerの型定義を拡張
declare global {
  interface Window {
    dataLayer: any[];
  }
}

function withTracker<P extends object>(
  WrappedComponent: ComponentType<P>,
  options: WithTrackerOptions = {}
) {
  return function WithTrackerComponent(props: P) {
    const location = useLocation();

    useEffect(() => {
      // ページビューイベントをGTMのデータレイヤーに送信
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: {
          path: location.pathname,
          title: document.title,
          location: window.location.href
        },
        ...options
      });

      // X（旧Twitter）広告のイベントをGTMのデータレイヤーに送信
      window.dataLayer.push({
        event: 'twitterEvent',
        twitterEventName: 'tw-omqnz-omqo0',
      });
    }, [location]);

    return <WrappedComponent {...props} />;
  };
}

export default withTracker;