import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
const auth = firebase.auth();

import StripeConnectButton from "../components/StripeConnectButton";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

import { FiLogOut } from "react-icons/fi";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      margin: 10,
    },
    textContent: {
      // 新しいスタイル
      textAlign: "left",
      flex: 1,
      display: "inline-block", // テキストコンテンツをインラインブロックにします
      //width: "calc(100% - 135px)", // 画像の幅とマージンを差し引いた幅
      width: "90%",
      verticalAlign: "top", // テキストを上揃えにします
    },
    textLeft: {
      textAlign: "left",
      flex: 1,
    },
    textCenter: {
      textAlign: "center",
      flex: 1,
    },
    img100: {
      width: "100%",
    },
    imgCenter: {
      // 新規追加
      width: "90%",
      //margin: "15px", // テキストとの間隔
    },
    customTypography: {
      fontSize: "1.25rem", // ここで望むフォントサイズに調整します。h4とh5の中間のサイズに設定
      fontWeight: "bold", // ここで文字を太くします
    },
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
    clearBoth: {
      clear: "both",
    },
  });

interface ToDoListProps extends WithStyles<typeof styles> {}

interface ToDoListState {
  error: string;
  warning: string;
  info: string;
  success: string;
  urlcopy: boolean;
  user?: firebase.User | null;
}

class ToDoList extends React.Component<ToDoListProps, ToDoListState> {
  constructor(props: ToDoListProps) {
    super(props);
    this.state = {
      error: "",
      warning: "",
      info: "",
      success: "",
      urlcopy: false,
    };
  }

  componentDidMount = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          user: user,
        });
        console.log("log in");
      } else {
        console.log("not log in");
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={`${classes.root} ${classes.textLeft}`}>
        <Typography
          variant="h3"
          className={`${classes.textContent} ${classes.customTypography}`}
        >
          駐車場の自主管理について
        </Typography>
        <br />
        {this.state.error && <Alert severity="error">{this.state.error}</Alert>}
        {this.state.warning && (
          <Alert severity="warning">{this.state.warning}</Alert>
        )}
        {this.state.info && <Alert severity="info">{this.state.info}</Alert>}
        {this.state.success && (
          <Alert severity="success">{this.state.success}</Alert>
        )}

        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            除草と掃除:
          </Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            定期的に除草や清掃を行ってください。
            <br />
            特に清掃を怠るとゴミを捨てる利用者が増えて来ます。
          </Typography>
          <br />
        </Paper>
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            放置車両の確認:
          </Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            未払のまま放置されている車両は開示請求手続きを行って料金を請求してください。
            <br />
            盗難車の可能性があるので警察に放置車両として通報してください。
          </Typography>
          <br />
        </Paper>
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            看板や電灯設備の点検:
          </Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            看板や電灯設備が壊れていないか確認してください。
            <br />
            汚れた看板は機会損失の原因になります。
            <br />
          </Typography>
          <br />
        </Paper>
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            地面の点検:
          </Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            舗装されていない駐車場の場合車両の出入りで窪みが発生します。
            <br />
            定期的に埋め戻して平らに均してください。
            <br />
          </Typography>
          <br />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ToDoList);
