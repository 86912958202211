import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import firebase, { db } from '../Firebase';

import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
});

class Test extends React.Component {


  setallusersallitems() {
    let bufitemList = [];
    db.collection('users').doc()//.where("delete", "!=", true)//絞り込み
    .get().then((usersquerySnapshot) => {
        usersquerySnapshot.forEach((user) => {
          const userdat = user.data();
            console.log("user: " + userdat.ambassadordisplayName);
            console.log("user.MarkupRatio: " +userdat.MarkupRatio);

            //userに対する逐次処理はここ
            //userdat.MarkupRatio = 1;
            //db.collection('users').doc(user.id).set(userdat);
            db.collection('users').doc(user.id).collection('items')//.where("delete", "!=", true)//絞り込み
            .get().then((itemsquerySnapshot) => {
                itemsquerySnapshot.forEach((item) => {
                  let itemB = item.data()
                  //console.log("item:" + itemB.pageModule.title);
                  //console.log("Aname:" + itemB.ambassador.ambassadordisplayName);
                  //itemB.ambassador.MarkupRatio = 1;
                  db.collection('users').doc(user.id).collection('items').doc(item.id).set(itemB);                            
                })        
            })
        })
    })   
  }


//url https://qrparking.jp/xxx__test__xxx
  setitemdata(userid) {
    db.collection('users').doc(userid).collection('items')//.where("delete", "!=", true)//絞り込み
    .get().then((itemsquerySnapshot) => {
        itemsquerySnapshot.forEach((item) => {
          let itemB = item.data()
          //console.log("item:" + itemB.pageModule.title);
          //console.log("Aname:" + itemB.ambassador.ambassadordisplayName);
          //itemB.ambassador.MarkupRatio = 2;
          /*
          let tags = itemB.tags;
          if (tags) {
            var result = tags.filter(function( item ) {
              return item != 'sppALL';
            });
            result.push('sppALL');
            result.sort();
            itemB.tags = result;
          */
          if (itemB.delete) {
            itemB.status = "delete";
          }
          else {
            itemB.status = "available";         
          }
            db.collection('users').doc(userid).collection('items').doc(item.id).set(itemB);
        })        
    })
  }

  render() {
    this.setitemdata("pjN1W1a1V5ZF09iBPcSa5Zo5xb22");
    return "DONE"
  }
}

// Material-ui関連
Test.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};



// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(Test);