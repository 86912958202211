import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import StripeConnectButton from "../components/StripeConnectButton";

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  twittercolor: {
    "background-color": "#55acee",
  },
  providerbotton: {
    margin: "10px auto",
    "font-weight": "500",
    "font-size": "14px",
    "font-family": "Roboto,Helvetica,Arial,sans-serif",
    height: "auto",
    "line-height": "normal",
    "max-width": "220px",
    "min-height": "40px",
    padding: "8px 16px",
    "text-align": "left",
    border: "none",
    "border-radius": "3",
    "box-shadow":
      "0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)",
    transition:
      "box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1)",
    "will-change": "box-shadow",
  },
  flex: {
    width: "100%",
    "flex-direction": "row",
    display: "flex",
  },
  providericon: {
    height: "18px",
    width: "18px",
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  img70: {
    width: "70%",
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
});

const RegistrationCompletion = (props) => {
  const { classes } = props;
  const params = useParams();
  const { Status, UserId } = params;
  const [isRegistered, setIsRegistered] = useState(false);
  const auth = firebase.auth();

  useEffect(() => {
    const signInWithStripe = async () => {
      try {
        const functions = firebase.app().functions('asia-northeast2');
        const stripeAuth = functions.httpsCallable('saveStripeAccount');
        const result = await stripeAuth({ UserId });
        const customToken = result.data.customToken;
        const IsRegistered = result.data.IsRegistered;
        if (IsRegistered) {
          await auth.signInWithCustomToken(customToken);
          setIsRegistered(IsRegistered);            
        }
      } catch (error) {
        console.error('Error in signInWithStripe:', error);
      }
    };

    const handleStripeOAuthRedirect = async (code) => {
      try {
        const functions = firebase.app().functions('asia-northeast2');
        const stripeOAuthHandler = functions.httpsCallable('stripeOAuthCallback');
        const result = await stripeOAuthHandler({ code });
        const customToken = result.data.customToken; // 新たにカスタムトークンを取得する
        await auth.signInWithCustomToken(customToken);
        console.log('Stripe OAuth handled:', result.data);
        // 必要な処理を追加
      } catch (error) {
        console.error('Error handling Stripe OAuth redirect:', error);
      }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      handleStripeOAuthRedirect(code);
    } else if (UserId) {
      signInWithStripe();
    }
  }, [UserId]);

  return (
    <div>
      <h2>
        QRparkingオーナー<br />
        {Status==="reauth" ? <>"Stripeアカウントの登録が完了していません"</>:(isRegistered ? "ご登録が正常に完了しました" : "ログインが正常に完了しました")}
      </h2>
      <Paper  className={classes.root} elevation={1}>
        <Typography variant="h5" component="h3">
          {Status==="reauth"  ? ((
      <>
        登録処理はまだ完了していません<br />
        下記ボタンからいつでも再開可能です<br />
        <StripeConnectButton />
      </>
    ) ):(isRegistered ? "ご登録ありがとうございました" : "正常にログインが終了しました")}
        </Typography>
        <Typography component="p">
          収益の状況・登録内容の変更は左上のサイドメニューからご利用ください
        </Typography>
      </Paper>
    </div>
  );
};

RegistrationCompletion.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RegistrationCompletion);