import React from "react";
import { useStripe } from "@stripe/react-stripe-js";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";

// スタイルの定義
const useStyles = makeStyles({
  button: {
    backgroundColor: "#69b076",
    color: "#FFF",
    fontSize: 12,
    height: "auto",
    width: "auto",
    padding: "0px 30px",
  },
});

interface StripeConnectButtonProps {
  accountId?: string;
}

const StripeConnectButton: React.FC<StripeConnectButtonProps> = ({ accountId }) => {
  const classes = useStyles();

  const handleClick = async () => {

    // GTMのデータレイヤーにイベントをプッシュ
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'stripeLinkClick',
      accountId: accountId,
    });

    const functions = firebase.app().functions("asia-northeast2");
    const createStripeAccount = functions.httpsCallable("createStripeAccount");

    // accountIdが存在する場合のみオブジェクトに含める
    const response = await createStripeAccount(accountId ? { accountId } : {});

    const { url } = response.data;
    window.location.href = url;
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handleClick}
    >
      Stripe利用登録を開始する
      <br />
      Stripe認証を使って
      <br />
      QRパーキングにログインする
    </Button>
  );
};

export default StripeConnectButton;