import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// react-slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../slick-item.css";

import Button from "@material-ui/core/Button";

//import Checkout from '../components/Checkout';
import PurchaseButton from "../components/PurchaseButton";

//const MarkupRatio = 2.2;
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { db } from "../Firebase";
// firestore を初期化する処理をここに記述
const firestore = firebase.firestore();

import Webcam from "react-webcam";

import AddAPhoto from "@mui/icons-material/AddAPhoto";

// スタイル
const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: "left",
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
  marginreftten: {
    marginLeft: 10,
  },
  bottonstyle: {
    margin: 10,
    border: "none",
    "-webkit-filter": "grayscale(0)",
    "-moz-filter": "grayscale(0)",
    "-ms-filter": "grayscale(0)",
    "-o-filter": "grayscale(0)",
    filter: "grayscale(0)",
    opacity: 1,
  },
  bottonstyleselected: {
    margin: 6,
    "border-style": "solid",
    "border-width": "2px",
    "border-color": "red",
    "-webkit-filter": "grayscale(0)",
    "-moz-filter": "grayscale(0)",
    "-ms-filter": "grayscale(0)",
    "-o-filter": "grayscale(0)",
    filter: "grayscale(0)",
    opacity: 1,
  },
  bottonstyledisable: {
    margin: 10,
    border: "none",
    "-webkit-filter": "grayscale(100)",
    "-moz-filter": "grayscale(100)",
    "-ms-filter": "grayscale(100)",
    "-o-filter": "grayscale(100)",
    filter: "grayscale(100)",
    opacity: 0.5,
  },
  displaynone: {
    display: "none",
  },
});

// react-slick
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  centerMode: false,
  centerPadding: "20px",
  fade: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

class I3 extends React.Component {
  state = {
    item: {
      itemId: "initializeing",
      imageModule: { imagePathList: ["/images/Preloader_8.gif"] }, //Preloader_8.gif
      pageModule: { title: "loading", description: "loading" },
      priceModule: { minAmount: { value: 0 }, minActivityAmount: { value: 0 } },
      skuModule: {
        productSKUPropertyList: [
          {
            skuPropertyName: "loading",
            order: "",
            skuPropertyId: "",
            skuPropertyValues: [
              {
                skuPropertyImagePath: "",
                skuPropertyImageSummPath: "",
                propertyValueName: "",
              },
            ],
          },
        ],
        skuPriceList: [
          { skuPropIds: "", skuVal: [{ skuActivityAmount: { value: 0 } }] },
        ],
      },
      freightAmount: {
        shipping_price: "",
        shipping_info: "",
        shipping_delivery: "",
      },
      ambassador: { MarkupRatio: 1, ambassadordisplayName: "" },
    },
    ambassador: { MarkupRatio: 1 },
    mainimages: ["/images/Preloader_8.gif"],
    skuAmountvalue: [0, 0, 0, -1],
    selectedpropertyValueId: [],
    selectedpropertyValueLst: [],
    quantity: 1,
    isCameraOpen: false,
    downloadUrl: "",
    sessionId: "",
  };

  webcamRef = React.createRef();

  handleQuantityChange = (event) => {
    this.setState({ quantity: event.target.value });
  };

  getOrderCount = async (itemId) => {
    const coll = db.collection("order");
    const q = coll
      .where("productid", "==", itemId)
      .where("status", "==", "prepayment_completed");
    const snapshot = await q.get();
    return snapshot.size;
  };
  //データ取得
  //uidで指定したメンバーの値を取得
  getItem = async (ambassadorid, itemId) => {
    const docRef = db
      .collection("users")
      .doc(ambassadorid)
      .collection("items")
      .doc(itemId);
    const ambassadorRef = db.collection("users").doc(ambassadorid);
    //const docRef = db.collection("item").doc(itemId);
    const doc = await docRef.get();
    const ambassadordoc = await ambassadorRef.get();
    var skuAmountvalue = [0, 0, 0, -1];

    //ドキュメントの存在確認
    if (doc.exists) {
      const dat = doc.data();
      const ambassadordat = ambassadordoc.data();
      const odercount = await this.getOrderCount(itemId);
      const inventory = dat.skuModule.inventory - odercount;
      console.log("doc.exists itemId:" + dat.itemId);
      var mainimages = dat.imageModule.imagePathList;
      /*
      dat.skuModule.productSKUPropertyList.map(_lst => {
        _lst.skuPropertyValues.map(_lst2 => {
          _lst2.selected = false;
        });
      });
      */
      if ("minActivityAmount" in dat.priceModule) {
        skuAmountvalue[0] = dat.priceModule.minActivityAmount.value;
      } else if ("minAmount" in dat.priceModule) {
        skuAmountvalue[0] = dat.priceModule.minAmount.value;
      }
      if ("maxActivityAmount" in dat.priceModule) {
        skuAmountvalue[1] = dat.priceModule.maxActivityAmount.value;
      } else if ("maxAmount" in dat.priceModule) {
        skuAmountvalue[1] = dat.priceModule.maxAmount.value;
      }
      if (skuAmountvalue[0] == 0 && skuAmountvalue[1] != 0) {
        skuAmountvalue[0] = skuAmountvalue[1];
      } else {
        skuAmountvalue[1] = skuAmountvalue[0];
      }
      if (dat.status == "available") {
        this.setState({
          item: dat,
          ambassador: ambassadordat,
          mainimages: mainimages,
          skuAmountvalue: skuAmountvalue,
          inventory: inventory,
        });
      } else {
        this.setState({
          item_no_data: "true",
        });
      }
    } else {
      console.log("else");
      this.setState({
        item_no_data: "true",
      });
    }
  };

  //値を取得
  componentDidMount = () => {
    this.getItem(
      this.props.match.params.userId,
      this.props.match.params.itemId
    );
    // コンポーネントがマウントされた際に一意のセッションIDを生成
    const docRef = firestore.collection("orders").doc();
    this.setState({ sessionId: docRef.id });
  };

  chkskuPriceList = (selectedpropertyValueId, dat) => {
    var res = [0, 0, 0, -1];
    for (let i = 0; i < dat.skuModule.skuPriceList.length; i++) {
      console.log(
        "searchlist::" +
          this.searchlist(
            dat.skuModule.skuPriceList[i].skuPropIds,
            selectedpropertyValueId
          )
      );
      if (
        this.searchlist(
          dat.skuModule.skuPriceList[i].skuPropIds,
          selectedpropertyValueId
        )
      ) {
        //if (dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount) {
        console.log(
          "true::" +
            this.searchlist(
              dat.skuModule.skuPriceList[i].skuPropIds,
              selectedpropertyValueId
            )
        );
        if (
          dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount &&
            (dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value <
              res[0] ||
          res[0] == 0)
        ) {
          res[0] = dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value;
        }
        if (
          dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount &&
            (dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value >
              res[1] ||
          res[0] == 0)
        ) {
          res[1] = dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value;
        }
      } else {
        console.log(
          "false::" +
            this.searchlist(
              dat.skuModule.skuPriceList[i].skuPropIds,
              selectedpropertyValueId
            )
        );
        if (
          dat.skuModule.skuPriceList[i].skuVal.skuAmount &&
            (dat.skuModule.skuPriceList[i].skuVal.skuAmount.value < res[0] ||
          res[0] == 0)
        ) {
          res[0] = dat.skuModule.skuPriceList[i].skuVal.skuAmount.value;
        }
        if (
          dat.skuModule.skuPriceList[i].skuVal.skuAmount &&
            (dat.skuModule.skuPriceList[i].skuVal.skuAmount.value > res[1] ||
          res[0] == 0)
        ) {
          res[1] = dat.skuModule.skuPriceList[i].skuVal.skuAmount.value;
        }
      }
      if (res[3] < dat.skuModule.inventory) {
        res[3] = dat.skuModule.inventory; //在庫数をセット
      }
    }

    console.log(
      "dat.skuModule.productSKUPropertyList.length::" +
        dat.skuModule.productSKUPropertyList.length +
        " selectedpropertyValueId::" +
        selectedpropertyValueId.join(",")
    );
    if (
      dat.skuModule.productSKUPropertyList.length ==
      selectedpropertyValueId.filter(function (value) {
        return value != null;
      }).length
    ) {
      res[2] = 1;
    } else {
      res[3] = -1;
    }
    console.log("res1::" + res.join(","));
    return res;
  };

  searchlist = (skuPropIds, selectedpropertyValueId) => {
    var res = true;
    for (let i = 0; i < selectedpropertyValueId.length; i++) {
      if (selectedpropertyValueId[i] != null) {
        if (skuPropIds.indexOf(selectedpropertyValueId[i]) == -1) {
          res = false;
        }
      }
    }
    console.log(
      "res2::" +
        res +
        " :: " +
        skuPropIds +
        "::" +
        selectedpropertyValueId.join(",")
    );
    return res;
  };

  exchangeUrl = (s1) => {
    if (s1) {
      return s1.replace(
        /https:\/\/(\w+)\.alicdn/g,
        "https://qrparking.jp/api/$1.alicdn"
      );
    }
    return null;
  };

  selectButtonClick = (i1, i2, e) => {
    var dat = this.state.item;
    var mainimages = this.state.item.imageModule.imagePathList;
    var selectedpropertyValueId = this.state.selectedpropertyValueId;
    var skuAmountvalue = this.state.skuAmountvalue;
    var selectedpropertyValueLst = this.state.selectedpropertyValueLst;
    var spVId;
    var skuAv;

    // まず、現在選択されているプランを解除します
    dat.skuModule.productSKUPropertyList.forEach((_lst, c1) => {
      _lst.skuPropertyValues.forEach((_lst2, c2) => {
        if (_lst2.selectstatus === "selected") {
          _lst2.selectstatus = "chk";
          selectedpropertyValueId[c1] = null;
          selectedpropertyValueLst[c1] = null;
        }
      });
    });

    // 新しいプランを選択します
    selectedpropertyValueId[i1] =
      dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[
        i2
      ].propertyValueId;

    dat.skuModule.productSKUPropertyList.forEach((_lst, c1) => {
      _lst.skuPropertyValues.forEach((_lst2, c2) => {
        if (_lst2.propertyValueId === selectedpropertyValueId[i1]) {
          _lst2.selectstatus = "selected";
          selectedpropertyValueLst[c1] = _lst2.propertyValueDisplayName;
          skuAmountvalue = this.chkskuPriceList(selectedpropertyValueId, dat);
          if (_lst2.skuPropertyImagePath) {
            mainimages[0] = _lst2.skuPropertyImagePath.replace(
              "_50x50.jpg",
              "_640x640.jpg"
            );
          }
        } else if (_lst2.selectstatus !== "selected") {
          _lst2.selectstatus = "chk";
        }
      });
    });

    this.setState({
      item: dat,
      mainimages: mainimages,
      skuAmountvalue: skuAmountvalue,
      selectedpropertyValueLst: selectedpropertyValueLst,
      quantity: 0,
    });
  };

  openCamera = () => {
    this.setState({ isCameraOpen: true });
  };

  closeCamera = () => {
    this.setState({ isCameraOpen: false });
  };

  capturePhoto = async () => {
    try {
      if (this.webcamRef.current) {
        const imageSrc = this.webcamRef.current.getScreenshot();
        const base64data = imageSrc.split(",")[1];
        await this.uploadImage(base64data, "image/jpeg");
        this.closeCamera();
      }
    } catch (error) {
      console.error("Error capturing photo:", error);
      alert("Error capturing photo: " + error.message);
    }
  };

  retakePhoto = () => {
    this.setState({ downloadUrl: "" });
  };

  uploadImage = async (base64data, mimeType) => {
    try {
      const storageRef = firebase.storage().ref();
      const filename = "test";
      const imageRef = storageRef.child(
        `images/public/${this.state.sessionId}.jpg`
      );

      await imageRef.putString(base64data, "base64", { contentType: mimeType });

      const downloadUrl = await imageRef.getDownloadURL();
      this.setState({ downloadUrl: downloadUrl });
      console.log("Image uploaded:", downloadUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  render() {
    const { classes } = this.props;
    const videoConstraints = {
      facingMode: "environment",
      width: 1280,
      height: 720,
    };
    const bottonstyle = this.state.bottonstyle;
    const selectedpropertyValueLst = this.state.selectedpropertyValueLst;
    const mainimages = this.state.mainimages;
    const MarkupRatio = this.state.item.MarkupRatio
      ? this.state.item.MarkupRatio
      : this.state.ambassador.MarkupRatio;
    const inventoryVal = this.state.inventory;
    const ambassadordisplayName = this.state.ambassador.ambassadordisplayName;
    const ambassadorEmail = this.state.ambassador.email;
    const applicationFeeamountRate = this.state.ambassador
      .applicationFeeamountRate
      ? this.state.ambassador.applicationFeeamountRate
      : 0.05;
    const sessionId = this.state.sessionId;

    const hourlySku = this.state.item.skuModule.skuPriceList.find((sku) =>
      sku.skuPropIds.includes("10001")
    );
    const hourlyRate = hourlySku
      ? hourlySku.skuVal.skuActivityAmount?.value
      : null;

    const endDaySku = this.state.item.skuModule.skuPriceList.find((sku) =>
      sku.skuPropIds.includes("10002")
    );
    const dailyRate = endDaySku
      ? endDaySku.skuVal.skuActivityAmount?.value
      : null;

    const monthlySku = this.state.item.skuModule.skuPriceList.find((sku) =>
      sku.skuPropIds.includes("10003")
    );
    const monthlyRate = monthlySku
      ? monthlySku.skuVal.skuActivityAmount?.value
      : null;

    const subscriptionPlanId = this.state.subscriptionPlanId;
    const planId = this.state.planId;

    // ここ整理すること const res = data.replace(/[^0-9]/g, '');

    var title = this.state.item.pageModule.title
      ? this.state.item.pageModule.title
      : "";
    var description = this.state.item.pageModule.description
      ? this.state.item.pageModule.description
          .split(/(\n)/)
          .map((item, index) => {
            return <>{item.match(/\n/) ? <br /> : item}</>;
          })
      : "";
    //const description = this.state.item.pageModule.description;
    //const freightAmount = this.state.item.shippingModule.freightAmount.value;
    var freightAmount = 0;
    var freightAmountString = "";
    //本日から　i　日後のDate

    var unitPrice;
    var price;
    var pricetoLocaleString;
    var quantity = this.state.quantity;
    var skuAmountvalue = this.state.skuAmountvalue;
    var nowDate = new Date();
    var futureDate = nowDate;
    var arrivalExpectedDate;
    var feeType;
    if (skuAmountvalue[0] == skuAmountvalue[1]) {
      if (
        this.state.item.skuModule.productSKUPropertyList == undefined ||
        this.state.item.skuModule.productSKUPropertyList.length == 0
      ) {
        skuAmountvalue[2] = 1;
      }
      unitPrice = parseInt(skuAmountvalue[0] * MarkupRatio, 10);
      price = unitPrice;
      pricetoLocaleString = "合計￥" + (price * quantity).toLocaleString();

      if (selectedpropertyValueLst.includes("時間")) {
        var daystimes = quantity * 60 * 60 * 1000;
        futureDate = new Date(nowDate.getTime() + daystimes);
        feeType = "hourly";
      } else if (selectedpropertyValueLst.includes("終日(24時間まで)")) {
        var daystimes = quantity * 24 * 60 * 60 * 1000;
        futureDate = new Date(nowDate.getTime() + daystimes);
        feeType = "daily";
      } else if (selectedpropertyValueLst.includes("月極(翌月同日まで)")) {
        futureDate = new Date(nowDate.getTime()); // 現在の日付を新しいDateオブジェクトとしてコピー
        futureDate.setMonth(nowDate.getMonth() + quantity); // 現在の月に月数を加える
        feeType = "monthly";
      }
    } else {
      pricetoLocaleString =
        "単価￥" +
        parseInt(skuAmountvalue[0] * MarkupRatio, 10).toLocaleString() +
        " - ￥" +
        parseInt(skuAmountvalue[1] * MarkupRatio, 10).toLocaleString();
    }
    var month = futureDate.getMonth() + 1;
    var day = futureDate.getDate();
    var hours = futureDate.getHours(); // 時
    var minutes = futureDate.getMinutes(); // 分
    arrivalExpectedDate =
      "出庫予定時間： " + month + "/" + day + " " + hours + ":" + minutes;

    if (this.state.item_no_data) {
      return (
        <div>
          <div class="overflow">
            <Paper className={classes.root} elevation={1}>
              <Typography component="p">
                申し訳ございません
                <br />
                現在駐車を受け付けておりません
                <br />
              </Typography>
              <Typography component="p">{this.state.error}</Typography>
            </Paper>
          </div>
        </div>
      );
    }
    if (inventoryVal <= 0) {
      return (
        <div>
          <div class="overflow">
            <Paper className={classes.root} elevation={1}>
              <Typography component="p">
                たいへん申し訳ございません
                <br />
                満車のため現在駐車を受け付けておりません
                <br />
                空きスペースも月極契約があり、現在停められる場所はございません
                <br />
                恐れ入りますが速やかに出庫くださいますようお願い申し上げます
                <br />
                お手数をおかけして申し訳ございません
                <br />
                またのご利用をおまちしております
                <br />
              </Typography>
              <Typography component="p">{this.state.error}</Typography>
            </Paper>
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div class="overflow">{title}</div>
        <div className={classes.textcenter}>
          <Paper className={classes.root} elevation={1}>
            <Typography component="p">
              当駐車場はスマートフォンを使った
              <br />
              クレジットカード決済、
              <br />
              Google pay、Apple pay のみ
              <br />
              対応しています。
              <br />
              現金には対応していません。
              <br />
              ご利用には駐車したクルマを撮影して
              <br />
              前払いしてください。
              <br />
            </Typography>

            <div
              style={{
                width: "100%",
                minHeight: "400px",
                position: "relative",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "lightblue",
                padding: "10px",
                boxSizing: "border-box", // パディングを幅に含める
              }}
            >
              {!this.state.isCameraOpen && !this.state.downloadUrl && (
                <>
                  <div
                    style={{
                      position: "relative",
                      zIndex: 1,
                      textAlign: "center",
                      marginBottom: "40px",
                      width: "100%", // 幅を100%に設定
                    }}
                  >
                    <div
                      style={{ position: "relative", display: "inline-block" ,width: "100%"}}
                      onClick={this.openCamera}
                    >
                      <AddAPhoto
                        style={{
                          fontSize: "10rem",
                          color: "white",
                          opacity: 0.5,
                        }}
                      />
                      <p
                        style={{
                          transform: "translate(0%, -50%)",
                          color: "darkslateblue",
                          fontWeight: "bold",
                          width: "100%", // 幅を100%に設定
                          textAlign: "center",
                        }}
                      >
                        利用開始にはお車の写真が必要です
                        <br />
                        タップしてカメラの起動を許可してください
                      </p>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button onClick={this.openCamera}>カメラを起動</button>
                  </div>
                </>
              )}
              {this.state.isCameraOpen && !this.state.downloadUrl && (
                <>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "0",
                      paddingBottom: "100%", // アスペクト比を1:1に保つ
                      maxHeight: "calc(100vh - 120px)",
                      overflow: "hidden",
                      margin: "auto",
                    }}
                  >
                    <Webcam
                      audio={false}
                      ref={this.webcamRef}
                      videoConstraints={videoConstraints}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "100%",
                        height: "100%",
                        transform: "translate(-50%, -50%)",
                        objectFit: "cover",
                      }}
                      onClick={this.capturePhoto}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 2,
                        textAlign: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        padding: "15px",
                        borderRadius: "5px",
                        width: "90%", // 幅を90%に増加
                        maxWidth: "400px", // 最大幅を設定
                      }}
                    >
                      <p
                        style={{
                          margin: "0 0 10px 0",
                          fontSize: "16px",
                          lineHeight: "1.4",
                        }}
                      >
                        停車位置とナンバーが確認できるように縦のままお車を撮影してください
                      </p>
                      <button
                        onClick={this.capturePhoto}
                        style={{
                          marginTop: "10px",
                          padding: "8px 16px",
                          fontSize: "16px",
                          backgroundColor: "#4CAF50",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        撮影
                      </button>
                    </div>
                  </div>
                </>
              )}

              {this.state.downloadUrl && (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img
                    src={this.state.downloadUrl}
                    alt="Captured"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      zIndex: 1,
                      textAlign: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      padding: "10px",
                    }}
                  >
                    <p>
                      画像でナンバーが確認できたら
                      <br />
                      駐車時間を選択してください
                    </p>

                    <button
                      onClick={this.retakePhoto}
                      style={{
                        zIndex: 1,
                      }}
                    >
                      撮影をやりなおす
                    </button>
                  </div>
                </div>
              )}
            </div>

            <Typography component="p">
              {this.state.item.skuModule.productSKUPropertyList
                ? this.state.item.skuModule.productSKUPropertyList.map(
                    (_lst, i1) => {
                      return (
                        <div>
                          <p>
                            {_lst.skuPropertyName}:
                            {selectedpropertyValueLst[i1]}
                          </p>
                          {_lst.skuPropertyValues.map((_lst2, i2) => {
                            //console.log('render::' + i1+':'+ i2 +'selectstatus:'+_lst2.selectstatus);
                            return (
                              <>
                                {this.state.item.skuModule.skuPriceList.find(
                                  (sku) =>
                                    sku.skuPropIds.includes(
                                      _lst2.propertyValueId
                                    )
                                )?.skuVal.skuActivityAmount ? (
                                  <Button
                                    variant="contained"
                                    className={
                                      _lst2.selectstatus == "selected"
                                        ? classes.bottonstyleselected
                                        : _lst2.selectstatus == "disable"
                                        ? classes.bottonstyledisable
                                        : classes.bottonstyle
                                    }
                                    id={_lst2.propertyValueDisplayName}
                                    onClick={this.selectButtonClick.bind(
                                      this,
                                      i1,
                                      i2
                                    )}
                                  >
                                    {_lst2.skuPropertyImageSummPath ? (
                                      <img
                                        src={this.exchangeUrl(
                                          _lst2.skuPropertyImageSummPath
                                        )}
                                        alt={_lst2.propertyValueDisplayName}
                                        width="50px"
                                        height="50px"
                                      />
                                    ) : (
                                      _lst2.propertyValueDisplayName
                                    )}
                                    <br />
                                    <span>
                                      ￥
                                      {(
                                        this.state.item.skuModule.skuPriceList.find(
                                          (sku) =>
                                            sku.skuPropIds.includes(
                                              _lst2.propertyValueId
                                            )
                                        ).skuVal.skuActivityAmount.value *
                                        MarkupRatio
                                      ).toLocaleString()}
                                    </span>
                                  </Button>
                                ) : null}
                              </>
                            );
                          })}
                        </div>
                      );
                    }
                  )
                : ""}
            </Typography>

            <Typography variant="headline" component="h3">
              {selectedpropertyValueLst.includes("時間") ? (
                <>
                  予定駐車時間：
                  <Select
                    value={this.state.quantity}
                    onChange={this.handleQuantityChange}
                  >
                    {(() => {
                      const maxHours = dailyRate
                        ? Math.floor(dailyRate / hourlyRate)
                        : 24;
                      return Array.from(
                        { length: maxHours || 24 },
                        (_, i) => i + 1
                      )
                        .filter(
                          (hours) =>
                            !dailyRate || hours * hourlyRate < dailyRate
                        )
                        .map((number) => (
                          <MenuItem key={number} value={number}>
                            {number}
                          </MenuItem>
                        ));
                    })()}
                  </Select>
                  時間
                </>
              ) : selectedpropertyValueLst.includes("終日(24時間まで)") ? (
                <>
                  駐車予定日数(24時間単位)：
                  <Select
                    value={this.state.quantity}
                    onChange={this.handleQuantityChange}
                  >
                    {(() => {
                      const maxDays =
                        monthlyRate && dailyRate
                          ? Math.floor(monthlyRate / dailyRate)
                          : 31;
                      return Array.from(
                        { length: maxDays || 31 },
                        (_, i) => i + 1
                      )
                        .filter(
                          (days) =>
                            !monthlyRate ||
                            !dailyRate ||
                            days * dailyRate < monthlyRate
                        )
                        .map((number) => (
                          <MenuItem key={number} value={number}>
                            {number}
                          </MenuItem>
                        ));
                    })()}
                  </Select>
                  日
                </>
              ) : selectedpropertyValueLst.includes("月極(翌月同日まで)") ? (
                <>
                  駐車予定月数（カレンダーの同日まで）：
                  <Select
                    value={this.state.quantity}
                    onChange={this.handleQuantityChange}
                  >
                    {[...Array(24).keys()].map((number) => (
                      <MenuItem key={number + 1} value={number + 1}>
                        {number + 1}
                      </MenuItem>
                    ))}
                  </Select>
                  ヶ月
                </>
              ) : null}
            </Typography>

            <Typography variant="headline" component="h3">
              {pricetoLocaleString}
            </Typography>
            <Typography component="p">{arrivalExpectedDate}</Typography>
            <Typography component="p">{freightAmountString}</Typography>

            <Typography component="p">
              {selectedpropertyValueLst.join(",")}
            </Typography>
            <p>出庫時に必ず出庫手続きをお願いします。</p>
            <p>予定駐車時間を過ぎた場合は精算をお願いします。</p>
            <p>精算は通常料金のみで割増しやペナルティはありません。</p>
            <p>
              出庫処理をしていない場合、駐車駐車料金は加算され続けますのでご注意ください。
            </p>
            <p>追加のお支払いは自動的にカード決済されません。</p>
            <p>
              <a
                href="https://qrparking.jp/specifiedcommercialtransactionact#OverchargeSettlementPolicy"
                target="_blank"
              >
                後払い精算に関するポリシー
              </a>
              をご確認ください。
            </p>

            <p>
              支払いボタンは撮影して、駐車プラン（時間・終日・月極）を選択し、予定時間を入力すると押せるようになります。
            </p>
            <p>終日料金を超える時間、月極料金を超える日数は表示されません。</p>
            {
              //unhandled error StripeInvalidRequestError: You passed an empty string for 'line_items[0][price_data][product_data][description]'. We assume empty values are an attempt to unset a parameter; however 'line_items[0][price_data][product_data][description]' cannot be unset. You should remove 'line_items[0][price_data][product_data][description]' from your request or supply a non-empty value.
            }
            {this.state.downloadUrl &&
            skuAmountvalue[2] &&
            price &&
            this.state.quantity ? (
              <PurchaseButton
                status="prepayment_intent"
                productid={this.props.match.params.itemId}
                mode="payment"
                feeType={feeType}
                price={price + freightAmount}
                applicationFeeamountRate={applicationFeeamountRate}
                quantity={this.state.quantity}
                name={title}
                image={this.state.downloadUrl}
                description={
                  selectedpropertyValueLst &&
                  selectedpropertyValueLst.length > 0
                    ? selectedpropertyValueLst.join(",")
                    : "non_selection"
                }
                ambassadorid={this.props.match.params.userId}
                ambassadordisplayName={ambassadordisplayName}
                ambassadorEmail={ambassadorEmail}
                arrivalExpectedDate={arrivalExpectedDate}
                label="支払い"
                hourlyRate={hourlyRate}
                dailyRate={dailyRate}
                monthlyRate={monthlyRate}
                additionalFee={0}
                sessionId={sessionId}
                subscriptionPlanId={subscriptionPlanId}
                planId={planId}
              ></PurchaseButton>
            ) : (
              <Button variant="contained" className="disable">
                支払い
              </Button>
            )}
            <p>
              支払いボタンを押したら決済画面が表示されるまで少しお待ち下さい。
              <br />
              ページの有効期限が切れた場合は反応がなくなるので再表示してください。
            </p>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <p>
              無断駐車・未精算には開示請求により所有者に請求を行います。
              <br />
              場内での事件事故には一切の責任を負いません 。<br />
              緊急時連絡先：{description}
            </p>
          </Paper>
        </div>
        <Typography component="subtitle2">
          <Link to="/specifiedcommercialtransactionact">
            特定商取引法に基づく表記
          </Link>
        </Typography>
        <br />　
      </React.Fragment>
    );
  }
}

// Material-ui関連
I3.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(I3);
