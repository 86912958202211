import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { TextField,Typography } from '@material-ui/core';
//import Typography from '@material-ui/core/Typography';

import FileUpload from '../components/Fileupload.tsx';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  FileUpload: {
    margin: 10,
    border: 'none',
    width:'100%',
  },
});

class AddOptionDialog extends Component {
  constructor(props) {
    super(props)
    //console.log(props.opt.name)
    this.state = {
      //open: false,
      catNo: 0,
      editOptDlgIndex: [],
      opt: {
        name: props.opt ? props.opt.name : "",
        img: props.opt ? props.opt.img : "",
      }
    }
  }
    

  texthandleChange = e => {
    const params = this.state.opt;
    params[e.target.name] = e.target.value;
    this.setState({opt:params} );
  };

  setFilelist2 = (lst, dir) => {
    /*
    // Create a reference to the file to delete
var desertRef = storageRef.child('images/desert.jpg');

// Delete the file
desertRef.delete().then(() => {
  // File deleted successfully
}).catch((error) => {
  // Uh-oh, an error occurred!
});

    */
    var opt = this.state.opt;
    opt.img = lst[0]
    this.setState({opt:opt});
  }

  cancel2 = () => {
    this.props.cancel();
    this.setState({
      opt:{ 
        name: "",
        img:"",
      }
    }); 
  }

  componentDidMount = () => {
    this.state = {
      //open: false,
      catNo: this.props.catNo,
      editOptDlgIndex: this.props.editOptDlgIndex,
      opt: {
        name: this.props.opt ? this.props.opt.name : "",
        img: this.props.opt ? this.props.opt.img : "",
      }
    }
  }
  
  render() {
    const { classes, msg, isOpen, submit, cancel, ambassadorId, itemId, catNo, editOptDlgIndex, setFilelist, dialogstyle } = this.props;
    /*
    key = { addOptDlgIndex }
    msg={addOptDlg? "オプションを追加します::" + addOptDlgIndex :"オプションを編集します::" + editOptDlgIndex.join(',')}
    isOpen={ editOptDlg || addOptDlg}
    submit={this.addopt.bind(this)}
    cancel={() => { this.setState({ addOptDlg: false, editOptDlg: false,editOptDlgIndex: [], addOptDlgIndex: 0}) }}
    catNo={addOptDlgIndex}
    editOptDlgIndex={editOptDlgIndex}
    opt={ opt }
    // message={()=>{console.log("opt::: " + i1)}}
    dialogstyle = { "addOptDlg"}
    */
    console.log(editOptDlgIndex);

    const submit2 = (opt, catNo, editOptDlgIndex) => {
      //console.log(editOptDlgIndex&&editOptDlgIndex.length?editOptDlgIndex.join(","):"no_editOptDlgIndex")
      submit(opt,catNo,editOptDlgIndex);
      this.setState({
        opt:{ 
          name: "",
          img:"",
        }
      }); 
    }
    
    console.log('render catNo:', catNo)
    
    return (
        <div>
        <Dialog
            open={isOpen}
            keepMounted
            onClose={() => this.cancel2()}
            aria-labelledby="common-dialog-title"
            aria-describedby="common-dialog-description"
        >
          <DialogContent>
            {this.props.msg}
            {　<>
              <TextField
                id="name"
                name="name"
                label="名前"
                style={{ margin: 8 }}
                helperText={dialogstyle == "addOptDlg" ? "オプション名":"オプション項目名"}
                fullWidth
                margin="normal"
                variant="outlined"
                autoComplete='off'
  //              msg={console.log('render memo:',this.state.item.memo)}
                value={this.state.opt.name}
                onChange={e => this.texthandleChange(e)}
                required="true"
                multiline="true"
                />

              {dialogstyle == "addOptDlg"
                ? <>
                  {this.state.opt.img ?
                    <>
                      <img src={this.state.opt.img} alt={this.state.opt.name} width='100px' height='100px' /> <br />
                    </>
                    :
                    <></>
                  }
                <Typography component="p">
                  画像をつける場合は以下に
                </Typography>
                <FileUpload userId={ambassadorId} itemId={itemId} fileList={this.setFilelist2} acceptFile="" dir='options' maxFileSize='10485760000' className={classes.FileUpload} />
                  </>
                :
                <></>
              }
            </>
          }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.cancel2()} color="primary">
              キャンセル
            </Button>
            <Button onClick={() => submit2(this.state.opt,this.props.catNo,this.props.editOptDlgIndex)} color="primary">
              登録
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddOptionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddOptionDialog);