import React from "react";
import PropTypes from "prop-types";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import { Input } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { hslToRgb } from "@mui/system";

import firebase, { db } from "../Firebase";
import "firebase/firestore";
const firestore = firebase.firestore();

import PurchaseButton from "../components/PurchaseButton";

// スタイル
const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
  img: {
    width: "80%",
    display: "block",
    margin: "0 auto",
  },
});

class Checkout extends React.Component {
  constructor(Props) {
    super(Props);
    this.scrollToRef = React.createRef();
    this.reloadInterval = null;
  }

  state = {
    addresslist: [],
    orderdat: { status: "no_data" },
    shippingAddress: "",
    user: {
      zip: "",
      address1: "",
      address2: "",
      address3: "",
      name: "",
      tel: "",
    },
    error: "",
    additionalFee: 0,
    diffTimeString: "",
  };

  getSessionAndCustomer = async (sessionId) => {
    const getStripeSessionAndCustomerBySessionId = firebase
      .app()
      .functions("asia-northeast2")
      .httpsCallable("getStripeSessionAndCustomerBySessionId");
    const result = await getStripeSessionAndCustomerBySessionId(sessionId);
  };

  mailsend = async (orderdat) => {
    const generateEmailAndSend = firebase
      .app()
      .functions("asia-northeast2")
      .httpsCallable("generateEmailAndSend");
    generateEmailAndSend(orderdat).then((result) => {
      console.log("mailsend:" + result);
    });
  };

  getoderData = async (oderId) => {
    const orderdocRef = db.collection("/order").doc(oderId);
    const orderdoc = await orderdocRef.get();
    const success = this.props.match.params.success === "success";
    console.log("getdat::");

    if (orderdoc.exists && success) {
      console.log("orderdoc.exists");
      const orderdat = orderdoc.data();
      if (orderdat.status == "prepayment_intent") {
        await this.getSessionAndCustomer(orderdat.stripesessionid);
        setTimeout("window.location.reload(true)", 1000);
      } else if (orderdat.status == "prepayment_completed") {
        await this.getcustomerData(orderdat);
        await this.handleCalculateFee(orderdat);
      } else if (orderdat.status == "additionalFee_intent") {
        orderdat.status = "exit_completed";
        orderdocRef
          .set(orderdat, { merge: true })
          .then(async () => {
            await this.getcustomerData(orderdat);
            this.setState({
              orderdat: orderdat,
            });
          })
          .catch((error) => {
            console.log("Error updating document:", error);
          });
      } else {
        await this.getcustomerData(orderdat);
        this.setState({
          orderdat: orderdat,
          additionalFee: orderdat.additionalFee,
        });
      }
    } else {
      console.log("no_data_orderdoc");
      this.setState({
        orderdat: { status: "no_data" },
      });
    }
  };

  getcustomerData = async (orderdat) => {
    console.log("orderdat.email", orderdat.customerid);
    const customerRef = db.collection("/customer").doc(orderdat.customerid);
    const customerdoc = await customerRef.get();
    let customerdat = {};
    let addresslist = [];
    if (customerdoc.exists) {
      customerdat = customerdoc.data();
      console.log("get_customerdata:: ", customerdat);
    } else {
      console.log("no_customerdata");
    }
    this.setState({
      customer: customerdat,
      orderdat: orderdat,
    });
  };

  handleChange = (e) => {
    const params = this.state.user;
    params[e.target.name] = e.target.value;
    this.setState({ user: params });
  };

  sendmail = (data) => {
    const _sendmail = firebase
      .app()
      .functions("asia-northeast2")
      .httpsCallable("sendMail");
    _sendmail(data).then((result) => {
      console.log("result:" + result);
    });
  };

  sendSMS = (data) => {
    const _sendSMS = firebase
      .app()
      .functions("asia-northeast2")
      .httpsCallable("sendSMS");
    _sendSMS(data).then((result) => {
      console.log("result:" + result);
    });
  };

  submitonClick = () => {
    const orderdocRef = db
      .collection("/order")
      .doc(this.props.match.params.orderId);
    console.log("orderId::" + this.props.match.params.orderId)
    orderdocRef
      .set(
        {
          status: "exit_completed",
          checkoutTime: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )
      .then(setTimeout("window.location.reload(true)", 1000))
      .catch((error) => {
        console.log("Error updating document:", error);
      });
    this.sendmail({
      destination: ["info01@qrparking.jp"],
      subject: "出庫が正常終了しました",
      text: JSON.stringify(
        {
          customerdat: this.state.customerdat,
          orderdat: this.state.orderdat,
        },
        null,
        "\t"
      ),
    });
  };

  zenNum2HanNum = (num) => {
    var z = ["０", "１", "２", "３", "４", "５", "６", "７", "８", "９"];
    for (var i = 0; i < 10; i++) num = num.replace(new RegExp(z[i], "g"), i);
    num = num.replace(new RegExp("[^0-9]", "g"), "");
    return num;
  };

  componentDidMount = () => {
    this.getoderData(this.props.match.params.orderId);
    if (window.location.hash === "#reload") {
      this.scrollToElement();
    }
    this.startAutoReload();
  };

  componentWillUnmount() {
    this.stopAutoReload();
  }

  startAutoReload = () => {
    this.reloadInterval = setInterval(() => {
      this.handleCalculateFee();
    }, 60000);
  };

  stopAutoReload = () => {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  };

  scrollToElement = () => {
    if (this.scrollToRef.current) {
      this.scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  reloadAndScroll = () => {
    window.location.hash = "reload";
    window.location.reload();
  };

  PaymentInfo = ({ classes, orderdat }) => (
    <Paper className={classes.root} elevation={1}>
      入庫時間：
      {orderdat.timestamp
        .toDate()
        .toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })}
      <br />
      {orderdat.description} ￥{orderdat.price.toLocaleString()} X{" "}
      {orderdat.quantity}
      <br />
      前払い金合計：
      {"￥" + (orderdat.price * orderdat.quantity).toLocaleString()}
      <br />
      {orderdat.arrivalExpectedDate}
      <br />
      <br />
      延長清算金：
      {this.state.additionalFee
        ? "￥" + this.state.additionalFee.toLocaleString()
        : 0}
      {orderdat.monthlyRate && (
        <>
          <br />
          <span style={{ fontSize: "small" }}>
            月極料金：￥{orderdat.monthlyRate.toLocaleString()}
          </span>
        </>
      )}
      {orderdat.dailyRate && (
        <>
          <br />
          <span style={{ fontSize: "small" }}>
            終日料金：￥{orderdat.dailyRate.toLocaleString()}
          </span>
        </>
      )}
      {orderdat.hourlyRate && (
        <>
          <br />
          <span style={{ fontSize: "small" }}>
            時間料金：￥{orderdat.hourlyRate.toLocaleString()}
          </span>
        </>
      )}
      <br />
      {orderdat.checkoutTime
        ? "出庫時間：" + orderdat.checkoutTime.toDate().toLocaleString()
        : ""}
    </Paper>
  );

  ImagePaper = ({ classes, src, alt }) => (
    <Paper className={classes.root} elevation={1}>
      <img src={src} alt={alt} className={classes.img} />
    </Paper>
  );

  handleCalculateFee = async () => {
    const calculateAdditionalFee = firebase
      .app()
      .functions("asia-northeast2")
      .httpsCallable("calculateAdditionalFee");

    try {
      const result = await calculateAdditionalFee({
        price: this.state.orderdat.price,
        quantity: this.state.orderdat.quantity,
        startTime: this.state.orderdat.timestamp,
        hourlyRate: this.state.orderdat.hourlyRate,
        dailyRate: this.state.orderdat.dailyRate,
        monthlyRate: this.state.orderdat.monthlyRate,
      });

      if (
        result.data &&
        result.data.additionalFee !== undefined &&
        result.data.diffTimeString
      ) {
        this.setState({
          additionalFee: result.data.additionalFee,
          diffTimeString: result.data.diffTimeString,
        });
      } else {
        console.error("Received data is not in expected format.");
      }
    } catch (error) {
      console.error("Error calculating parking fee:", error);
    }
  };

  render() {
    console.log("render");
    const { classes } = this.props;
    const { orderdat, error, additionalFee, diffTimeString } = this.state;
    const success = this.props.match.params.success === "success";
    const PaymentInfo = this.PaymentInfo;
    const ImagePaper = this.ImagePaper;

    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }

    if (success) {
      switch (orderdat.status) {
        case "no_data":
          return (
            <div>
              <div className="overflow">
                <Paper className={classes.root} elevation={1}>
                  <CircularProgress />
                  <Typography component="p">データの取得中です</Typography>
                </Paper>
              </div>
            </div>
          );
        case "prepayment_intent":
          return (
            <div>
              <h2>
                ただいまカード会社の決済を待っています
                <br />
                しばらくお待ちください
              </h2>
              <div className={classes.textLeft}>
                <ImagePaper
                  classes={classes}
                  src={orderdat.image}
                  alt="picture"
                />
                <PaymentInfo
                  classes={classes}
                  orderdat={orderdat}
                  additionalFee={additionalFee}
                />
              </div>
            </div>
          );
        case "additionalFee_intent":
          return (
            <div>
              <h2>
                ただいまカード会社の決済を待っています
                <br />
                しばらくお待ちください
              </h2>
              <div className={classes.textLeft}>
                <ImagePaper
                  classes={classes}
                  src={orderdat.image}
                  alt="picture"
                />
                <PaymentInfo
                  classes={classes}
                  orderdat={orderdat}
                  additionalFee={additionalFee}
                />
              </div>
            </div>
          );
        case "prepayment_completed":
          return (
            <div className={classes.root}>
              <h2>前払い決済が成功しました</h2>
              {additionalFee
                ? additionalFee.toLocaleString() && (
                    <Typography variant="headline" component="h3">
                      <span
                        style={{
                          color: "rgb(181, 49, 49)",
                          fontWeight: "bold",
                        }}
                      >
                        超過料金が発生しています。
                      </span>
                      <br />
                      出庫時に超過料金を精算してください。
                    </Typography>
                  )
                : ""}
              <div className={classes.textLeft}>
                <ImagePaper
                  classes={classes}
                  src={orderdat.image}
                  alt="picture"
                />
                <PaymentInfo
                  classes={classes}
                  orderdat={orderdat}
                  additionalFee={additionalFee}
                />
                <Paper className={classes.root} elevation={1}>
                  <Typography variant="headline" component="h3">
                    <span style={{ fontWeight: "bold" }}>{diffTimeString}</span>
                  </Typography>
                  <Typography variant="headline" component="h3">
                    {additionalFee ? (
                      <>
                        超過料金は
                        <span
                          style={{
                            color: "rgb(181, 49, 49)",
                            fontWeight: "bold",
                          }}
                        >
                          {additionalFee.toLocaleString()}円
                        </span>
                        です。
                        <br />
                        追加のお支払いは自動的にカード決済されません。
                        <br />
                        下の「精算して出庫する」ボタンから精算して出庫してください。
                      </>
                    ) : (
                      <>現在精算金はありません。</>
                    )}
                  </Typography>
                  <Typography component="p">
                    終日は２４時間、月極は翌月の同日までの料金です。
                    <br />
                    出庫時に必ず出庫手続きをお願いします。
                    <br />
                    出庫処理をしていない場合、駐車駐車料金は加算され続けますのでご注意ください。
                    <br />
                    <a
                      href="https://qrparking.jp/specifiedcommercialtransactionact#OverchargeSettlementPolicy"
                      target="_blank"
                    >
                      後払い精算に関するポリシー
                    </a>
                    をご確認ください。
                  </Typography>
                  <Typography variant="headline" component="h3">
                    未精算には開示請求により所有者に請求させていただきます。
                    <br />
                    未精算のまま駐車場を離れてしまった場合も下のボタンから出庫してください。
                  </Typography>
                </Paper>
                <form className={classes.root} noValidate autoComplete="off">
                  <Typography className={classes.textCenter} component="p">
                    {additionalFee ? (
                      <PurchaseButton
                        status="additionalFee_intent"
                        productid={this.props.match.params.itemId}
                        mode="payment"
                        feeType={orderdat.feeType}
                        price={orderdat.price}
                        applicationFeeamountRate={
                          orderdat.applicationFeeamountRate
                        }
                        additionalFee={additionalFee}
                        quantity={1}
                        name={orderdat.name}
                        image={orderdat.image}
                        description={orderdat.description}
                        ambassadorid={orderdat.ambassadorid}
                        ambassadordisplayName={orderdat.ambassadordisplayName}
                        ambassadorEmail={orderdat.ambassadorEmail}
                        arrivalExpectedDate={orderdat.arrivalExpectedDate}
                        label="精算して出庫する"
                        sessionId={orderdat.sessionId}
                        hourlyRate={orderdat.hourlyRate}
                        dailyRate={orderdat.dailyRate}
                        monthlyRate={orderdat.monthlyRate}
                        subscriptionPlanId={orderdat.subscriptionPlanId}
                        planId={orderdat.planId}
                      />
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.margin}
                        onClick={this.submitonClick}
                      >
                        出庫する
                      </Button>
                    )}
                  </Typography>
                  <br />
                  <Typography className={classes.textCenter} component="p">
                    <a
                      href="#"
                      onClick={this.reloadAndScroll}
                      ref={this.scrollToRef}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.margin}
                      >
                        画面更新して料金を確認する
                      </Button>
                    </a>
                  </Typography>
                </form>
              </div>
            </div>
          );
        case "exit_completed":
          return (
            <div>
              <h2>ありがとうございました</h2>
              駐車料金を下記の通り承り、出庫手続きが完了しました
              <h3>またのご利用を心よりお待ちしております</h3>
              <div className={classes.textLeft}>
                <ImagePaper
                  classes={classes}
                  src={orderdat.image}
                  alt="picture"
                />
                <PaymentInfo classes={classes} orderdat={orderdat} />
                <a href={`/i3/${orderdat.ambassadorid}/${orderdat.productid}`}>
                  <Paper className={classes.textCenter} elevation={1}>
                    <Typography variant="headline" component="h3">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.margin}
                      >
                        新規で再度駐車登録する
                      </Button>
                    </Typography>
                  </Paper>
                </a>
              </div>
            </div>
          );
        case "exit_terminated":
          return (
            <div>
              <h2>ありがとうございました</h2>
              駐車料金を下記の通り承り、管理者が出庫手続きを完了しました
              <h3>出庫時には出庫処理をお願いいたします</h3>
              <h3>またのご利用を心よりお待ちしております</h3>
              <p>領収書は前払い、後払い精算それぞれ別のメールで届いております</p>
              <p>ご確認ください</p>
              <div className={classes.textLeft}>
                <ImagePaper
                  classes={classes}
                  src={orderdat.image}
                  alt="picture"
                />
                <PaymentInfo classes={classes} orderdat={orderdat} />
                <a href={`/i3/${orderdat.ambassadorid}/${orderdat.productid}`}>
                  <Paper className={classes.textCenter} elevation={1}>
                    <Typography variant="headline" component="h3">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.margin}
                      >
                        新規で再度駐車登録する
                      </Button>
                    </Typography>
                  </Paper>
                </a>
              </div>
            </div>
          );
        default:
          return (
            <Alert severity="error">
              予期しない状態です。管理者にお問い合わせください。
            </Alert>
          );
      }
    } else if (orderdat) {
      switch (orderdat.status) {
        case "no_data":
          return (
            <div>
              <h2>キャンセルされました</h2>
              <div className={classes.textLeft}>
                <Paper className={classes.root} elevation={1}>
                  <Typography variant="headline" component="h3">
                    ブラウザの戻るボタンか
                    <br />
                    QRコードを読み取って
                    <br />
                    受付画面に戻ってください
                  </Typography>
                </Paper>
              </div>
            </div>
          );
        case "prepayment_intent":
          return (
            <div>
              <h2>前払い料金のカード決済がキャンセルされました</h2>
              <div className={classes.textLeft}>
                <ImagePaper
                  classes={classes}
                  src={orderdat.image}
                  alt="picture"
                />
                <PaymentInfo classes={classes} orderdat={orderdat} />
                <Paper className={classes.root} elevation={1}>
                  決済総額：
                  {"￥" + (orderdat.price * orderdat.quantity).toLocaleString()}
                  <br />
                  カード会社にお問い合わせください
                </Paper>
              </div>
              <PurchaseButton
                status="additionalFee_intent"
                productid={this.props.match.params.itemId}
                mode="payment"
                feeType={orderdat.feeType}
                price={orderdat.price}
                additionalFee={additionalFee}
                quantity={orderdat.quantity}
                name={orderdat.name}
                image={orderdat.image}
                description={orderdat.description}
                ambassadorid={orderdat.ambassadorid}
                ambassadordisplayName={orderdat.ambassadordisplayName}
                ambassadorEmail={orderdat.ambassadorEmail}
                arrivalExpectedDate={orderdat.arrivalExpectedDate}
                label="再度決済を試みる"
                sessionId={sessionId}
                hourlyRate={orderdat.hourlyRate}
                dailyRate={orderdat.dailyRate}
                monthlyRate={orderdat.monthlyRate}
                subscriptionPlanId={orderdat.subscriptionPlanId}
                planId={orderdat.planId}
              />
            </div>
          );
        case "additionalFee_intent":
          return (
            <div>
              <h2>延長料金のカード決済がキャンセルされました</h2>
              <div className={classes.textLeft}>
                <ImagePaper
                  classes={classes}
                  src={orderdat.image}
                  alt="picture"
                />
                <PaymentInfo classes={classes} orderdat={orderdat} />
                <Paper className={classes.root} elevation={1}>
                  カード決済額：{"￥" + additionalFee.toLocaleString()}
                  <br />
                  カード会社にお問い合わせください
                </Paper>
                <Paper className={classes.root} elevation={1}>
                  <Typography variant="headline" component="h3">
                    決済画面に戻る
                  </Typography>
                </Paper>
              </div>
              <PurchaseButton
                status="additionalFee_intent"
                productid={this.props.match.params.itemId}
                mode="payment"
                feeType={orderdat.feeType}
                price={orderdat.price}
                additionalFee={additionalFee}
                quantity={1}
                name={orderdat.name}
                image={orderdat.image}
                description={orderdat.description}
                ambassadorid={orderdat.ambassadorid}
                ambassadordisplayName={orderdat.ambassadordisplayName}
                ambassadorEmail={orderdat.ambassadorEmail}
                arrivalExpectedDate={orderdat.arrivalExpectedDate}
                label="再度精算して出庫"
                sessionId={sessionId}
                hourlyRate={orderdat.hourlyRate}
                dailyRate={orderdat.dailyRate}
                monthlyRate={orderdat.monthlyRate}
                subscriptionPlanId={orderdat.subscriptionPlanId}
                planId={orderdat.planId}
              />
            </div>
          );
        default:
          return (
            <Alert severity="error">
              予期しない状態です。管理者にお問い合わせください。
            </Alert>
          );
      }
    }
  }
}

// Material-ui関連
Checkout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(Checkout);
