import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Search from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import { MenuItem as Mi } from "@material-ui/core/";
//import Accordion from '@material-ui/core/Accordion';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import firebase, { db } from "../Firebase";
import { DataUsageTwoTone } from "@material-ui/icons";
import QRCode from "qrcode";

// コンポーネントの準備
import Item from "../components/Item";
import MenuItem from "../components/MenuItem";

// スタイル
const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    textAlign: "center",
    align: "center",
    width: "90%",
  },
  textcenter: {
    width: "100%",
    align: "center",
    "white-space": "nowrap",
    overflow: "hidden",
    "text-overflow": "ellipsis",
    "-webkit-text-overflow": "ellipsis",
    "-o-text-overflow": "ellipsis",
    textAlign: "center",
    "text-align": "center",
  },
  accordionSummary: {
    height: "15px",
    "min-height": "15px",
    padding: "0",
    margin: "0",
  },
  accordionDetails: {
    padding: "0",
    margin: "0",
    display: "flex",
    "flex-wrap": "wrap",
    //    'flex-flow': 'column',
  },
  expanded: {
    "min-height": "15px",
    padding: "0",
    margin: "0",
  },
  resize: {
    height: "15px",
    fontSize: "12px",
    padding: "0",
    margin: "0",
    width: "20px",
  },
  searchbox: {
    width: "100%",
    display: "flex",
    "flex-wrap": "wrap",
    "flex-direction": "row",
    "justify-content": "space-evenly",
  },
  categlybox: {
    width: "100%",
    display: "flex",
    "flex-wrap": "wrap",
    "flex-direction": "row",
    "justify-content": "space-evenly",
  },
  inlinetext: {
    top: "-10px",
    fontSize: "12px",
  },
  searchbottn: {
    top: "-5px",
    "line-height": "16px",
  },
  serchtext: {
    width: "50px",
  },
  pricetext: {
    width: "50px",
  },
  selectSortType: {
    fontSize: "10px",
    "line-height": "0px",
  },
});

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      favCategory: [],
      currentCategory: {
        categoryId: "0",
        categoryEnName: "root",
        categoryName: "Top",
      },
      categoryLst: [],
      OrderList: [],
      getting: false,
      page: 1,
      error: "",
    };
  }

  //python.get()
  get = (dic, key) => {
    if (dic && key in dic) return dic[key];
    return null;
  };

  setData = (OrderList, categorylst) => {
    let bufOrderList = [];
    const page = this.state.page;
    if (page > 1) {
      bufOrderList = bufOrderList.concat(this.state.OrderList);
    }
    bufOrderList = bufOrderList.concat(OrderList.mods.OrderList.content);
    console.log("OrderList.length", bufOrderList.length);
    this.setState({
      error: "",
      OrderList: bufOrderList,
      categoryLst: categorylst.childCategories,
      pathCategories: categorylst.pathCategories,
      getting: false,
    });
  };

  itemChange = null;

  getMylist = () => {
    let query = db
      .collection("order")
      .where("ambassadorid", "==", this.props.userdata.uid)
      .where("status", "==", "prepayment_completed")
      .orderBy("dt", "desc")
      .limit(50);
  
    if (this.state.lastVisible) {
      query = query.startAfter(this.state.lastVisible);
    }
  
    if (!this.itemChange) {
      this.itemChange = query.onSnapshot((querySnapshot) => {
        let bufOrderList = [];
        querySnapshot.forEach((doc) => {
          const docdat = doc.data();
          docdat.id = doc.id;  // ドキュメントIDを追加
          bufOrderList.push(docdat);
        });
  
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  
        this.setState((prevState) => ({
          error: "",
          OrderList: this.state.lastVisible ? [...prevState.OrderList, ...bufOrderList] : bufOrderList,
          getting: false,
          hasMore: querySnapshot.docs.length === 50, // 50件取得できた場合、まだデータがある可能性がある
          lastVisible: lastVisible || null,
        }));
      });
    }
  };
  
  // 次のページを取得するメソッド
  getNextPage = () => {
    if (this.state.hasMore) {
      this.getMylist();
    }
  };

  userdataset = (key, value) => {
    db.collection("users")
      .doc(this.props.userdata.uid)
      .set({ [key]: value }, { merge: true });
  };

  setRootCategory = async () => {
    if (this.state.categoryroot) {
      this.setState({
        categoryLst: this.state.categoryroot,
        favCategory: this.props.userdata.favCategory,
        OrderList: [],
        currentCategory: {
          categoryId: "0",
          categoryEnName: "root",
          categoryName: "Top",
        },
        page: 1,
      });
    } else {
      const docref = await db.collection("aliCategorylist").doc("root").get();
      if (docref.exists) {
        const lst = await docref.data();
        this.setState({
          categoryroot: lst.item,
          categoryLst: lst.item,
          favCategory: this.props.userdata.favCategory,
          OrderList: [],
          currentCategory: {
            categoryId: "0",
            categoryEnName: "root",
            categoryName: "Top",
          },
          page: 1,
        });
      }
    }
  };

  setStart = () => {
    if (this.props.userdata.currentCategory) {
      this.setState(
        {
          favCategory: this.props.userdata.favCategory,
          currentCategory: this.props.userdata.currentCategory,
          OrderList: [],
          page: 1,
          error: "",
        },
        () => {
          this.getData();
        }
      );
    } else {
      this.setRootCategory();
    }
  };

  getData = async () => {
    if (this.itemChange) {
      this.itemChange();
      this.itemChange = null;
    }
    this.setState({ error: "no_data" });
    fetch(url)
      .then(
        (result) => {
          if (result.status == 200) {
            console.log("getData stats 200");
            return result.json();
          } else {
            this.setState({
              error: result.body,
            });
          }
        },
        // 補足：コンポーネント内のバグによる例外を隠蔽しないためにも
        // catch()ブロックの代わりにここでエラーハンドリングすることが重要です
        (error) => {
          this.setState({
            error: error,
          });
        }
      )
      .then((jsondata) => {
        /* data = {
            u'items':items,
            u'refineCategory':refineCategory,
            u'categoryName':categoryName,
            u'categoryId':categoryId,
            u'dt':dt.now(UTC),
            u'request_args':args,
            u'categoryUrl':categoryUrl,
            u'listdata':listdata,
            u'allcookies':allcookies
        }*/
        try {
          if (
            jsondata.listdata.success == true ||
            jsondata.listdata.success == "true"
          ) {
            this.setData(
              jsondata.listdata,
              jsondata.refineCategory[0]
                ? jsondata.refineCategory[0]
                : this.state.categoryroot
            );
          } else {
            this.setState({
              error: "data-error",
              getting: false,
            });
          }
        } catch (error) {
          console.error("There has been a problem with your jsondata:", error);
          this.setState({
            error: "data-error",
            getting: false,
          });
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        this.setState({
          error: error,
          getting: false,
        });
      });
  };

  //値を取得
  componentDidMount = () => {
    window.addEventListener(
      "scroll",
      (event) => this.watchCurrentPosition(),
      true
    );
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.getMylist();
      } else {
        this.setState({
          error: "not login",
        });
      }
    });
  };
  componentWillUnmount() {
    window.removeEventListener(
      "scroll",
      (event) => this.watchCurrentPosition(),
      true
    );
  }

  watchCurrentPosition() {
    const pos =
      this.topParts.clientHeight + this.topParts.getBoundingClientRect().top; //clientHeight: 42710  document.getElementById("scrolldiv").getBoundingClientRect().top
    const page = this.state.page;
    if (pos > window.OrderListrepos) {
      if (!this.state.accordionExpanded) {
        this.setState({ accordionExpanded: true });
      }
    } else {
      if (this.state.accordionExpanded) {
        this.setState({ accordionExpanded: false });
      }
    }

    window.OrderListrepos = pos;
    if (pos < 10000 && this.state.getting == false) {
      console.log("pos: ", pos);
      this.setState(
        {
          getting: true,
          page: page + 1,
        },
        () => {
          this.getData();
        }
      );
    }
  }
  
  getStatusMessage = (status) => {
    const statusMessages = {
      'prepayment_intent':'入庫前払い処理中',
      'additionalFee_intent':'出庫清算処理中',
        'exit_completed': '正常に出庫しました',
        'exit_terminated': '強制出庫されました'
    };

    return statusMessages[status] ||  `ステータス不明: ${status}`;
}

  render() {
    // Material-ui関連
    const { classes } = this.props;
    const Items = this.state.OrderList ? this.state.OrderList : [];
    const uid = this.props.userdata.uid;
    let renderCards = [];
    let itemCards = [];
    let emptyCards = [];
    const ItemsLength = Items ? Items.length : 0;
    //商品
    for (let index = 0; index < ItemsLength; index++) {
      id,additionalFee,arrivalExpectedDate,customerid,dailyRate,description,email,feeType,hourlyRate,image,label,monthlyRate,name,nextSendTime,phone,price,productname,status,timestamp
      const price = Items[index].prices.salePrice
        ? parseInt(
            Items[index].prices.salePrice.formattedPrice.replace(/[^0-9]/g, "")
          )
        : 0;
      const price2 = parseInt(price * this.props.userdata.MarkupRatio, 10);
      const trueprice = "￥ " + (price2).toLocaleString();
      const href =
        "/itemedit3/" +
        Items[index].productId +
        "?domain=" +
        Items[index].domain;
      const itemid = Items[index].itemId;
      const status = Items[index].status
        ? Items[index].status === "available"
          ? "公開"
          : "非公開"
        : "";
      //      if ((price2 + shippingprice2) > 150) {
      itemCards.push(
        <Item
          empty={false}
          title={title}
          imgUrl={imgUrl}
          price={trueprice}
          href={href}
          uid={uid}
          itemid={itemid}
          getMylist={this.getMylist}
          deletable={itemid}
          status={status}
        />
      );
      //      }
    }

    renderCards.push(itemCards);

    // 読み込み中はロード画面にする
    const errors = () => {
      switch (this.state.error) {
        case "data-error":
          return <p>データがありません</p>;
        case "not login":
          return <p>ログインが必要です</p>;
        case "no_data":
          return (
            <p>
              <CircularProgress /> <p>データを取得しています</p>
            </p>
          );
        default:
          return this.state.error ? <p>{this.state.error}</p> : "";
      }
    };

    return (
      <div>
        {this.get(this.state, "error") ? (
          <div class="overflow">{errors()}</div>
        ) : (
          ""
        )}

        <br />
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open("/itemedit3/new", "_blank")}
          >
            現在の駐車状況
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open("/itemedit3/new", "_blank")}
          >
            過去の駐車履歴
          </Button>
        </div>
        <div class="overflow"></div>

        <div
          className={classes.textcenter}
          ref={(div) => {
            this.topParts = div;
          }}
          id="scrolldiv"
        >
          {renderCards} {/* 無限スクロールで表示する項目 */}
          {this.state.getting ? <CircularProgress /> : ""}
        </div>
      </div>
    );
  }
}

// Material-ui関連
OrderList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(OrderList);
