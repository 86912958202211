import React from "react";
import { Link } from "react-router-dom";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core";

// スタイルを定義
const styles = (theme: Theme) =>
  createStyles({
    titleImage: {
      width: "100%",
      maxWidth: 700,
    },
    root: {
      width: "80%", // 変更前: width: '80%'
      marginLeft: "auto", // 追加
      marginRight: "auto", // 追加
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      margin: theme.spacing(1),
      overflow: "hidden",
      listStyle: "none",
    },
    listItem: {
      padding: "8px 16px", // 各リストアイテムのパディング
      backgroundColor: "#f0f0f0", // 背景色
      margin: "4px 0", // マージン
      borderRadius: "4px", // 角の丸み
    },
    contentContainer: {
      display: "block",
    },
    textContent: {
      // 新しいスタイル
      textAlign: "left",
      flex: 1,
      display: "inline-block", // テキストコンテンツをインラインブロックにします
      //width: "calc(100% - 135px)", // 画像の幅とマージンを差し引いた幅
      width: "90%",
      verticalAlign: "top", // テキストを上揃えにします
    },
    textLeft: {
      textAlign: "left",
      flex: 1,
    },
    textRight: {
      textAlign: "right",
      "text-decoration": "underline",
      flex: 1,
    },
    imgCenter: {
      // 新規追加
      height: "300px",
      margin: "15px", // テキストとの間隔
    },
    imgLeft: {
      // 新規追加
      height: "120px",
      float: "left", // テキストの左側に画像を配置
      marginRight: "15px", // テキストとの間隔
    },
    imgRight: {
      // 新規追加
      height: "120px",
      float: "right", // テキストの右側に画像を配置
      marginLeft: "15px", // テキストとの間隔
    },
    clearBoth: {
      // 新しいスタイル
      clear: "both", // フロートされた要素の影響をクリアします
    },
    customTypography: {
      fontSize: "1.25rem", // ここで望むフォントサイズに調整します。h4とh5の中間のサイズに設定
      fontWeight: "bold", // ここで文字を太くします
    },
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
  });

// Propsの型を定義
interface Props extends WithStyles<typeof styles> {}

class ProductCatalog extends React.Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <h3>
          QRパーキング・オンラインショップ
          <br />
        </h3>
        <br />
        <h4>駐車場経営を簡単実現にする便利アイテム</h4>
        <div className={classes.clearBoth}></div> {/* フロートのクリア */}
        <br />
        <div>
          <Paper className={classes.root} elevation={1}>
            <a
              href="https://qrparking.jp/i/acct_1PF4utPmGNuqXXac/vjbCRoopNXQNiKwLljHp"
              target="_blank"
            >
              <Typography
                variant="h3"
                className={`${classes.textContent} ${classes.customTypography}`}
              >
                QRコード付き自立看板45x90
              </Typography>
              <br />
              <Typography
                component="p"
                className={`${classes.textRight}`}
              >
                ＞＞詳しくはこちら
              </Typography>
              <br />
              <br />
              <Typography component="p" className={`${classes.textContent}`}>
                QRコードの付いた自立型立て看板です。
                <br />
                この立て看板を設置したら即営業可能！
              </Typography>
              <div className={classes.contentContainer}>
                <img
                  src="/images/siteimage/QRparkig看板.jpg"
                  className={classes.imgCenter}
                />
              </div>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </a>
          </Paper>
          <br />
          <Paper className={classes.root} elevation={1}>
            <a
              href="https://qrparking.jp/i/acct_1PF4utPmGNuqXXac/EZAWzNAhaxXR5dzlmaOF"
              target="_blank"
            >
              <Typography
                variant="h3"
                className={`${classes.textContent} ${classes.customTypography}`}
              >
                45X90 QRコードプリントステッカー
              </Typography>
              <br />
              <Typography
                component="p"
                className={`${classes.textRight}`}
              >
                ＞＞詳しくはこちら
              </Typography>
              <br />
              <br />
              <Typography component="p" className={`${classes.textContent}`}>
                既存看板の張替えにすぐに使えるプリントステッカー45x90cmサイズです
              </Typography>
              <div className={classes.contentContainer}>
                <img
                  src="/images/siteimage/QRparkig看板プリントステッカー正方形.jpg"
                  className={classes.imgCenter}
                />
              </div>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </a>
          </Paper>
          <br />
          <Paper className={classes.root} elevation={1}>
            <a
              href="https://qrparking.jp/i/acct_1PF4utPmGNuqXXac/CoPkYixz5pNBaE6XbV0H"
              target="_blank"
            >
              <Typography
                variant="h3"
                className={`${classes.textContent} ${classes.customTypography}`}
              >
                QRコード付きオーダープリントステッカー100x100・オリジナルステッカーデザイン
              </Typography>
              <br />
              <Typography
                component="p"
                className={`${classes.textRight}`}
              >
                ＞＞詳しくはこちら
              </Typography>
              <br />
              <br />
              <Typography component="p" className={`${classes.textContent}`}>
                地元看板屋さんに発注して設置したり看板をDIYするのに便利な、野立て看板に貼る耐候性プリントステッカーです
                <br />
                サイズは100cmx100cmで、複数枚に分割プリントすることで大きな看板に対応可能です
                <br />
                またオリジナルデザインのステッカーもこちらで格安で注文できます
                <br />
                注意書きの文面など安心してお任せください
              </Typography>
              <div className={classes.contentContainer}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/qrparking-7996b.appspot.com/o/userfiles%2Facct_1PF4utPmGNuqXXac%2FCoPkYixz5pNBaE6XbV0H%2Fimages%2FQRparkig%E7%9C%8B%E6%9D%BF%E3%83%97%E3%83%AA%E3%83%B3%E3%83%88%E3%82%B9%E3%83%86%E3%83%83%E3%82%AB%E3%83%BC%E8%A4%87%E6%95%B0.jpg?alt=media&token=d97b0e6c-ba3f-4919-8b81-7057d19d5a36"
                  className={classes.imgCenter}
                />
              </div>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </a>
          </Paper>
          <br />
          <Paper className={classes.root} elevation={1}>
            <a
              href="https://qrparking.jp/i/acct_1PF4utPmGNuqXXac/NljkRvuQ5jfDYTXXkP7J"
              target="_blank"
            >
              <Typography
                variant="h3"
                className={`${classes.textContent} ${classes.customTypography}`}
              >
                QRパーキング名義駐車禁止警告チラシ20枚セット
              </Typography>
              <br />
              <Typography
                component="p"
                className={`${classes.textRight}`}
              >
                ＞＞詳しくはこちら
              </Typography>
              <br />
              <br />
              <Typography component="p" className={`${classes.textContent}`}>
                無断駐車車両のワイパーなどに挟んで警告するチラシ20枚のセットです
                <br />
                チラシの問い合わせ先名義はQRパーキングとなっておりますので問い合わせ応対は基本的にQRパーキングが行います
              </Typography>
              <div className={classes.contentContainer}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/qrparking-7996b.appspot.com/o/userfiles%2Facct_1PF4utPmGNuqXXac%2FNljkRvuQ5jfDYTXXkP7J%2Fimages%2FIMG_0764-1.jpg?alt=media&token=5f20caf0-62f6-4dc0-9c1e-45b5a0e6e9dd"
                  className={classes.imgCenter}
                />
              </div>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </a>
          </Paper>
          <br />
          <Paper className={classes.root} elevation={1}>
            <a
              href="https://qrparking.jp/i/acct_1PF4utPmGNuqXXac/cz8f240zhE6Z9UE2gN2r"
              target="_blank"
            >
              <Typography
                variant="h3"
                className={`${classes.textContent} ${classes.customTypography}`}
              >
                QRパーキング名義違法駐車警告ハガキ20枚セット
              </Typography>
              <br />
              <Typography
                component="p"
                className={`${classes.textRight}`}
              >
                ＞＞詳しくはこちら
              </Typography>
              <br />
              <br />
              <Typography component="p" className={`${classes.textContent}`}>
                無断駐車車両の所有者に送付するハガキのセットです
                <br />
                チラシと違って相手の住所氏名がわかっていることを示すことができるとても強力な警告ツールです
                <br />
                ハガキの送付には所有者開示請求が必要です
                <br />
                トップページの「不法駐車や未払いの対策は？料金トラブルについて」の項をご参照ください
              </Typography>
              <div className={classes.contentContainer}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/qrparking-7996b.appspot.com/o/userfiles%2Facct_1PF4utPmGNuqXXac%2Fcz8f240zhE6Z9UE2gN2r%2Fimages%2FIMG_0759-1.jpg?alt=media&token=dddf180f-b2f4-4ffb-b9dd-226bd909c464"
                  className={classes.imgCenter}
                />
              </div>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </a>
          </Paper>
          <br />
          <Paper className={classes.root} elevation={1}>
            <a href="https://qrparking.jp/MailForm/24tel" target="_blank">
              <Typography
                variant="h3"
                className={`${classes.textContent} ${classes.customTypography}`}
              >
                ２４時間電話応対オプション
              </Typography>
              <br />
              <Typography
                component="p"
                className={`${classes.textRight}`}
              >
                ＞＞詳しくはこちら
              </Typography>
              <br />
              <br />
              <Typography component="p" className={`${classes.textContent}`}>
                QRパーキングに２４時間の電話対応を依頼できるオプションです
                月額３０００円のサブスクリプションとなります
              </Typography>
              <div className={classes.contentContainer}>
                <img
                  src="/images/siteimage/_MV-940x529.jpg"
                  className={classes.imgCenter}
                />
              </div>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </a>
          </Paper>
          <br />
          <Paper className={classes.root} elevation={1}>
            <a
              href="https://qrparking.jp/i/acct_1PF4utPmGNuqXXac/p4eDmUPc5wWPhMGkvSdE"
              target="_blank"
            >
              <Typography
                variant="h3"
                className={`${classes.textContent} ${classes.customTypography}`}
              >
                QRパーキング名義　開示請求内容証明一括処理1件
              </Typography>
              <br />
              <Typography
                component="p"
                className={`${classes.textRight}`}
              >
                ＞＞詳しくはこちら
              </Typography>
              <br />
              <br />
              <Typography component="p" className={`${classes.textContent}`}>
                陸運局に対する登録証明取得申請手続きを行い、駐車料金請求の内容証明を送るまでを一括してQRパーキング名義で行います
              </Typography>
              <div className={classes.contentContainer}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/qrparking-7996b.appspot.com/o/userfiles%2Facct_1PF4utPmGNuqXXac%2Fp4eDmUPc5wWPhMGkvSdE%2Fimages%2FS__57974788.jpg?alt=media&token=dac1a9d1-6b0c-4a50-88b3-7423e9090714"
                  className={classes.imgCenter}
                />
              </div>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </a>
          </Paper>
          <br />
        </div>
        <Typography variant="subtitle2">
          <Link to="/specifiedcommercialtransactionact">
            特定商取引法に基づく表記
          </Link>
        </Typography>
      </div>
    );
  }
}

// withStyles HOCを使用してスタイルを適用
export default withStyles(styles)(ProductCatalog);
