import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
const auth = firebase.auth();

import StripeConnectButton from "../components/StripeConnectButton";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

import { FiLogOut } from "react-icons/fi";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      margin: 10,
    },
    textContent: {
      // 新しいスタイル
      textAlign: "left",
      flex: 1,
      display: "inline-block", // テキストコンテンツをインラインブロックにします
      //width: "calc(100% - 135px)", // 画像の幅とマージンを差し引いた幅
      width: "90%",
      verticalAlign: "top", // テキストを上揃えにします
    },
    textLeft: {
      textAlign: "left",
      flex: 1,
    },
    textCenter: {
      textAlign: "center",
      flex: 1,
    },
    img100: {
      width: "100%",
    },
    imgCenter: {
      // 新規追加
      width: "90%",
      //margin: "15px", // テキストとの間隔
    },
    customTypography: {
      fontSize: "1.25rem", // ここで望むフォントサイズに調整します。h4とh5の中間のサイズに設定
      fontWeight: "bold", // ここで文字を太くします
    },
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
    clearBoth: {
      clear: "both",
    },
  });

interface PricingSettingsProps extends WithStyles<typeof styles> {}

interface PricingSettingsState {
  error: string;
  warning: string;
  info: string;
  success: string;
  urlcopy: boolean;
  user?: firebase.User | null;
}

class PricingSettings extends React.Component<
  PricingSettingsProps,
  PricingSettingsState
> {
  constructor(props: PricingSettingsProps) {
    super(props);
    this.state = {
      error: "",
      warning: "",
      info: "",
      success: "",
      urlcopy: false,
    };
  }

  componentDidMount = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          user: user,
        });
        console.log("log in");
      } else {
        console.log("not log in");
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={`${classes.root} ${classes.textLeft}`}>
        <Typography
          variant="h3"
          className={`${classes.textContent} ${classes.customTypography}`}
        >
          価格設定とQRコードの取得について：ご利用マニュアル
        </Typography>
        <br />
        {this.state.error && <Alert severity="error">{this.state.error}</Alert>}
        {this.state.warning && (
          <Alert severity="warning">{this.state.warning}</Alert>
        )}
        {this.state.info && <Alert severity="info">{this.state.info}</Alert>}
        {this.state.success && (
          <Alert severity="success">{this.state.success}</Alert>
        )}
        <Typography
          className={`${classes.root} ${classes.textLeft}`}
          variant="body1"
          component="p"
        >
          左上のメニューから新規駐車場登録を選ぶと新しい駐車場を登録することができます。
        </Typography>

        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            駐車場名と緊急連絡先:
          </Typography>
          <img
            src="/images/siteimage/IMG_0338.jpg"
            className={classes.imgCenter}
          />
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            駐車場に場所がわかりやすい名前をつけてください。
            <br />
            ユーザーのカード明細などにも表記されます。
            <br />
            <br />
            緊急連絡先はユーザーに通知される緊急連絡先です。
            <br />
            月額５，０００円で緊急連絡先をQRパーキングの応答代行にすることができます。
          </Typography>
        </Paper>
        <br />
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            駐車料金:
          </Typography>
          <img
            src="/images/siteimage/IMG_0334.jpg"
            className={classes.imgCenter}
          />
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            駐車時間（時間・終日・月極）を選択してからその単価を設定してください。
            <br />
            １００円以下の料金は設定できません。
            <br />
            終日料金の設定がある場合、時間料金は終日料金を超える請求はされません。
            <br />
            月極料金の設定がある場合、時間料金と終日料金は月極料金を超える請求はされません。
            <br />
            料金を設定しない時間帯はなにも入力しないでください。
          </Typography>
        </Paper>
        <br />
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
        <Typography
              variant="h3"
              className={`${classes.textContent} ${classes.customTypography}`}
            >            メモ:
          </Typography>
          <img
            src="/images/siteimage/IMG_0340.jpg"
            className={classes.imgCenter}
          />

          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            メモは外部に公開されません。
            <br />
            ここだけで使える覚え書きとしてご利用ください。
          </Typography>
        </Paper>
        <br />
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            {" "}
            公開する・保存する:
          </Typography>
          <img
            src="/images/siteimage/IMG_0340-2.jpg"
            className={classes.imgCenter}
          />
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            公開するにチェックを入れて保存すると即座に反映されます。
            <br />
            駐車場として貸出を休止する場合は公開するチェックを外して保存してください。
          </Typography>
        </Paper>
        <br />
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            {" "}
            決済ページを確認・QRコードをダウンロード:
          </Typography>
          <img
            src="/images/siteimage/IMG_0340-3.jpg"
            className={classes.imgCenter}
          />

          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            ユーザーに見せる決済ページを実際に開いて確認することができます。
            <br />
            QRコードをダウンロードボタンを押すとQRコードをダウンロードできます。
            <br />
            看板を発注する場合などにそのままご利用ください。
          </Typography>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PricingSettings);
