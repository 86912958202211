import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// スタイル
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
  imageleft: {
    float: "left",
    margin: "0 15px 15px 0",
    height: "120px",
  },
  imageright: {
    float: "right",
    margin: "0 15px 15px 0",
    height: "120px",
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
});


class QRCodeSignOrder extends React.Component {

  render() {

    // Material-ui関連
    const { classes } = this.props;

    return (
      <div>
        <h2>QRparkingについて</h2>
        <div className={classes.textLeft}>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              joinoshopiとは
            </Typography>
            <Paper className={classes.root} elevation={2}>
            <Typography component="p">
            <img src="/images/siteimage/5199607_s.jpg" className={classes.imageright}/>
              ・QRparking・プログラム<br />
              SNSアカウントだけで簡単に始められる駐車場経営です。<br />
                成果報酬は振り込みで受け取れます。<br />
                <br />
            </Typography>
            </Paper>
            <Typography variant="headline" component="h3">
            ・収益とフォロワー獲得の方法<br />
            </Typography>
            <Paper className={classes.root} elevation={2}>
              <Typography component="p">
              <img src="/images/siteimage/5085364_s.jpg" className={classes.imageleft}/>
              アンバサダーが商品リストから選んだ素敵な商品をフォロワーに紹介します。<br />
              商品が売れるたびに商品価格の約２０％の紹介ポイントが得られます。<br /><br /><br />
              </Typography>
            </Paper>
            <Paper className={classes.root} elevation={2}>
            <Typography component="p">
            <img src="/images/siteimage/5144417_s.jpg" className={classes.imageright}/>
            商品の送り先は購入したフォロワーが自由に決めることができるのと同時に
              アンバサダーに送ることを選択することもできます。<br />
              送付先のアンバサダーの住所はフォロワーには一切通知されません。<br />
              アンバサダーは、自分が選んだ商品をプレゼントとして受け取った上に
              ２０％の紹介ポイントを得ることができます。<br />
              さらに同じ商品がすでに手元にあるなどの理由で商品を受け取る必要がない場合は、
              商品を受け取る代わりに商品価格の７０％をポイントで得ることができます。<br />
              </Typography>
            </Paper>
            <Paper className={classes.root} elevation={2}>
            <Typography component="p">
              <img src="/images/siteimage/22358049_s.jpg" className={classes.imageright}/>
              例えば、あなたが商品を一つ選んでそれをフォロワーに紹介したとします。<br />
              商品をアンバサダー本人が買っても紹介ポイントが貰えるので実質２０％割引で買うことができます。<br />
                さらにその紹介で誰か一人が商品を購入した場合、２０％があなたに入ります。<br />
                </Typography>
            </Paper>
            <Paper className={classes.root} elevation={2}>
            <Typography component="p">

              <img src="/images/siteimage/1736116_s.jpg" className={classes.imageleft}/>
                
              到着した商品をレビューしてまた商品紹介をします。<br />
                
              商品の紹介ページには自分の写真をアップロードすることができます。<br />
              その商品を誰かが買うたびにあなたには２０％の紹介ポイントが入ります。<br />
                ４人が買えばあなたは無料で商品を手に入れたことになります。<br /><br /><br />
                </Typography>
            </Paper>
            <Paper className={classes.root} elevation={2}>
            <Typography component="p">
            <img src="/images/siteimage/5034196_s.jpg" className={classes.imageright}/>  
              あなたがその商品を使ってラブリツプレゼント企画をやったとします。<br />
              当選者が決定するまでに、あなたが紹介した商品のページから４つ商品が売れた場合は、
              ８０％の紹介ポイントが入るので実質無料でプレゼント企画を何度でもすることができます。<br />
              それ以上に売れれば売れるたびに２０％があなたの利益になります。<br />
              </Typography>
            </Paper>
            <Paper className={classes.root} elevation={2}>
            <Typography component="p">
              <img src="/images/siteimage/3023107_s.jpg" className={classes.imageleft}/>  
              こうやって喜ばれながらどんどんフォロワーを獲得していくことができます。<br />
              フォロワーにとっては商品選びのセンスがあなたの魅力のひとつになります。<br />
              商品を買わずに宣伝だけたくさんすれば売れただけ利益になるのは事実です。<br />
                それは禁止されていません。でもぜひ節度を守って活動してください。<br />
                ぜひ厳選したすばらしい商品を楽しくみんなに紹介してください。<br />
                </Typography>
            </Paper>
            {/*
            <Paper className={classes.root} elevation={2}>
            <Typography component="p">
                
              joinoがむやみにアンバサダーを広めないで審査・招待制にする理由は、
              利益に目が眩んで闇雲に広告するだけのアカウントが増えすぎるのは、SNSユーザー全体の迷惑になってしまうと考えるからです。<br />
              当然悪質なアンバサダーは処分を受ける可能性があります。<br />
              </Typography>
            </Paper>
            */}
            <Typography variant="headline" component="h3">
            ・やり方<br />
            </Typography>


            <Paper className={classes.root} elevation={2}>
            <Typography component="p">

                左メニューのアイテムリストから商品を選び紹介ページを編集して保存ボタンで保存して、右上の拡散ボタンでフォロワーに紹介してください。<br />
                もし初めてお使いの場合は新規登録から指示に従ってください。<br />
                アカウントの作成にはTwitterアカウントかGoogle(gmail)アカウントそして招待コードが必要です。
              </Typography>
            </Paper>
            <Paper className={classes.root} elevation={2}>
            <Typography component="p">
                今から始めても手続きはそれだけです。<br />
              </Typography>
            </Paper>
            <Paper className={classes.root} elevation={2}>
            <Typography component="p">
              匿名でSNSアカウントさえあれば誰でも始めることができます。<br />
                溜まったポイントを出金する段階になって初めて口座情報を必要としますが、ご希望によってpaypayやアマギフでお支払いできます。<br />
                </Typography>
            </Paper>
            <Paper className={classes.root} elevation={2}>
            <Typography component="p">

              商品代金の決済、発送、問い合わせ苦情の対応はすべてjoinoが行いアンバサダーは気になる商品を気軽に紹介するだけです。<br />
              </Typography>
            </Paper>
            <Paper className={classes.root} elevation={2}>
            <Typography component="p">
              ポイント還元の申込みは当面メールやお問い合わせフォームで行い、近い将来オンラインで手続きができるようになります。<br />
              左メニューのアイテムリストやアカウント・サービスを開くと即座にアカウント登録が始まります。<br />
              さあ！始めましょう!
              </Typography>
            </Paper>

          </Paper>
        </div>
      </div>
    );
  }
}

// Material-ui関連
QRCodeSignOrder.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(QRCodeSignOrder);