import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = {
  root: {
    padding: '16px',
    margin: 10,
  },
  paper: {
    padding: '16px',
    marginTop: '20px',
  },
  button: {
    marginTop: '20px',
  },
  checkbox: {
    marginTop: '10px',
  },
};

const LogoutPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [clearCache, setClearCache] = useState(false);
  const history = useHistory();

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();

      if (clearCache) {
        // ローカルストレージをクリア
        localStorage.clear();
        
        // セッションストレージをクリア
        sessionStorage.clear();
        
        // Indexedデータベースをクリア
        if (window.indexedDB) {
          const databases = await window.indexedDB.databases();
          databases.forEach(db => {
            if (db.name) {
              window.indexedDB.deleteDatabase(db.name);
            }
          });
        }
        
        // サービスワーカーを削除（存在する場合）
        if ('serviceWorker' in navigator) {
          const registrations = await navigator.serviceWorker.getRegistrations();
          for (let registration of registrations) {
            await registration.unregister();
          }
        }

        // キャッシュストレージをクリア
        if ('caches' in window) {
          const cacheNames = await caches.keys();
          await Promise.all(cacheNames.map(name => caches.delete(name)));
        }
      }

      history.push('/'); // ログアウト後にトップページにリダイレクト
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={styles.root}>
      <Typography variant="h4" component="h2">
        QRparkingオーナー ログアウト
      </Typography>
      <Paper style={styles.paper} elevation={1}>
        <Typography variant="h5" component="h3">
          ログアウト
        </Typography>
        <Typography component="p">
          ログアウトすると、QRparkingオーナーダッシュボードからサインアウトします。
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={clearCache}
              onChange={(e) => setClearCache(e.target.checked)}
              name="clearCache"
              color="primary"
            />
          }
          label="キャッシュデータも削除する"
          style={styles.checkbox}
        />
        <Button
          variant="contained"
          color="secondary"
          style={styles.button}
          onClick={handleClickOpen}
        >
          ログアウト
        </Button>
      </Paper>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"ログアウトの確認"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            本当にログアウトしますか？
            {clearCache && (
              <strong> キャッシュデータも削除されます。</strong>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleLogout} color="secondary" autoFocus>
            ログアウト
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LogoutPage;