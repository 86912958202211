// AuthRoute.tsx
import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import firebase from './Firebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import StripeConnectButton from './components/StripeConnectButton';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Userdata } from './types';
import { loadStripe } from '@stripe/stripe-js';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
}));

interface AuthRouteProps extends RouteComponentProps {
  component: React.ComponentType<any>;
  path?: string | string[];
}

const AuthRoute: React.FC<AuthRouteProps> = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState<Userdata | null>(null);
  const classes = useStyles();
  const clientId = process.env.REACT_APP_STRIPE_CLIENT_ID ? process.env.REACT_APP_STRIPE_CLIENT_ID : "";
  
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        getUserData(user).then((data) => {
          setUserData(data);
          setIsAuthenticated(true);
          setLoading(false);
        });
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const getUserData = async (user: firebase.User): Promise<Userdata> => {
    const docRef = firebase.firestore().collection("users").doc(user.uid);
    const doc = await docRef.get();

    if (doc.exists) {
      return doc.data() as Userdata;
    } else {
      const newUserdata: Userdata = {
        uid: user.uid,
        dt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      docRef.set(newUserdata);
      return newUserdata;
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return (
            <div className="container">
              <div className={classes.root}>
                <h2>ログイン</h2>
                <div className={classes.textLeft}>
                  <Paper className={classes.root} elevation={1}>
                    <Typography variant="h6" component="h3">
                      Stripe Connectでログインしてください
                    </Typography>
                    <StripeConnectButton />
                  </Paper>
                </div>
              </div>
            </div>
          );
        }

        return <Component {...props} userdata={userData} />;
      }}
    />
  );
};

export default AuthRoute;