import React from "react";
import { Link } from "react-router-dom";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles } from "@material-ui/core/styles";

// スタイルを定義
const styles = (theme: Theme) =>
  createStyles({
    titleImage: {
      width: "100%",
      maxWidth: 700,
    },
    root: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      margin: theme.spacing(1),
      overflow: "hidden",
    },
    contentContainer: {
      display: "block",
    },
    textContent: {
      textAlign: "left",
      flex: 1,
      display: "inline-block",
      width: "calc(100% - 50px)",
      verticalAlign: "top",
    },
    textLeft: {
      textAlign: "left",
      flex: 1,
    },
    imgLeft: {
      height: "120px",
      float: "left",
      marginRight: "15px",
    },
    imgRight: {
      height: "120px",
      float: "right",
      marginLeft: "15px",
    },
    clearBoth: {
      clear: "both",
    },
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
  });

// Propsの型を定義
interface Props extends WithStyles<typeof styles> {}

class TermsOfService extends React.Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <img
          src="/images/QRparkingLOGOIMG.jpg"
          alt="title"
          className={classes.titleImage}
        />
        <h2>利用規約</h2>
        <div className={classes.clearBoth}></div> {/* フロートのクリア */}
        <div>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              QRパーキング利用規約
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                この利用規約（以下、「本規約」といいます。）には、鵜飼不動産株式会社（以下、「当社」といいます。）の提供するインターネットサービス「QRパーキング」（以下、「本サービス」といいます。）のご利用にあたり、利用者の皆様に遵守していただかなければならない事項及び当社と利用者の皆様との間の権利義務関係が定められております。当該サービスをご利用になられる方は、本規約に同意する前に、必ず全文お読み下さいますようお願いいたします。
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第1条 （定義）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                本規約において使用する以下の用語は各々以下に定める意味を有するものとします。
                <br />
                (1)
                「利用者」とは、本規約の内容に同意した駐車場運営者及び駐車場利用者のことをいいます。
                <br />
                (2) 「本利用契約」とは、本規約に基づく契約のことをいいます。
                <br />
                (3)
                「定額サービス」とは、駐車場運営者が提供するサービスであって、駐車場運営者が定めた条件のサービスを、駐車場運営者が定めた期間、定額で提供するサービスのことをいいます。
                <br />
                (4)
                「定額サービス契約」とは、駐車場運営者及び駐車場利用者が締結する契約であって、駐車場運営者が駐車場利用者に対し、定額サービスを提供する契約のことをいいます。
                <br />
                (5)
                「駐車場運営者」とは、利用者のうち、駐車場利用者に対し、定額サービス契約に基づき、定額サービスを提供する個人又は法人のことをいいます。
                <br />
                (6)
                「駐車場利用者」とは、利用者のうち、定額サービス契約に基づき、駐車場利用者から定額サービスの提供を受ける個人のことをいいます。
                <br />
                (7)
                「収納代行業者」とは、当社が指定する事業者であって、駐車場運営者が定額サービス利用料の代理受領権限を付与した事業者のことをいいます。
                <br />
                (8)
                「定額サービス条件」とは、定額サービス契約の要素となる、定額サービスの内容、定額サービス利用料その他の定額サービスの条件のことをいう。
                <br />
                (9)
                「定額サービス利用料」とは、定額サービス契約に基づき、駐車場利用者が駐車場運営者に対し支払う定額サービスの利用料金のことをいいます。
                <br />
                (10)「本サービス利用料」とは、駐車場運営者が、本規約に基づき、当社に対し支払う本サービスの利用料金のことをいいます。
                <br />
                (11)「引出手数料」とは、駐車場運営者が、本サービス利用料を現金化する際に当社に対して支払う手数料のことをいいます。
                <br />
                (12)「アカウント情報」とは、本サービスを利用するために必要となる電子メールアドレス及びパスワード（外部事業者から発行されたものを含みます。）のことをいいます。
                <br />
                (13)「個人情報」とは、個人情報保護法に定める「個人情報」を指すものとし、利用者が入力した氏名、郵便番号、住所、生年月日、性別、職業、電話番号、アカウント情報、プロフィール情報（ニックネーム、趣味、家族構成、年齢その他の個人に関する属性情報を含みます。）、クレジットカード情報、銀行口座情報及び利用履歴等で、かつ特定の個人を識別できる情報（他の情報と容易に照合することができ、それにより特定の個人を識別することとなるものを含みます。）をいいます。
                <br />
                (14)「プライバシーポリシー」とは、当社が定めるプライバシーポリシー（名称の如何を問わないものとします。）のことをいいます。
                <br />
                (15)「外部サービス」とは、Facebook、その他の他の事業者が提供している当社所定のサービスで、利用者の認証等の機能を持ち、本サービスの実施に利用されるサービスのことをいいます。
                <br />
                (16)「外部事業者」とは、外部サービスの提供者のことをいいます。
                <br />
                (17)「外部利用規約」とは、利用者と外部事業者との権利関係を定める規約のことをいいます。
                <br />
                本条の定義は、別途定義されている場合及び文脈上別異に解すべき場合を除き、本利用規約のほか、プライバシーポリシーにおいても、適用されるものとします。
                <br />
              </Typography>
            </div>
            <div className={classes.clearBoth}></div> {/* フロートのクリア */}
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第2条 （本規約への同意）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                本規約は、本サービスの利用に関する条件を利用者と当社との間で定めることを目的とし、利用者と当社との間の本サービスの利用に関わる一切の関係に適用されます。利用者は、本規約に同意をしたうえで、本規約の定めに従って本サービスを利用しなければなりません。利用者は、本サービスを利用することにより本規約に同意をしたものとみなされます。
                <br />
                利用者が未成年者である場合は、事前に親権者その他の法定代理人の包括的な同意を得たうえで本サービスを利用しなければなりません。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第3条 （本規約の内容の変更）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                当社は、必要に応じ、本規約の内容を変更できるものとします。当社は、本規約を変更した場合には、利用者に通知（本条において、当社の運営するウェブサイトにおける提示を含みます。）するものとし、通知後、利用者が本サービスを利用した場合又は当社の定める期間内にアカウント削除の手続をとらなかった場合には、利用者は、本規約の変更に同意をしたとみなされるものとします。当社は、本規約の改定、変更により利用者に生じたすべての損害について、一切の責任を負いません。
                <br />
                変更後の本規約は、当社が別途定める場合を除き、当社が利用者に本規約の内容を通知する旨の通知をした時点より効力が発生するものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第4条 （Stripeサービス規約への同意）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                当社は、収納代行業者として、ストライプジャパン株式会社（以下、「Stripe」といいます。）を指定します。
                <br />
                定額サービス利用料、本サービス利用料及び引出手数料の支払いは、Stripeが提供する支払処理サービスに基づき行われるものとし、当該支払処理サービスは、Stripe
                Connectアカウント契約（Stripe利用規約を含み、総称して、以下、「Stripeサービス契約」といいます。）に従うものとします。本規約への同意又は本サービスにおいて利用者としての取引の継続により、利用者はStripeサービス契約（随時Stripeにより修正されることがあり、その場合には修正されたものを含みます。）に拘束されることに同意するものとします。Stripeを通じた支払処理サービスを当社が本サービス内で可能にするための条件として、利用者は、当社に対して利用者に関する正確かつ完全な情報を提供することに同意し、当社が当該情報及びStripeが提供する支払処理サービスの利用者による使用に関連する取引情報を共有することを認めるものとします。
                <br />
                利用者は、Stripeによる支払処理サービスにおいて、支払金額に1円未満の端数が生じた場合、その端数が四捨五入されることに同意するものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第5条 （利用者登録）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                本サービスの利用を希望する者は、本規約を遵守することに同意し、かつ当社の定める情報を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
                <br />
                登録の申請は必ず本サービスを利用する本人自身が行わなければならず、当社が認めた場合を除き代理人による登録申請は認められません。また、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
                <br />
                当社は、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
                <br />
                (1) 本規約に違反するおそれがあると当社が判断した場合
                <br />
                (2)
                当社に提供された情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
                <br />
                (3) 過去に本サービスの利用の登録を取り消された者である場合
                <br />
                (4)
                未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合
                <br />
                (5)
                反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じとします。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
                <br />
                (6)
                特定商取引法又は消費者契約法に違反する行為を行っている場合若しくはそのおそれがあるとき又は直近5年間にこれらの法令に基づく処分を受けている場合
                <br />
                (7) その他、当社が登録を適当でないと判断した場合
                <br />
                当社は、前項その他当社の基準に従って、登録の可否を判断し、当社が登録を認める場合にはその旨を通知します。かかる通知により利用者としての登録は完了し、本サービスの利用が開始します。
                <br />
                利用者は、当社に提供した情報に変更があった場合は、遅滞なく、当社の定める方法により、変更した事項を当社に通知し、当社から要求された資料を提出するものとします。
                <br />
                利用者は、利用者として複数の登録を行うことができないものとします。
                <br />
                利用者は、反社会的勢力等に該当しないこと、かつ将来にわたっても該当しないこと、及び、自ら又は第三者を利用して、暴力的な要求行為、法的な責任を超えた不当な要求行為、取引に関して脅迫的な言動をし又は暴力を用いる行為、風説を流布し、偽計を用い又は威力を用いて当社の信用を毀損し又は当社の業務を妨害する行為、その他これらに準ずる行為を行わないことを確約するものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第6条 （本サービスの利用）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                利用者は、本利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第7条 （アカウント情報の管理等）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                利用者はアカウント情報を自ら管理する責任を負います。利用者は、アカウント情報を第三者等に利用させたり、譲渡や売買、質入、貸与、賃貸したり、その他形態を問わず処分することはできません。
                <br />
                アカウント情報の管理不十分による情報の漏洩、使用上の過誤、第三者の使用、不正アクセス等による損害の責任は利用者が負うものとし、当社は一切責任を負わないものとします。また、アカウント情報が不正に利用されたことにより当社に損害が生じた場合、利用者は当該損害を賠償するものとします。
                <br />
                アカウント情報が第三者に漏えいした場合又はそのおそれがある場合、速やかに当社まで連絡するものとします。また、その際に当社の指示がある場合にはこれに従うものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第8条 （定額サービス契約の成立）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                本サービス上で、駐車場利用者は、駐車場運営者が定めた条件により、定額サービス契約を締結することの申し出をすることができ、駐車場運営者はこれを承諾するものとします。駐車場運営者が承諾をすることにより、駐車場運営者及び駐車場利用者間で、定額サービス条件により、定額サービス契約が成立するものとします。駐車場運営者及び駐車場利用者は、自己の責任において本サービスを利用し、定額サービスの提供及び提供を受けるものとし、本サービス及び定額サービスにおける一切の行為及びその結果について一切の責任を負うものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第9条 （当社の役割）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                当社は、本サービスにより、利用者に対し、利用者間の定額サービスのマッチングのための場・機会を提供します。駐車場運営者が駐車場利用者に対し提供する定額サービスは、利用者間で締結される定額サービス契約に基づき行われます。
                <br />
                当社は、定額サービス契約の当事者若しくは代理人となり、又は媒介を行うものではありません。当社は、本規約において別段の定めがある場合を除き、定額サービス契約の取消し、解約、解除又は不履行には、一切関与しません。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第10条 （駐車場運営者が負う義務）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                駐車場運営者は、定額サービス契約が成立した場合、定額サービス条件に従い、定額サービスを提供する義務を負うものとします。
                <br />
                駐車場運営者は、定額サービス契約に従い定額サービスを提供しなかった場合又は定額サービスの提供に瑕疵があった場合、駐車場利用者に対し、定額サービス利用料を返金しなければならないものとします。これらの場合、駐車場運営者は、当社に対し、駐車場利用者に対し定額サービス利用料を返金することの依頼をし、当社は、当該依頼に基づき、駐車場利用者に対し定額サービス利用料の返金をする処理をするものとします。定額サービス利用料の返金に関し発生する、引出手数料その他の経費は、駐車場運営者が負担するものとします。
                <br />
                駐車場運営者は、駐車場利用者から、定額サービス契約に従い定額サービスを提供しなかったこと又は定額サービスの提供に瑕疵があったことを理由とする苦情、問い合わせが生じた場合、自らの責任で対応をし、これらの事態を解消するものとします。駐車場運営者は、これらの事態に対応するための連絡先を、予め、駐車場利用者に対し、分かり易い方法で明示するものとします。
                <br />
                駐車場運営者は、当社が別途定めるサービスのカテゴリに属するサービスに限り、本サービス上、定額サービスとして、提供することができるものとします。
                <br />
                駐車場運営者は、本規約又は法令に違反し、駐車場利用者、当社その他の第三者に損害を与えた場合は、自己の責任と費用で、その損害を賠償するものとします。
                <br />
                駐車場運営者は、定額サービス利用料として、税込の金額を表示するものとします。
                <br />
                駐車場運営者は、本サービス上で、定額サービス、クーポンその他の駐車場運営者が行う表示内容につき、次の各号に定める事項を遵守するものとします。
                <br />
                (1)
                景品表示法、消費者契約法、割賦販売法、薬機法、不正競争防止法、健康増進法及び医療法等の法令等の定めに違反する表示を行わないこと
                <br />
                (2) 定額サービス条件を正確かつ明確に表示すること
                <br />
                (3) 公序良俗に反する表現、内容の表示を行わないこと
                <br />
                (4)
                プライバシー、肖像権及びパブリシティ権を侵害する表示を行わないこと
                <br />
                (5)
                特許権、著作権、商標権その他の知的財産権を侵害する表示を行わないこと
                <br />
                (6) その他の法令の定めに違反する表示を行わないこと
                <br />
                (7) 本規約の定めに違反する表示を行わないこと
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第11条 （定額サービス利用料の支払い）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                駐車場運営者は、収納代行業者に対し、駐車場利用者から定額サービス利用料を代理受領する権限を付与するものとします。
                <br />
                駐車場利用者は、定額サービスの利用申込時に、当社が定めるクレジットカード又は当社が認めるその他の決済方法により、定額サービス契約の締結日の属する月の定額サービス利用料を支払うものとし、その翌月以降も、定額サービス契約の解約、又は退会などの日の属する月まで定額サービス利用料を毎月支払うものとします。
                <br />
                駐車場利用者は、前項で定める定額サービス契約の締結日の属する月の定額サービス利用料の支払いを行なった日より、定額サービスを利用できるものとします。駐車場運営者は、定額サービス契約の締結日の属する月の定額サービス利用料の金額を、次の各号のいずれかの取扱いとするか指定できるものとします。なお、駐車場運営者が特に指定をしなかった場合は、1号の取扱いとします。
                <br />
                (1) 日割り計算で計算した金額
                <br />
                (2) 1ヵ月分の定額サービス利用料の金額
                <br />
                定額サービス契約の締結日の属する月の以降の月は、毎月1日時点で、定額サービス利用料の課金がなされるものとし、駐車場利用者は、同日、定額サービス利用料を支払うものとします。
                <br />
                駐車場利用者が本規約に従って必要な支払いを行わない場合若しくは遅延した場合又は収納代行業者に対し届け出たクレジットカードの利用が停止された場合、当社は、当該利用者に通知することなく、当該利用者による本サービスの利用を停止することができるほか、第30条に定める措置をとることができるものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第12条 （本サービス利用料の支払い）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                駐車場運営者は、本サービス利用料として、本サービス利用料の売上げに、当社が定めた料率を掛け合わせた金額を、当社に対し支払うものとします。
                <br />
                当社は、本サービス利用料を、定額サービス利用料の売上げから、差し引く方法により徴収できるものとし、駐車場運営者は予めこれに同意するものとします。
                <br />
                当社は、第1項に定める料率を改定する場合、駐車場運営者に対して事前に通知（当社の運営するウェブサイトにおける提示を含みます。）を行うものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第13条 （定額サービス利用料の引出し）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                定額サービス利用料の売上げは、引出処理が完了した時に、駐車場運営者が登録した金融機関の口座に、収納代行業者により、振り込まれるものとし、駐車場運営者は、引出処理が完了するまで、定額サービス利用料の売上げを引き出すことができないものとします。
                <br />
                当社により、毎月末（末日が休祝日の場合は翌営業日）に、駐車場運営者に発生する前月の定額サービス利用料の売上げを対象として、引出手続をする方法に限り、引出処理が行われるものとします。駐車場運営者は、引出手続がされる前に、当社の定める方法による本人確認手続を完了させなければならないものとします。
                <br />
                当社が、前項に定める引出手続を行ったにもかかわらず、当社の責めに帰すべき事由なく引出処理が正常に完了しない場合、当社は、駐車場運営者が、定額サービス利用料の売上げの支払請求権を放棄したものとみなすことができるものとします。
                <br />
                引出処理には、１回当たり、引出手数料が必要となります。当社は、引出手数料を、定額サービス利用料の売上げから、差し引く方法により徴収できるものとし、駐車場運営者は、予めこれに同意するものとします。
                <br />
                引出処理をする時点における、定額サービス利用料の売上げから、本サービス利用料及び引出手数料を減じた金額が、1円未満となる場合、その月の引出処理は行われないものとします。駐車場利用者が定額サービス利用料を支払った日から90日以内に、当社の責めに帰すべき事由なく引出処理が行われなかった場合、当社は、駐車場運営者が、定額サービス利用料の支払請求権を放棄したものとみなすことができるものとします。
                <br />
                引出処理の対象となる定額サービス利用料の売上げは、駐車場利用者が現に支払った定額サービス利用料に限られるものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第14条 （不払い・支払遅延等）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                当社に対し利用者の未払いの支払債務が存在している場合、当社は、未払いの支払債務の回収を第三者に委託することができるものとします。
                <br />
                当社に対し利用者が支払期日までに支払債務を履行しなかった場合、当社は、当該利用者に対し、年率14.6％の遅延損害金を請求することができるものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第15条 （クーポン）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                駐車場利用者は、駐車場運営者が定める利用条件により、クーポンを取得することができます。クーポンで定められた利用条件により、駐車場運営者は、サービスを提供するものとし、駐車場利用者は、サービスの提供を受けるものとします。
                <br />
                駐車場利用者は、クーポンを利用条件に定められた用途以外に用いることができず、クーポンを、現金、財物その他の経済的利益と交換することはできません。また、当社は、理由のいかんを問わず、一切クーポンの払い戻しをいたしません。ただし、法令上必要な場合はこの限りではありません。この場合、クーポンの払い戻し方法は、法令に従って当社が定め、当社のウェブサイト等に表示するところに従うものとします。
                <br />
                利用者は、クーポンを当社が定めた日数の期間中にのみ利用することができます。有効期限を過ぎた未使用のクーポンは消滅し、その後利用することはできません。
                <br />
                理由のいかんを問わず、利用者が退会した場合又は利用者の会員資格が消滅した場合には、当該利用者が保有するクーポンは全て失効し、以後利用することはできないものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第16条 （駐車場利用者による解約）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                駐車場利用者は、本規約及び当社が定める方法により、いつでも、翌月以降の定額サービス契約を解約することができるものとします。
                <br />
                駐車場利用者が、定額サービス契約を解約する場合、解約を希望する月の末日に本契約が終了するものとし、駐車場利用者は、前項に基づき定額サービス契約を解約した場合、解約した月の月末時点で当該定額サービス契約に基づく定額サービスの提供を受ける権利を失うものとします。駐車場利用者は、解約を希望する月の定額サービス料として、1ヵ月分の定額サービス利用料を駐車場運営者に対し支払うものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第17条 （駐車場運営者による解約）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                駐車場運営者は、本規約及び当社が定める方法により、いつでも、定額サービス契約を解約することができるものとします。
                <br />
                駐車場運営者は、月の途中に定額サービス契約を解約する場合、駐車場利用者に対し、日割り計算で当該月の定額サービス利用料を返金するものとします。
                <br />
                前項による返金は、当社が、駐車場利用者に対し、返金をした上で、当該返金をした金額を駐車場運営者に対し請求することにより行うものとします。当社は、当該金額を、定額サービス利用料の売上げから、差し引く方法により徴収できるものとし、駐車場運営者は、予めこれに同意するものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第18条 （返金）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                駐車場運営者が、当社又は収納代行業者に対し支払った、本サービス利用料及び引出手数料は、本規約で定める場合又は当社若しくは収納代行業者が必要と認めた場合を除き、返金されないものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第19条 （禁止行為）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                利用者は、以下の行為を行ってはならないものとします。
                <br />
                (1)
                当社、他の利用者又は第三者の権利（著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、肖像権その他法令上又は契約上の権利を広く含みます。）を侵害する行為又はそのおそれのある行為
                <br />
                (2)
                当社、他の利用者その他の第三者に対する暴言、中傷、脅迫等をする行為
                <br />
                (3) 当社、他の利用者その他の第三者に対する詐欺的行為
                <br />
                (4) 本サービス上に虚偽の情報を掲載する行為
                <br />
                (5)
                自己のアカウントを他人に利用させるなどのアカウントを不正に利用する行為
                <br />
                (6) 当社、他の利用者その他の第三者になりすます行為
                <br />
                (7)
                当社、他の利用者その他の第三者の信用を毀損するおそれがある行為、誤解を招く行為、差別的行為又は不正行為
                <br />
                (8)
                自動化手段を用いてデータにアクセスする行為又はデータを取得する行為
                <br />
                (9) アクセス許可のないデータにアクセスを試みる行為
                <br />
                (10)当社の業務に支障を与える行為
                <br />
                (11)ウイルスの送付、不正アクセス等により、当社システム及び本サービスの提供に障害等を引き起こす行為
                <br />
                (12)ネットワークビジネス、マルチ商法、ねずみ講などへの勧誘行為
                <br />
                (13)本サービスを利用したアフィリエイト誘導、目的に合致しないリンクの貼付け行為
                <br />
                (14)法令、公序良俗、本規約若しくは本サービスの趣旨に反する行為又はそのおそれがある行為
                <br />
                (15)その他当社が不適切であると判断する行為
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第20条 （当社の免責）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                本サービスに関連して利用者間又は利用者と第三者間において生じたトラブル（以下の各号に掲げるトラブルを含む）に関し、利用者は各自の費用及び責任で解決するものとし、当社は一切の責任を負わないものとします。
                <br />
                (1)
                駐車場運営者及び駐車場利用者間の定額サービス契約に基づく権利の行使又は義務の履行
                <br />
                (2)
                利用者の法令又は本規約に違反する行為による、他の利用者又は第三者に対する損害等の発生
                <br />
                (3)
                その他本サービス又は定額サービスに関連する利用者又は第三者に対する損害等の発生
                <br />
                第1項に定めるトラブルが生じた際には、当事者間で解決するものとし、当該トラブルにより当社が損害を被った場合は、当事者は当該損害を賠償するものとします。
                <br />
                利用者間又は利用者と第三者との間で、本サービスに関連して、第1項に定めるトラブルを含め、裁判やクレーム、請求等あらゆる紛争が生じた場合、利用者各自の責任や費用で解決するものとし、当社は、当該紛争に関し、解決のためのあっせん、調停、仲裁その他の紛争解決の手段を講じる義務を負いません。
                <br />
                当社は、駐車場運営者が本サービスを利用することにより、一定の売上又は利益を得られることを保証するものではありません。
                <br />
                当社は、駐車場運営者が提供する定額サービスの内容、機能、性能、品質その他の一切の要素を保証するものではなく、一切の責任を負わないものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第21条 （権利帰属等）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                本サービスを構成する全ての要素に関する知的財産権、肖像権、パブリシティ権その他の権利（以下、「知的財産権等」といいます。）は、全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権等の使用許諾を意味するものではありません。利用者は、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権等を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしないものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第22条 （当社による利用）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                利用者が本サービスに関連して投稿又は掲載した写真、文書、情報等（以下、「利用者投稿物」といいます。）に関しては、本サービスの宣伝、運営、研究開発及び発表等を目的として、当社及び当社の指定する者が自由に利用できるものとします。
                <br />
                利用者投稿物に関する一切の責任は、投稿又は掲載した利用者が負うものとし、当社は、その内容、品質、正確性、信憑性、適法性、最新性、有用性等について、確認いたしません。また、当社は、それらに関して、一切保証せず、利用者及び第三者が利用者投稿物を利用することにより生じる損害について、一切責任を負わないものとします。
                <br />
                当社は利用者投稿物のバックアップを行う義務を負わないものとします。利用者は、利用者投稿物のバックアップが必要な場合には、自己の費用と責任でこれを行うものとします。
                <br />
                当社は、利用者が本規約に違反又は本規約若しくは本サービスの趣旨・目的に照らして不適切な行為を行ったと判断した場合、当該利用者が掲載した利用者投稿物を、事前の通知なしに変更及び削除できるものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第23条 （非保証・設備の負担等）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                当社は、本サービス及びコンテンツ（利用者が本サービス上で掲載したコンテンツを含みます。）に瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証するものではなく、現状有姿のまま提供するものとします。
                <br />
                本サービスの提供を受けるために必要な、コンピューター、スマートフォン、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、利用者の費用と責任において行うものとします。当社は、利用者が利用したコンピューター、スマートフォン、ソフトウェアその他の機器、通信回線その他の通信環境等により利用者又は第三者に生じた損害に関し、一切責任を負わないものとします。
                <br />
                利用者は、本サービスを利用する際に、種々のネットワークを経由する場合があることを理解し、接続しているネットワークや機器等によっては、それらに接続したり、それらを通過するために、データや信号等の内容が変更される可能性があることを理解したうえで、本サービスを利用するものとし、かかる変更について当社は一切責任を負わないものとします。利用者がインターネット回線を通じて行う一切の入力は、当社のシステムが当該入力に関するデータを受信し、当社のシステムに当該入力の内容が反映された時点をもって有効に成立するものとします。当社は、インターネット回線の障害、エラー、バグの発生等に関し、一切責任を負わないものとします。
                <br />
                利用者は本サービスの自己の利用環境に応じて、コンピュータ・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。当社は、本サービスに関連するコンテンツの中に、コンピュータ・ウィルス等が含まれていないことに関し、一切保証しません。本サービスに関連するコンテンツの中に、コンピュータ・ウィルス等が含まれていたことにより生じた損害について、当社は、利用者及び第三者に対して一切責任を負わないものとします。
                <br />
                利用者は、本サービスの利用開始に際し又は本サービスの利用中に、ダウンロードその他の方法によりソフトウェア等を利用者のコンピューター、スマートフォン等にインストールする場合には、利用者が保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社は利用者に発生したかかる損害について一切責任を負わないものとします。
                <br />
                本サービスは、外部サービスと連携することがありますが、かかる連携を保証するものではなく、本サービスにおいて外部サービスと連携できなかった場合でも、当社は一切の責任を負いません。本サービスが外部サービスと連携している場合において、利用者は外部利用規約を自己の費用と責任で遵守するものとし、利用者と当該外部サービスを運営する外部事業者との間で紛争等が生じた場合でも、当社は当該紛争等について一切の責任を負いません。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第24条 （当社の損害賠償責任）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                当社は、本サービスに関連して利用者が被った損害について、一切賠償の責任を負いません。消費者契約法の適用その他の理由により当社が利用者に対して損害賠償責任を負う場合においても、当社の賠償責任は、当社の過失（重過失を除きます）による債務不履行又は不法行為により利用者に生じた損害のうち、損害が生じた時点から遡って過去3ヶ月以内の期間に当社が当該利用者から現実に受領した本サービス利用料の総額を上限とします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第25条 （利用者の損害賠償責任）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                利用者が本規約に違反した場合、故意過失を問わず、当該利用者が、当該違反により損害を受けた他の利用者及び第三者に対する損害賠償責任を含む、一切の責任を負うものとします。利用者がかかる違反を行ったことにより、当社が損害を被った場合は、利用者は当該損害を賠償するものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第26条 （不可抗力）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                地震、台風、津波その他の天変地異、戦争、暴動、内乱、テロ行為、疫病、法令・規則の制定・改廃、公権力による命令・処分その他の政府による行為、争議行為、輸送機関・通信回線等の事故、その他不可抗力による利用契約の全部又は一部（金銭債務を除く）の履行遅滞又は履行不能については、当社は何ら責任を負わないものとします。
                <br />
                前項に定める事由が生じ、本規約の目的を達成することが困難であると認めるに足りる合理的な理由がある場合には、当社は、本規約の全部又は一部を解除できるものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第27条 （個人情報の取扱い）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                利用者は、本サービスの利用の前に、本規約、本サービス又は当社のウェブサイト上で、プライバシーポリシーの内容を必ず確認し、その内容に同意したうえで、本サービスを利用するものとします。
                <br />
                当社は、プライバシーポリシーに定める他、本規約の定めに従って個人情報を取り扱います。本サービスに関する個人情報に関し、プライバシーポリシーの定めと本規約の定めが矛盾抵触する場合、本規約の定めが優先して適用されるものとします。
                <br />
                当社は、本サービスに関する個人情報に関し、以下のとおり取り扱います。
                <br />
                個人情報の利用目的
                <br />
                当社は、あらかじめ利用者本人の同意を得た場合及び個人情報の保護に関する法律その他法令により例外として定められている場合を除き、以下の利用目的の範囲内で個人情報を利用します。
                <br />
                本サービスの提供のため
                <br />
                本サービスにおいて、定額サービスを提供する駐車場運営者とその駐車場利用者の間における情報の共有のため
                <br />
                見積書、請求書、領収書その他必要書類の送付のため
                <br />
                利用者に対して本サービスその他当社に関するご意見をお伺いするため
                <br />
                お問い合わせに対する回答のため
                <br />
                当社に対する本サービス申込内容の確認その他必要事項の連絡を行うため
                <br />
                利用者からのクレーム情報により謝罪及び改善につなげるため
                <br />
                利用者その他の当社のお取引先様との契約関係の管理及びお取引に関する業務を行うため
                <br />
                事業上必要な業務連絡、円滑な対応を行うため
                <br />
                当社における市場調査、システム開発、データ分析及び業務開発の基礎資料として利用するため
                <br />
                本サービスの解約やお取引後の事後管理のため
                <br />
                上記の利用目的に付随する利用目的のため
                <br />
                個人情報の第三者提供
                <br />
                当社は、個人データ（個人情報の保護に関する法律の定義によります。以下同じ）について、適切に管理し、以下のいずれかに該当する場合を除き、利用者本人の同意なく、第三者に提供しません。
                <br />
                前号に定めた利用目的のために、定額サービスを提供する駐車場運営者又はその駐車場利用者に提供するとき
                <br />
                人の生命、身体又は財産の保護のために必要がある場合であって、利用者本人の同意を得ることが困難であるとき
                <br />
                本サービスの利用に関して、利用者や当社の取引先が法令、利用規約、ガイドラインに反し、第三者又は当社の権利、財産、サービス等を保護する必要があるとき
                <br />
                公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者本人の同意を得ることが困難であるとき
                <br />
                国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                <br />
                前号に定めた利用目的の達成に必要な範囲内において個人データの取扱いの全部又は一部を委託することに伴って当該個人データが提供されるとき
                <br />
                特定の者との間で共同して利用される個人データが当該特定の者に提供される場合であって、その旨並びに共同して利用される個人データの項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人データの管理について責任を有する者の氏名又は名称を、あらかじめ、本人に通知し、又は本人が容易に知り得る状態に置いているとき
                <br />
                利用者は、本サービスを通じて得た他の利用者の個人情報に関し、本サービスの利用の範囲内においてのみ利用することができ、それ以外の利用はできないものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="h5" className={classes.textContent}>
              第28条 （秘密保持）
            </Typography>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                本規約において「秘密情報」とは、本サービスに関連して、利用者が、当社又は他の利用者から、書面、口頭若しくは記録媒体の受渡しその他の方法により開示された、技術上若しくは営業上の情報又は個人情報を意味するものとします。ただし、以下の情報については、秘密情報から除外するものとします。
                <br />
                (1) 開示の時に、既に公知であった情報
                <br />
                (2)
                開示された後、利用者の責めに帰すべからざる事由により公知となった情報
                <br />
                (3) 開示の時に、既に保有していた情報
                <br />
                (4)
                開示された後、正当な権限を有する第三者から守秘義務を課されることなく取得した情報
                <br />
                (5) 開示された秘密情報によることなく単独で開発した情報
                <br />
                利用者は、秘密情報を、本サービスの利用に必要な範囲内でのみ使用するものとし、当該秘密情報を第三者に開示又は漏洩してはならないものとします。ただし、以下の場合には、秘密情報を第三者に開示できるものとします。
                <br />
                (1) 予め開示を行う当事者から書面による承諾を得た場合
                <br />
                (2) 法令の定めにより開示を求められた場合
                <br />
                (3)
                裁判所、警察、税務署、弁護士会、消費者センターその他これらに準じる機関により開示を求められた場合
                <br />
                利用者は、秘密情報を記載した文書又は記録媒体等を複製する場合には、複製物に「秘密」等の表示を付するものとします。利用者は、当社が発行するID及びパスワード等の管理責任を負うものとします。利用者は、当社の事前の承諾なく、第三者にID及びパスワード等を開示してはならないものとします。
                <br />
                利用者は、本利用契約の有効期間満了又は解除等により本利用契約が終了した場合には、当社の指示に従い、秘密情報の全部又は一部を記載若しくは記録した文書又は記録媒体等を返還し、又はこれらを消去しなければならないものとします。
                <br />
              </Typography>
              <div className={classes.clearBoth}></div> {/* フロートのクリア */}
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TermsOfService);
