import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import translate from 'deepl';
const DEEPL_API_KEY = '9a93324d-bbfc-5608-01b3-b166abbf9350:fx';
//import { useParams } from 'react-router-dom';

import firebase, { db } from '../Firebase';

// react-slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../slick-item.css"
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';

import Button from '@material-ui/core/Button'
import EditIcon from '@mui/icons-material/Edit';
import Item from '../components/Item';


// コンポーネントの準備
import FileUpload from '../components/FileUpload.tsx';
import CommonDialog from '../components/CommonDialog2';
import AddOptionDialog from '../components/AddOptionDialog'
import MakeThumbnail from '../components/MakeThumbnail';
import { ExposureNeg1Rounded, PhoneEnabled } from '@material-ui/icons';
import { inflate } from '../components/rawinflate';
import { s2h, h2s } from '../components/Str2Hex';
import { XML } from '../components/ObjTree';
import { idText } from 'typescript';
import { ImageDialog } from '../components/ImageDialog.tsx';

//テスト用URL
//https://qrparking.jp/itemedit/1005002957994634
//https://qrparking.jp/itemedit/?itemid=FDRd20Ik1SucAZwexNz9
//https://qrparking.jp/itemedit/1005002957994634?uid=oO6c95kthlauPLvV9tG4xq3KfEl1
//https://qrparking.jp/itemedit/?itemid=FDRd20Ik1SucAZwexNz9&uid=oO6c95kthlauPLvV9tG4xq3KfEl1

// スタイル
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
  marginreftten:{
    marginLeft: 10
  },
  flexbox: {
    display: 'flex',
    'flex-wrap':'wrap',
  },
  optbox: {
    display: 'flex',
    margin: 'auto',    
  },
  bottonstyle: {
    display:'inline-block',
    margin: 10,
    border: 'none',
    '-webkit-filter': 'grayscale(0)',
    '-moz-filter': 'grayscale(0)',
    '-ms-filter': 'grayscale(0)',
    '-o-filter': 'grayscale(0)',
    filter: 'grayscale(0)',
    opacity:1,
    'text-transform': 'none',
  },
  bottonstyleselected: {
    margin: 10,
    display: 'inline-block',
    'border-style': 'solid',
    'border-width': '2px',
    'border-color': 'red',
    '-webkit-filter': 'grayscale(0)',
    '-moz-filter': 'grayscale(0)',
    '-ms-filter': 'grayscale(0)',
    '-o-filter': 'grayscale(0)',
    filter: 'grayscale(0)',
    opacity:1,
  },
  bottonstyledisable: {
    margin: 10,
    border: 'none',
    '-webkit-filter': 'grayscale(100)',
    '-moz-filter': 'grayscale(100)',
    '-ms-filter': 'grayscale(100)',
    '-o-filter': 'grayscale(100)',
    filter: 'grayscale(100)',
    opacity:0.5,
  },
  displaynone: {
    display: 'none'
  },
  FileUpload: {
    margin: 10,
    border: 'none',
    width:'100%',
  },
  tagsbox: {
    width: '100%',
    display: 'flex',
    'flex-wrap': 'wrap',
    'flex-direction': 'row',
    'justify-content': 'space-evenly',
    fontSize: '12px',
  },
  removebottn: {
    'color': 'rgba(200,0,0,1)',
    'width': '14px',
    'font-size':'20px',
    'min-width': '0px',
    'padding': '0px 8px 1px 8px',
    'top': '-10px',
    'left': '0px',
    'position': 'relative',
    'display': 'inline-block',
    'z-index': '1',
  },
  editbottn: {
    'color': 'rgba(200,0,0,1)',
    'width': '14px',
    'font-size':'20px',
    'min-width': '0px',
    'padding': '0px 8px 1px 8px',
    'top': '16px',
    'left': '-20px',
    'position': 'relative',
    'display': 'inline-block',
    'z-index': '1',
  },
  itemaddbottn: {
    'color': 'rgba(0,0,0,0.5)',
    'font-size':'40px',
    'padding': '10px 10px 10px 10px',
    'top': '0px',
    'left': '0px',
    'position': 'relative',
  },
  lineremovebottn: {
    'color': 'rgba(200,0,0,1)',
    'width': '14px',
    'font-size':'20px',
    'min-width': '0px',
    'padding': '0px 8px 1px 8px',
    'top': '16px',
    'left': '0px',
    'position': 'relative',
    'display': 'inline-block',
  },
  lineaddbottn: {
    'color': 'rgba(0,0,0,0.5)',
//    'width': '14px',
    'font-size':'40px',
//    'min-width': '0px',
    'padding': '10px 10px 10px 10px',
    'top': '0px',
    'left': '0px',
    'position': 'relative',
  },
  });

// react-slick
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  centerMode: false,
  centerPadding: '20px',
  fade: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

class itemEdit extends React.Component {

  constructor(props) {
    //console.log("constructor::::" + JSON.stringify(props) );
    super(props);
    //this.shareDialogToggle = this.shareDialogToggle.bind(this);
    if (this.props.match) {
      const itemId = this.props.match.params.itemId;
      if (itemId == 'itemid') {
        console.log('this.props.location.search :',this.props.location.search)
        const id = this.props.location.search.split("itemid=")[1].split("&")[0] //"itemid?itemid=xxxxxxxxx&"
        //const docRef = db.collection("users").doc(user.uid).collection("items").doc(id);
        const docRef = db.collectionGroup('items').where("itemId","==",id)
        docRef.get().then((doc) => { 
          if (doc.docs.length) {
            const dat = doc.docs[0].data();
            window.location.href = 'https://qrparking.jp/itemedit/' + dat.sourceItemId + this.props.location.search + (dat.domain ? this.props.location.search ? "&domain="+dat.domain:"?domain="+dat.domain:"");
            return
          }
          else {
            window.location.href = 'https://qrparking.jp/itemedit/errorid';
            return
          }
        });
      }
    }
    this.state = {
      item: {
        itemId: 'initializeing',
        ambassador: { ambassadordisplayName: '', MarkupRatio: 2 },
        imageModule: { imagePathList: ["/images/Preloader_8.gif"] }, //Preloader_8.gif
        pageModule: { title: "loading", description: "loading" },
        priceModule: { minAmount: { value: 0 },maxAmount: { value: 0 }, minActivityAmount: { value: 0 },maxActivityAmount: { value: 0 } },
        skuModule: { productSKUPropertyList: [{ skuPropertyName: "loading",order:1,skuPropertyId:"", skuPropertyValues: [{ skuPropertyImagePath: "", skuPropertyImageSummPath: "", propertyValueName: "" ,skuPropertyValueShowOrder:0}] }], skuPriceList: [{ skuPropIds: "", skuVal: {skuActivityAmount:{value:0}}}]},
        //skuModule: { productSKUPropertyList: [{ skuPropertyName: "loading", skuPropertyValues: [{ propertyValueName: "" }, {selected : false}] }] },
        freightAmount: { shipping_price: "", shipping_info: "", shipping_delivery: "" },
        tags: [],
        status: "edit",
        files: [],
      },
      mainimages: ["/images/Preloader_8.gif"],
      skuAmountvalue: { min:0, max:0, match:false, inventory:-1 },
      selectedpropertyValueId: [],
      selectedpropertyValueLst: [],
      editOptDlgIndex: [],
      opt: {},
      taglst: [],
      commDlg: false,
      addOptDlg: false,
      addOptDlgIndex:0,
      addLinDlg: false,
      status: "edit",
      error: "no_data",
    }
  }

  shareDialogToggle = () => this.props.shareDialogToggle;

  setData = (user,dat) => {
    if ('imageModule' in dat && !dat.error) {
      var mainimages = [];
      var skuAmountvalue = { min:0, max:0, match:false, inventory:-1 };
      var item = dat;
      delete item.dt;
      window.__item_source_URL = item.url;
      const taglst = user.tags ? user.tags : [];
      if (!'ambassador' in item) {
        item.ambassador = user;
      }
      if (!dat.imageModule.imagePathListoligin) {
        const list2 = dat.imageModule.imagePathList;
        dat.imageModule.imagePathListoligin = list2;
      }
      dat.imageModule.imagePathList.map(_lst => {
        mainimages.push({ img: _lst, selectstatus: dat.imageModule.pendingimagePathList ? "selected" : "enable" });
      });
      if (dat.imageModule.pendingimagePathList) {
        dat.imageModule.pendingimagePathList.map(_lst => {
          mainimages.push({ img: _lst, selectstatus: "enable" });
        });
      }
      if ('minActivityAmount' in dat.priceModule) {
        skuAmountvalue.min = dat.priceModule.minActivityAmount.value;
      }
      else if ('minAmount' in dat.priceModule) {
        skuAmountvalue.min = dat.priceModule.minAmount.value;
      }
      if ('maxActivityAmount' in dat.priceModule) {
        skuAmountvalue.max = dat.priceModule.maxActivityAmount.value;
      }
      else if ('maxAmount' in dat.priceModule) {
        skuAmountvalue.max = dat.priceModule.maxAmount.value;
      }
      if (skuAmountvalue.min == 0 && skuAmountvalue.max !=0) {
        skuAmountvalue.min = skuAmountvalue.max;
        skuAmountvalue.match = true;
      }
      else if (skuAmountvalue.max == 0 && skuAmountvalue.min !=0){
        skuAmountvalue.max = skuAmountvalue.min;
        skuAmountvalue.match = true;
      }
      if (!('files' in item)) {
        item.files = [];
      }
      const tags = item.tags;
      const tagstr = tags ? tags.length ? tags.join(' ') : "" : "";
      const savemainimages = JSON.parse(JSON.stringify(mainimages));

      this.setState({
        error: "",
        item: item,
        ambassador:user,
        tagtoUpperCasestr: item.tags ? item.tags.join(' ') : "",
        mainimages: mainimages,
        savemainimages :savemainimages ,
        skuAmountvalue: skuAmountvalue,
        taglst: taglst,
        tagstr: tagstr,
        status: item.status?item.status:"edit",
      });
      window.scrollTo(0, 0);
    } else {
      console.log("setData error data")
      this.setState({
        error: "error-data",
      });              
    }
  }
  //データ取得
  //uidで指定したメンバーの値を取得
  getData = async (user, itemId) => {
    let id = '';
    let domain = ';'
    let doc = {};
    let dat = {};
    const userdocRef = db.collection("users").doc(user.uid);
    let itemdocRef = {};
    if (itemId == 'new') {
      let newItemdata = this.state.item;
      const collection = db.collection("users").doc(user.uid).collection("items")
      const newDocRef = collection.doc()
      id = newDocRef.id
      newItemdata.itemId = id;
      newItemdata.sourceItemId = id;
      newItemdata.dt = firebase.firestore.FieldValue.serverTimestamp()
      newItemdata.ambassador = user;
      newItemdata.imageModule = { imagePathList: [] };
      newItemdata.pageModule = { title: "", description: "" };
      newItemdata.skuModule = { productSKUPropertyList: [], skuPriceList: [] }
      newItemdata.domain = "qrparking.jp"
      const ref = await newDocRef.set(
        newItemdata
      ).catch(error => {
        console.error('There has been a problem with your adding new data operation:', error);
      });
      console.log("getnewdata::id::" + id)
      const url = 'https://qrparking.jp/itemedit/' + id + (this.props.location.search?this.props.location.search+"&":"?") + "itemid=" + id + "&domain=qrparking.jp";
      setTimeout("window.location.href = '" + url + "'", 200);
    }
    else if (itemId == 'getdata') {
      if (this.props.location.search && this.props.location.search.split("data=")[1]) {
        const chunkdat = this.props.location.search.split("data=")[1].split("&")[0];
        const index = Number(this.props.location.search.split("index=")[1].split("&")[0]);
        const length = Number(this.props.location.search.split("length=")[1].split("&")[0]);
        const sourceItemId = this.props.location.search.split("sourceItemId=")[1].split("&")[0];
        domain = this.props.location.search.split("domain=")[1].split("&")[0];
        id = s2h(domain + sourceItemId);
        itemdocRef = userdocRef.collection("items").doc(id)
        dat.itemId = id;
        dat.sourceItemId = sourceItemId;
        dat.ambassador = user;
        dat.dt = firebase.firestore.FieldValue.serverTimestamp()
        await itemdocRef.set({
          ...dat,
          chunkdat: firebase.firestore.FieldValue.arrayUnion({ dat: chunkdat, index: index }),
          cunklength: length,
          sourceItemId: sourceItemId,
          domain: domain,
        }, { merge: true })
        if (index + 1 == length) {
          const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
          await _sleep(2000);
          const itemdocRef2 = userdocRef.collection("items").doc(id);
          const doc2 = await itemdocRef2.get();
          const dat2 = doc2.data();
          let chunkdat2 = dat2.chunkdat;
          if (dat2.cunklength <= chunkdat2.length) {
            if (dat2.priceModule && dat2.status && dat2.status != "delete") {
              alert("すでに保存されたデータがあります\n上書きするにはアイテムリストから削除してください")
              window.location.href = 'https://qrparking.jp/itemedit/' + id + "?itemId=" + itemId + "&sourceItemId=" + sourceItemId + "&domain=" + domain;
              return  
            }
            let strdat = '';
            let arraydat = [];
            /*
            Array.prototype.forEach.call(chunkdat2,(v) => { 
              arraydat[v.index]=v.dat
            })*/
            chunkdat2.forEach((v) => {
              arraydat[v.index] = v.dat
            })
            for (let i = 0; i < arraydat.length; i++) {
              if (arraydat[i]) {
                let tempstr = arraydat[i];
                tempstr = atob(tempstr); // base64デコード
                tempstr = inflate(tempstr); // 復号
                tempstr = decodeURIComponent(tempstr); // UTF8 → UTF16
                strdat += tempstr
              }
              else {
                //console.log("else")
                dat = { error: "missing_chunk" };
              }
            }
            delete dat2.chunkdat;
            delete dat2.cunklength;
            dat = { ...dat2, ...JSON.parse(strdat) };
            if (dat.domain == "taobao.com") {
              let tdat = {};
              tdat.t = dat.pageModule.title.replace(/\s+/g, '');
              tdat.d = dat.pageModule.description.replace(/\s+/g, '');
              tdat.pl = dat.skuModule.productSKUPropertyList.map((e) => {
                return {
                  sn: e.skuPropertyName.replace(/\s+/g, ''),
                  sv: e.skuPropertyValues.map(e2 => e2.propertyValueDisplayName.replace(/\s+/g, '')),
                }
              });
              tdat = {data:tdat}
              //console.log(JSON.stringify(tdat));
              let objTree = new XML.ObjTree();
              const xmldat = objTree.writeXML(tdat);
              //console.log(xmldat);
              //console.log(JSON.stringify(objTree.parseXML(xmldat)));
              const result = await translate({
                free_api: true,
                text: xmldat,
                target_lang: 'JA',
                source_lang: 'ZH',
                auth_key: DEEPL_API_KEY,
                tag_handling: 'xml',
                split_sentences: '1',
                preserve_formatting:'1',
                // All optional parameters available in the official documentation can be defined here as well.
                /*
                 export interface Parameters {
                  free_api: Boolean;
                  auth_key: string;
                  text: string;
                  source_lang?: DeeplLanguages;
                  target_lang: DeeplLanguages;
                  split_sentences?: '0' | '1' | 'nonewlines';
                  preserve_formatting?: '0' | '1';
                  formality?: 'default' | 'more' | 'less';
                  tag_handling?: string[];
                  non_splitting_tags?: string[];
                  outline_detection?: string;
                  splitting_tags?: string[];
                  ignore_tags?: string[];
                }
                https://www.deepl.com/docs-api/simulator/
                */
              })
              .catch(error => {
                  console.error(error)
              });
              //console.log(result.data.translations[0].text);
              const tdat2 = objTree.parseXML(result.data.translations[0].text);
              //const tdat2 = JSON.parse(result.data.translations[0].text);
              //console.log(JSON.stringify(tdat2));
              dat.pageModule.title = tdat2.data.t;
              dat.pageModule.description = tdat2.data.d;
              let tmparray = Array.isArray(tdat2.data.pl) ? tdat2.data.pl : [tdat2.data.pl];
              dat.skuModule.productSKUPropertyList = tmparray.map((e,i) => {
                let tmparray = Array.isArray(e.sv) ? e.sv : [e.sv];
                return {
                  isShowTypeColor: dat.skuModule.productSKUPropertyList[i].isShowTypeColor,
                  order: dat.skuModule.productSKUPropertyList[i].order,
                  showType: dat.skuModule.productSKUPropertyList[i].showType,
                  showTypeColor: dat.skuModule.productSKUPropertyList[i].showTypeColor,
                  skuPropertyId: dat.skuModule.productSKUPropertyList[i].skuPropertyId,
                  skuPropertyName: e.sn,
                  skuPropertyValues: tmparray.map((e1,i1 )=> {                     
                    return {
                      propertyValueDisplayName: e1,
                      propertyValueId: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].propertyValueId,
                      propertyValueIdLong: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].propertyValueIdLong,
                      propertyValueName: e1,
                      selectstatus: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].selectstatus,
                      skuColorValue: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].skuColorValue,
                      skuPropertyImagePath: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].skuPropertyImagePath,
                      skuPropertyImageSummPath: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].skuPropertyImageSummPath,
                      skuPropertyTips: e1,
                      skuPropertyValueShowOrder: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].skuPropertyValueShowOrder,
                      skuPropertyValueTips: e1,
                      }              
                  })  
                }
              });
            }
            dat.ambassador = user;
          }
          else {
            //console.log("else")
            alert("データの構成に失敗しました\n再読み込みしてください")
            dat = { error: "missing_chunk" };
          }
        }
        else {
          //console.log("else")
          window.close();
          return
        }
      }
      else {
        dat = { error: "no_data" }; 
      }
    }
    else {
      //URLパラメーターで"itemid="があればそれを優先
      const itemid2 = this.props.location.search.split("itemid=")[1]?this.props.location.search.split("itemid=")[1].split("&")[0]:"";
      if (itemid2) {
        itemdocRef = userdocRef.collection("items").doc(itemid2);
        doc = await itemdocRef.get();
        if (doc.exists) {
          dat = doc.data();
        }
        else {
          dat = {error:"itemid Does not exist"}
        }
      }
      else {
        //URLパラメーターで"domain="があればそれを優先
        domain = this.props.location.search.split("domain=")[1]?this.props.location.search.split("domain=")[1].split("&")[0]:"";
        if (!domain) {
          let itemid2 = h2s(itemId);
          if (itemid2.indexOf("taobao.com")!=-1) {
            domain = "taobao.com";
          }
          else if(itemid2.indexOf("aliexpress.com")!=-1){
            domain = "aliexpress.com";
          }
          else if(itemid2.indexOf("qrparking.jp")!=-1){
            domain = "qrparking.jp";
          }
        }
        itemdocRef = db.collection("users").doc(user.uid).collection("items").where("sourceItemId", "==", itemId);        
        doc = await itemdocRef.get();
        //ドキュメントの存在確認
        if (doc.docs.length) {
          for (let i = 0; i < doc.docs.length; i++){
            dat = doc.docs[i].data();
            if (dat.domain && dat.domain == domain)
            {
              dat.itemId = doc.docs[i].id;
              break
            }
            if((!dat.domain && !domain) ||  (!dat.domain || doc.docs.length == 1))
            {
              if (dat.itemId==dat.sourceItemId) {
                dat.domain = "qrparking.jp";
              }
              else {
                dat.domain = "aliexpress.com";
              }
              dat.itemId = doc.docs[i].id;
              break
            }
            if (i == doc.docs.length) {
              dat = { error: "no_item_in_database" };
            }
          }
        }
        else {
          dat = { error: "no_itemId_in_database" };  
        }
        if (!Object.keys(dat).length) {
          dat = { error: "no_data" }
        }   
      }
    }
    this.setData(user, dat)
  }
    //値を取得
  componentDidMount = () => {
        //ログインしてるかどうかチェック
    //console.log("componentDidMount:::::::::::::"  + JSON.stringify(this.props) )
    firebase.auth().onAuthStateChanged(user => {
      if (this.props.match) {
        if (this.props.location.search && this.props.location.search.split("uid=")[1]) {
          const uid = this.props.location.search.split("uid=")[1].split("&")[0]
          //"itemid?id=xxxxxxxxx&" URLパラメータを受け取った場合の処理
          if (uid) {
            const docRef = db.collection("users").doc(uid)
            docRef.get().then((doc) =>{
              if (doc.exists) {
                const ambassador = doc.data();
                if (ambassador.uid == this.props.userdata.uid || this.props.userdata.status == "super") {
                  this.getData(ambassador, this.props.match.params.itemId);
                }
              }  
            });
          }
          else {
            this.getData(this.props.userdata, this.props.match.params.itemId);  
          }            
        }
        else {
          this.getData(this.props.userdata, this.props.match.params.itemId);  
        }            
      }
      else {
        console.log("not props.computedMatch::",this.props);
      }
    });
  }

  getselectedpropertyValue = () => {
    const dat = this.state.item;
    if (this.state.selectedpropertyValueId.length) {
      const selectedpropertyValueId = this.state.selectedpropertyValueId.join(',');
      var skuPriceList = dat.skuModule.skuPriceList.filter((_lst, c1) => {
        return _lst.skuPropIds==selectedpropertyValueId;
      });
      if (skuPriceList.length) {
        return skuPriceList[0];        
      }
    }
    return { skuPropIds:this.state.selectedpropertyValueId,skuVal: { skuActivityAmount: {currency: "JPY", value:""},inventory:"" }};
  }

  chkskuPriceList = (selectedpropertyValueId, dat) => {
    var res = { min:0, max:0, match:false, inventory:-1 };
    for (let i = 0; i < dat.skuModule.skuPriceList.length; i++){
      if ((dat.skuModule.skuPriceList[i].skuVal.inventory > 0 || (!selectedpropertyValueId[0])) && this.searchlist(dat.skuModule.skuPriceList[i].skuPropIds, selectedpropertyValueId)) {
        if (dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount) {
          if (dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value < res.min || res.min == 0) {
            res.min = dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value;
          }
          if (dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value > res.max || res.max == 0) {
            res.max = dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value;
          }            
        } else {
          if (dat.skuModule.skuPriceList[i].skuVal.skuAmount.value < res.min || res.min == 0) {
            res.min = dat.skuModule.skuPriceList[i].skuVal.skuAmount.value;
          }
          if (dat.skuModule.skuPriceList[i].skuVal.skuAmount.value > res.max || res.max == 0) {
            res.max = dat.skuModule.skuPriceList[i].skuVal.skuAmount.value;
          }            
        }
        if (res.inventory < dat.skuModule.skuPriceList[i].skuVal.inventory) {
          res.inventory = dat.skuModule.skuPriceList[i].skuVal.inventory;//暫定的な在庫数をセット          
        }
      }          
    }
    console.log('dat.skuModule.productSKUPropertyList.length::' +dat.skuModule.productSKUPropertyList.length + ' selectedpropertyValueId::'+ selectedpropertyValueId.join(','));
    if (dat.skuModule.productSKUPropertyList.length == selectedpropertyValueId.filter(function (value) { return value != null}).length) {
      res.match = true;
    }
    else {
      res.inventory = -1
    }
    //console.log('res1::' + res.join(','));
    return res
  }

  searchlist = (skuPropIds, selectedpropertyValueId) => {
    var res = true;
    for (let i = 0; i < selectedpropertyValueId.length; i++) {
      if (selectedpropertyValueId[i]!=null) {
        if (skuPropIds.indexOf(selectedpropertyValueId[i]) == -1) {
          res = false;
        };
      }
    }
    //console.log('res2::' + res + ' :: '+skuPropIds+'::'+selectedpropertyValueId.join(','));
    return res;
  }

  selectButtonClick = (i1, i2, e) => {

    var dat = this.state.item;
    var mainimages = this.state.mainimages;
    var selectedpropertyValueId = this.state.selectedpropertyValueId;
    var skuAmountvalue = this.state.skuAmountvalue;
    var selectedpropertyValueLst = this.state.selectedpropertyValueLst;
    var spVId;
    var skuAv;
    this.slider.slickGoTo(0);
    if ((dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].selectstatus != 'disable' && dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].selectstatus != 'selected') /*|| (dat.skuModule.productSKUPropertyList[i1].skuPropertyId == 200007763 && (dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].propertyValueId == 201336100 || dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].propertyValueId == 201441035))*/) {
      selectedpropertyValueId[i1] = dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].propertyValueId;
      //console.log("selectstatus:::::" + dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].selectstatus);
    }
    else if(dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].selectstatus == ('selected')) {
      selectedpropertyValueId[i1] = null;
    }
    dat.skuModule.productSKUPropertyList.map((_lst,c1) => {
      _lst.skuPropertyValues.map((_lst2, c2) => {
        if (_lst.skuPropertyId == dat.skuModule.productSKUPropertyList[i1].skuPropertyId || _lst.skuPropertyId == 200007763 ) {
          if ((_lst2.propertyValueId == dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].propertyValueId) || (_lst.skuPropertyId == 200007763 && (_lst2.propertyValueId == 201336100 || _lst2.propertyValueId == 201441035))) {
            //選択項目にフォーカスがあり選択されている項目
            if ((_lst2.selectstatus != 'selected') || (_lst.skuPropertyId == 200007763 && (_lst2.propertyValueId == 201336100 || _lst2.propertyValueId == 201441035))) {
              //console.log("_lst2.selectstatus:" + _lst2.selectstatus);
              //console.log("selected selectedpropertyValueId:" + selectedpropertyValueId.join(','));
              _lst2.selectstatus = 'selected';
              selectedpropertyValueId[c1] = _lst2.propertyValueId;//送信国が中国の場合
              selectedpropertyValueLst[c1] = _lst2.propertyValueDisplayName;
              skuAmountvalue = this.chkskuPriceList(selectedpropertyValueId, dat);
              //console.log(c1+':'+ c2 +'selectstatus1::selected');
              if (_lst2.skuPropertyImagePath) {
                //mainimages[0] = 'https://qrparking.jp/api/' + _lst2.skuPropertyImagePath.replace('https://',"").replace("_50x50.jpg","").replace("_640x640.jpg","")+"_640x640.jpg";              
                mainimages[0].img = _lst2.skuPropertyImagePath.replace("_50x50.jpg","_640x640.jpg")
              }
            }
            else if (_lst2.selectstatus == 'selected') {
              _lst2.selectstatus = 'chk';
              //console.log(c1+':'+ c2 +'selectstatus1::chk');
              selectedpropertyValueId[c1] = null;
              selectedpropertyValueLst[c1] = null;
              skuAmountvalue = this.chkskuPriceList(selectedpropertyValueId, dat);
            }              
          }
          //選択項目にフォーカスがあり選択されてない項目
          else {
/*
            if (_lst2.selectstatus != 'selected') {
              if (selectedpropertyValueId[c1]) {
                _lst2.selectstatus = 'disable';
                //console.log(c1+':'+ c2 +'selectstatus2::disable');
              }
              else {
                _lst2.selectstatus = 'chk';
                //console.log(c1+':'+ c2 +'selectstatus2::chk');
              }
              _lst2.selectstatus = 'chk';          
              
            }
            else {              
              _lst2.selectstatus = 'chk';          
            }
*/
            _lst2.selectstatus = 'chk';
          }
        }
        //選択項目にフォーカスがなし
        else {
          if (selectedpropertyValueId[c1] == null) {
            _lst2.selectstatus = 'chk';
            //console.log(c1+':'+ c2 +'selectstatus3::chk');
          }
        }
        if (_lst2.selectstatus == 'chk') {
          spVId = selectedpropertyValueId.slice(0, selectedpropertyValueId.length);
          spVId[c1] = _lst2.propertyValueId;
          //console.log("spVId:" + spVId.join(','));
          skuAv = this.chkskuPriceList(spVId, dat);
          //console.log('skuAv' + skuAv.join(','));
          if (skuAv.min == 0) {
            _lst2.selectstatus = 'disable';
            //console.log(c1+':'+ c2 +'selectstatus4::disable');
          }
          else {
            _lst2.selectstatus = 'enable';
            //console.log(c1+':'+ c2 +'selectstatus4::enable');
          }        
        }
      });
    });
    dat.priceModule = {
      maxActivityAmount: { value: skuAmountvalue.max },
      minActivityAmount: { value: skuAmountvalue.min },
    }
    this.setState({
      item: dat,
      mainimages: mainimages,
      skuAmountvalue: skuAmountvalue,
      selectedpropertyValueLst: selectedpropertyValueLst,
      selectedpropertyValueId:selectedpropertyValueId,
    });
  }

  optdelete = (i1, i2, e) => {
    /*
      delete 連想配列.key
      // もしくは
      delete 連想配列[key]
    */
    var dat = this.state.item;
    //filter() の機能を利用して、特定条件の要素を「削除」することができます。
    var skuPriceList = dat.skuModule.skuPriceList.filter((_lst, c1) => {
        return !this.searchlist(_lst.skuPropIds, [dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].propertyValueId]);
    });
    dat.skuModule.skuPriceList = skuPriceList;
    dat.skuModule.productSKUPropertyList[i1].skuPropertyValues.splice(i2, 1)
    var res = this.chkskuPriceList([],dat)
    this.setState({
      item: dat,
      skuAmountvalue: res,
      deleteDlg: false,
      deleteOptDlgIndex:false,
    });
  }

  optedit = (i1, i2, e) => {
    /*
      delete 連想配列.key
      // もしくは
      delete 連想配列[key]
    */
    var dat = this.state.item;
    //filter() の機能を利用して、特定条件の要素を「削除」することができます。
    var skuPriceList = dat.skuModule.skuPriceList.filter((_lst, c1) => {
        return !this.searchlist(_lst.skuPropIds, [dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].propertyValueId]);
    });
    dat.skuModule.skuPriceList = skuPriceList;
    dat.skuModule.productSKUPropertyList[i1].skuPropertyValues.splice(i2, 1)
    var res = this.chkskuPriceList([],dat)
    this.setState({
      item: dat,
      skuAmountvalue: res,
      addOptDlg: false,
      editOptDlg:false,
      editOptDlgIndex: [],
    });
  }


  linedelete = (index) => {
    /*
    delete 連想配列.key
    // もしくは
    delete 連想配列[key]
    */
    var dat = this.state.item;
    dat.skuModule.skuPriceList = []; //すべての価格表をリセット
    dat.skuModule.productSKUPropertyList.splice(index, 1); //プロパティリスト項目を削除
    var res = this.chkskuPriceList([],dat)
    this.setState({
      item: dat,
      skuAmountvalue: res,
      deleteDlg: false,
      deleteLinDlgIndex:false,
    });      
  }

  optaddbottnhandle = (index) => {
    this.setState({ addOptDlg: true, addOptDlgIndex:index })
  }

  optdeletebottnhandle = (i1, i2) => {
    this.setState({ deleteDlg: true, deleteOptDlgIndex:[i1,i2] })
  }
  
  opteditbottnhandle = (i1, i2, opt) => {
    this.setState({ editOptDlg: true, addOptDlgIndex:i1, editOptDlgIndex:[i1,i2], opt:opt })
  }
  

  lineaddbottnhandle = (e) => {
    this.setState({ addLinDlg: true })
  }
  
  linedeletebottnhandle = (index) => {
    this.setState({ deleteDlg: true ,deleteLinDlgIndex:index})
  }
  
  itemdeletehandle = (e) => {
    if (this.state.deleteOptDlgIndex) {
      this.optdelete(this.state.deleteOptDlgIndex[0], this.state.deleteOptDlgIndex[1])
    }
    if (this.state.deleteLinDlgIndex>=0) { 
      this.linedelete(this.state.deleteLinDlgIndex)
    }
  }
  
  submitonClick = () => {
    const user = this.state.ambassador;
    const itemId = this.state.item.itemId;
    const userdocRef = db.collection("users").doc(user.uid);
    const itemdocRef = userdocRef.collection("items").doc(itemId);
    let dat = this.state.item;
    let tags = [];
    let list = [];
    let list2 = [];
    const skuAmountvalue = this.chkskuPriceList([], dat);
    /*
    const elements = Array.from(document.getElementsByClassName('mainimages'));
    elements.map(e => {
      list.push(e.src);
    });
    */

    if (this.state.tagstr) {
      tags = this.state.tagstr.split(' ')
    }
    let taglst = tags.concat(this.state.taglst);
    let set = new Set(taglst);
    taglst = [...set];
    taglst = taglst.filter(Boolean).sort();
    console.log("tags: ",taglst)
    userdocRef.set({ tags: taglst }, { merge: true });

    this.state.savemainimages.map(e => {
      if (e.selectstatus == "selected") {
        list.push(e.img);
      }
      else {
        list2.push(e.img);
      }
    });

    if (dat.skuModule.productSKUPropertyList) {
      dat.skuModule.productSKUPropertyList.map(_lst => {
        _lst.skuPropertyValues.map(_lst2 => {
          _lst2.selectstatus = 'enable';
        });
      });  
    }
    dat.imageModule.imagePathList = list;
    dat.imageModule.pendingimagePathList = list2;
    dat.ambassador = user;
    dat.ambassador.ambassadordisplayName = document.getElementsByName('ambassadordisplayName')[0].value;
    dat.pageModule.title = document.getElementsByName('title')[0].value;
    dat.pageModule.description = document.getElementsByName('description')[0].value;
    if (skuAmountvalue.min) {
      dat.priceModule.minActivityAmount = 
        {currency:"JPY", formatedAmount: "￥" + skuAmountvalue.min.toLocaleString(), value: skuAmountvalue.min };
    }
    if (skuAmountvalue.max) {
      dat.priceModule.maxActivityAmount = 
        { currency:"JPY",formatedAmount: "￥" + skuAmountvalue.max.toLocaleString(), value: skuAmountvalue.max };
    }
    dat.memo = document.getElementsByName('memo')[0].value;
    tags = tags.filter(Boolean).sort();
    dat.tags = tags;
    dat.status = this.state.status; //delete edit available
    dat.editby = this.props.userdata;
    dat.dt = firebase.firestore.FieldValue.serverTimestamp()
//    itemdocRef.set(dat, { merge: true })
    itemdocRef.set(dat)
      .then(() => {
        const href = '"https://qrparking.jp/itemedit/' + dat.sourceItemId + this.props.location.search + ((dat.domain && !this.props.location.search.indexOf('domain=',0))? this.props.location.search ? '&domain='+dat.domain:'?domain='+dat.domain:'') + '"'
        setTimeout('window.location.href='+href, 200);
      });
  }

  setTagList = () => {
    //const user = this.props.userdata; //これが参照しているのは現在ログインしている人であってitemの所有者ではない可能性がある
    const user = this.state.item.ambassador
    const userdocRef = db.collection("users").doc(user.uid);
    const taglst = this.state.taglst;
    const removals = this.state.tagstr.split(' ');
    const newtags = taglst.filter(function(v){
      return ! removals.includes(v);
    });
    userdocRef.set({ tags: newtags }, { merge: true })
    .then(() => this.setState({ commDlg: false }))
    .then(()=>{ setTimeout("window.location.reload(true)", 200) });
  }
  
  addlin = (opt, catNo , editOptDlgIndex) => {
    var item = this.state.item;
    var order = this.state.item.skuModule.productSKUPropertyList.length ? Math.max.apply(null, this.state.item.skuModule.productSKUPropertyList.map(function (o) { return o.order; })) + 1 : 1 ;
    const e = {
      isShowTypeColor: false,
      order: order,//連番 並び順を保証するものらしい
      showType: "none",
      showTypeColor: true,
      skuPropertyId: order, //unique number
      skuPropertyName:opt.name,
      skuPropertyValues:[],      
    };
    item.skuModule.productSKUPropertyList.push(e);
    this.setState({ item: item , addLinDlg: false});
  }

  addopt = (opt, catNo, editOptDlgIndex) => {
    var item = this.state.item;
    //連番処理
    var propertyValueId;
    var propertyValueIdLong;
    var skuPropertyValueShowOrder;
    if (editOptDlgIndex.length) {
      propertyValueId = item.skuModule.productSKUPropertyList[editOptDlgIndex[0]].skuPropertyValues[editOptDlgIndex[1]].propertyValueId,
      propertyValueIdLong = item.skuModule.productSKUPropertyList[editOptDlgIndex[0]].skuPropertyValues[editOptDlgIndex[1]].propertyValueIdLong;
      skuPropertyValueShowOrder = item.skuModule.productSKUPropertyList[editOptDlgIndex[0]].skuPropertyValues[editOptDlgIndex[1]].skuPropertyValueShowOrder;
    } else {
      const order = item.skuModule.productSKUPropertyList[catNo].skuPropertyValues.length ? Math.max.apply(null, this.state.item.skuModule.productSKUPropertyList[catNo].skuPropertyValues.map(function (o) { return o.skuPropertyValueShowOrder; })) + 1 : (catNo+1)*10000 + 1 ;
      propertyValueId = order;
      propertyValueIdLong = order;
      skuPropertyValueShowOrder = order;        
    }
    const e = {
      propertyValueDefinitionName: opt.name,
      propertyValueDisplayName: opt.name,
      propertyValueId: propertyValueId,//unique number
      propertyValueIdLong: propertyValueIdLong, //unique number
      propertyValueName: opt.name,
      selectstatus:"enable",
      skuColorValue: "",
      skuPropertyImagePath: opt.img?opt.img:"",
      skuPropertyImageSummPath: opt.img?opt.img:"",
      skuPropertyTips: opt.name,
      skuPropertyValueShowOrder: skuPropertyValueShowOrder,
      skuPropertyValueTips: opt.name,      
    };
    if (editOptDlgIndex.length) {
      item.skuModule.productSKUPropertyList[editOptDlgIndex[0]].skuPropertyValues[editOptDlgIndex[1]]=e;
    } else {
      item.skuModule.productSKUPropertyList[catNo].skuPropertyValues.push(e);
     }
    this.setState({ item: item, addOptDlg: false, editOptDlg: false, editOptDlgIndex: [], addOptDlgIndex: 0 });    
  }
  
  imgselectButtonClick = (index) => {
    var mainimages = this.state.mainimages;
    var tmp
    if (mainimages[index].selectstatus == "selected") {
      mainimages[index].selectstatus = 'enable';
      for (let i = index; i < mainimages.length-1; i++) {
        if (mainimages[i+1].selectstatus == 'enable') break;
        tmp = mainimages[i+1];
        mainimages[i+1] = mainimages[i];
        mainimages[i] = tmp;
      }
    } 
    else {
      mainimages[index].selectstatus = "selected";
      for (let i = index ; i > 0; i--){
        if (mainimages[i-1].selectstatus == 'selected') break;
        tmp = mainimages[i-1];
        mainimages[i-1] = mainimages[i];
        mainimages[i] = tmp;
      }
    }
    const savemainimages = JSON.parse(JSON.stringify(mainimages));
    this.setState({
      mainimages: mainimages,
      savemainimages: savemainimages ,
    });
  }

  filesselectButtonClick = (index) => {
    var item = this.state.item;
    var files = item.files;
    var tmp
    if (files[index].selectstatus == "selected") {
      files[index].selectstatus = 'enable';
      for (let i = index; i < files.length-1; i++) {
        if (files[i+1].selectstatus == 'enable') break;
        tmp = files[i+1];
        files[i+1] = files[i];
        files[i] = tmp;
      }
    } 
    else {
      files[index].selectstatus = "selected";
      for (let i = index ; i > 0; i--){
        if (files[i-1].selectstatus == 'selected') break;
        tmp = files[i-1];
        files[i-1] = files[i];
        files[i] = tmp;
      }
    }
    item.files = files;
    this.setState({
      item: item
    });
  }

  namehandleChange = e => {
    const params = this.state.item;
    params.ambassador[e.target.name] = e.target.value;
    this.setState({ Item : params });
  };

  pageModulehandleChange = e => {
    const params = this.state.item;
    params.pageModule[e.target.name] = e.target.value;
    this.setState({item:params} );
  };

  texthandleChange = e => {
    const params = this.state.item;
    params[e.target.name] = e.target.value;
    this.setState({item:params} );
  };

  taghandleChange = e => {
    var item = this.state.item;
    const i = item.tags?item.tags.indexOf(e.target.value):-1;
    if (i == -1) {
      if (item.tags) {
        item.tags.push(e.target.value);
      }
      else {
        item.tags = [e.target.value];
      }
    }
    else {
      item.tags.splice( i, 1 );
    }
    const tagstr = item.tags?item.tags.sort().join(' '):'';
    this.setState({ item: item ,tagstr: tagstr});
  }

  tagshandleonBlur = e => {
    if (e.target.value) {
      const tags = e.target.value.split(' ');
      var item = this.state.item;
      item.tags = tags;
      this.setState({ item: item,tagstr:e.target.value } );        
    }
  };

  tagshandleChange = e => {
    var tagstr = e.target.value;
    this.setState({ tagstr: tagstr });
    //console.log("handleCange::" + e.target.name + ": " + e.target.value)
  };

  setskuPriceList = () => {
    var item = this.state.item;
    const inventoryval = isNaN(parseInt(document.getElementsByName('inventoryBox')[0].value))?0:parseInt(document.getElementsByName('inventoryBox')[0].value) ;
    const ActivityAmountvalue = isNaN(parseInt(document.getElementsByName('priceBox')[0].value))?0:parseInt(document.getElementsByName('priceBox')[0].value);
    const selectedpropertyValueId = this.state.selectedpropertyValueId;
    if (item.skuModule.productSKUPropertyList.length == selectedpropertyValueId.filter(function (value) { return value != null }).length) {
      //skuModule: { productSKUPropertyList: [{ skuPropertyName: "loading", skuPropertyValues: [{ skuPropertyImagePath: "", skuPropertyImageSummPath: "", propertyValueName: "" }] }], skuPriceList: [{ skuPropIds: "", skuVal: [{skuActivityAmount:{value:0}}]}]},
      //挿入する前に消す
      var skuPriceList = item.skuModule.skuPriceList.filter((_lst, c1) => {
        return !(_lst.skuPropIds == selectedpropertyValueId.join(','));
      });
      skuPriceList.push({
        skuPropIds: selectedpropertyValueId.join(','),
        skuVal: {
          inventory: inventoryval,
          skuActivityAmount: {
            currency: "JPY",
            value: ActivityAmountvalue,
          }
        }
      })
      item.skuModule.skuPriceList = skuPriceList;
      const skuAmountvalue = {
        min : ActivityAmountvalue ,
        max : ActivityAmountvalue,
        match : true,
        inventory : inventoryval,
      };
      this.setState({ item: item, skuAmountvalue: skuAmountvalue });
    }
  }
  
  priceBoxhandleonBlur = (e) => {
    this.setskuPriceList();
  }  

  priceBoxhandleChange = e => {
    this.priceBoxhandleonBlur(e);
    //console.log("handleCange::" + e.target.name + ": " + e.target.value)
  };
    //this.state.item.freightAmount.shipping_price
  freightBoxhandleonBlur = (e) => {
    const shipping_price = isNaN(parseInt(e.target.value.replace(/[^0-9]/g, ''))) ? 0 :parseInt(e.target.value.replace(/[^0-9]/g, ''));
    var item = this.state.item;
    if (shipping_price) {
      item.freightAmount.shipping_price = "￥"+ shipping_price.toLocaleString()
    }
    else {
      item.freightAmount.shipping_price = "送料無料";
    }
    this.setState({ item: item });
  }  

  freightBoxhandleChange = e => {
    this.freightBoxhandleonBlur(e);
    //console.log("handleCange::" + e.target.name + ": " + e.target.value)
  };

  inventoryBoxhandleonBlur = (e) => {
    this.setskuPriceList();
  }  

  inventoryBoxhandleChange = e => {
    this.inventoryBoxhandleonBlur(e);
    //console.log("handleCange::" + e.target.name + ": " + e.target.value)
  };

  shipping_deliveryBoxhandleonBlur = (e) => {
    const shipping_delivery = isNaN(parseInt(e.target.value.replace(/[^0-9]/g, ''))) ? 0 :parseInt(e.target.value.replace(/[^0-9]/g, ''));
    var item = this.state.item;
    item.freightAmount.shipping_delivery = shipping_delivery;
    this.setState({ item: item });
  }  

  shipping_deliveryBoxhandleChange = e => {
    this.shipping_deliveryBoxhandleonBlur(e);
    //console.log("handleCange::" + e.target.name + ": " + e.target.value)
  };

  statushandleChange = e => {
    if (e.target.checked) {
      this.setState({ status: "available" });
    }
    else {
      this.setState({ status: "edit" });      
    }
  }

  setFilelist = (lst, dir) => {
    switch (dir) {
      case 'images':
        var newarray = this.state.mainimages.concat();
        lst.forEach( (value) => { 
          newarray.push({ img: value, selectstatus: "enable" })
        }); 
        const savemainimages = JSON.parse(JSON.stringify(newarray));
        this.setState({ mainimages: newarray, savemainimages:savemainimages });
        break;
      case 'files':
        var item = this.state.item;
        var newarray = item.files.concat();
        lst.forEach( (value) => { 
          newarray.push({ file: value, selectstatus: "enable" })
        }); 
        item.files = newarray;
        this.setState({item:item} );
        break;
    }
  }


  exchangeUrl = (s1) => {
    //https://firebasestorage.googleapis.com/v0/b/joino-fc334.appspot.com/o/userfiles%2FpjN1W1a1V5ZF09iBPcSa5Zo5xb22%2F9HW73oYCBEXXXEDqo8ms%2Fimages%2FS58b6e5204cb14962976c89cda7c87a2b7.jpg?alt=media&token=047c1454-af3c-4929-a70b-584a291e289b
    if (s1) {
      if (s1.indexOf("firebasestorage.googleapis.com") == -1) {
        return s1.replace(/https:\/\/(\w+)/g, "https://qrparking.jp/api/$1");
      }
      return s1
    }
    return null;
  }
  

  getExt = (filename) => {
    var pos = filename.lastIndexOf('.');
    if (pos === -1) return '';
    return filename.slice(pos + 1);
  }

  //アップロード予定のファイル名の拡張子が許可されているか確認する関数
  checkExt = (filename) => {
  //アップロードを許可する拡張子
    var allow_exts = new Array('.jpg', '.jpeg', '.png');
    //比較のため小文字にする
    var ext = "." + this.getExt(filename).toLowerCase();
    //許可する拡張子の一覧(allow_exts)から対象の拡張子があるか確認する
    if (allow_exts.indexOf(ext) === -1) return false;
    return true;
  }

  render() {
    //console.log("render:::::::::::::"  + JSON.stringify(this.props))
    const { classes } = this.props;
    const selectedpropertyValueLst = this.state.selectedpropertyValueLst;
    const mainimages = this.state.mainimages;
    const files = this.state.item.files;
    const MarkupRatio = this.state.item.MarkupRatio?this.state.MarkupRatio:this.state.item.ambassador.MarkupRatio?this.state.item.ambassador.MarkupRatio:this.props.userdata.MarkupRatio?this.props.userdata.MarkupRatio:2;
    const ambassadordisplayName = this.state.item.ambassador.ambassadordisplayName;
    const ambassadorId = this.state.item.ambassador.uid;
    const title = this.state.item.pageModule.title.split("|")[0]
    const description = this.state.item.pageModule.description.split("|")[0];
    const tags = this.state.item.tags;
    const taglst = this.state.taglst;
    const tagstr = this.state.tagstr ? this.state.tagstr : tags ? tags.join(' ') : "";
    const commDlg = this.state.commDlg;
    const addOptDlg = this.state.addOptDlg;
    const addOptDlgIndex = this.state.addOptDlgIndex;
    const addLinDlg = this.state.addLinDlg;
    const deleteDlg = this.state.deleteDlg;
    const editOptDlg = this.state.editOptDlg;
    const editOptDlgIndex = this.state.editOptDlgIndex;
    const opt = this.state.opt;
    const deleteLinDlgIndex = this.state.deleteLinDlgIndex;
    const memo = this.state.item.memo?this.state.item.memo:"";
    const issuper = this.props.userdata.status == "super";
    const selectedpropertyValue = this.getselectedpropertyValue();

    // ここ整理すること const res = data.replace(/[^0-9]/g, '');
    //const description = this.state.item.pageModule.description;
    //const freightAmount = this.state.item.shippingModule.freightAmount.value;

    if (this.state.error == "no_data") {
      return (
        <div>
          <div class="overflow">
          <Paper className={classes.root} elevation={1}>
              <CircularProgress />
              <Typography component="p">
                データの構成が終わるまでお待ち下さい
              </Typography>
              <Typography component="p">
                数分待って変化がない場合は<br/>一度閉じてもう一度お試しください
              </Typography>
          </Paper> 
          </div>
        </div>
      );        
    }
    if (this.state.error) {
      return (
        <div>
          <div class="overflow">
          <Paper className={classes.root} elevation={1}>
              <Typography component="p">
                データの構成中にエラーかタイムアウトが発生しました<br />
                URLを確認して少しあとにもう一度お試しください
              </Typography>
              <Typography component="p">
                {this.state.error}
              </Typography>
          </Paper> 
          </div>
        </div>
      );        
    }
  
    var freightAmount = 0;
    var freightAmountString = '';
    if (this.state.item.freightAmount.shipping_price && parseInt(this.state.item.freightAmount.shipping_price.replace(/[^0-9]/g, ''))) {
      freightAmount = parseInt(this.state.item.freightAmount.shipping_price.replace(/[^0-9]/g, ''));
      freightAmountString = this.state.item.freightAmount.shipping_price.replace('配送', '送料').replace(' ','');
    }
    else {
      freightAmount = 0;
      freightAmountString = '送料無料';
    }
    

    //本日からdays日後のDate
    var nowDate = new Date();
    var days = this.state.item.freightAmount.shipping_delivery?this.state.item.freightAmount.shipping_delivery:21;
    var futureDate = new Date(nowDate.getTime() + days * 24 * 60 * 60 * 1000);
    var month = futureDate.getMonth() + 1;
    var day = futureDate.getDate() ;
    var arrivalExpectedDate = '到着予定日 ' + month + '月' + day + '日';
    var price = "loading";
    var pricetoLocaleString;
    var skuAmountvalue = this.chkskuPriceList(this.state.selectedpropertyValueId, this.state.item)
    if (skuAmountvalue.min == skuAmountvalue.max) {
      if (this.state.item.skuModule.productSKUPropertyList == undefined || this.state.item.skuModule.productSKUPropertyList.length == 0) {
        skuAmountvalue.match = true;
      }
      price = parseInt(skuAmountvalue.min * MarkupRatio, 10);
      pricetoLocaleString = "￥"+ price.toLocaleString();
    }
    else {
      pricetoLocaleString = "￥" + parseInt(skuAmountvalue.min * MarkupRatio, 10).toLocaleString() + " - ￥" + parseInt(skuAmountvalue.max * MarkupRatio, 10).toLocaleString();
    }

    return (
      <div>
        <div class="overflow"></div>
        <Paper className={classes.root} elevation={1}>
          <TextField
            id="ambassadordisplayName"
            name="ambassadordisplayName"
            label="表示名"
            style={{ margin: 8 }}
            helperText="表示名を編集してください"
            fullWidth
            margin="normal"
            variant="outlined"
            autoComplete='off'
            msg={console.log('render ambassadordisplayName:',ambassadordisplayName)}
            value={ambassadordisplayName}
            onChange={e => this.namehandleChange(e)}
            required="true"
          />
          <Typography component="p">
            表示名・アイコンを別アカウントに変更したい場合は左メニュー"アカウント・サービス"からログインしなおしてください
          </Typography>
          <TextField
            id="title"
            name="title"
            label="タイトル"
            style={{ margin: 8 }}
            helperText="商品タイトルを編集してください"
            fullWidth
            margin="normal"
            variant="outlined"
            autoComplete='off'
            msg={console.log('render titel:',this.state.item.pageModule.title)}
            value={title}
            onChange={e => this.pageModulehandleChange(e)}
            required="true"
          />
          <TextField
            id="description"
            name="description"
            label="説明"
            style={{ margin: 8 }}
            helperText="商品説明を編集してください"
            fullWidth
            margin="normal"
            variant="outlined"
            autoComplete='off'
            msg={console.log('render titel:',this.state.item.pageModule.description)}
            value={description}
            onChange={e => this.pageModulehandleChange(e)}
            required="true"
            multiline="true"
          />
          {price<50?<p>商品価格が設定できる最低価格{parseInt(50 * MarkupRatio, 10).toLocaleString()}を下回っているので登録できません</p>:<Button variant="contained" className='disable' onClick={this.submitonClick}>保存</Button>}
        </Paper>
        <div className={classes.textcenter}>
          <Paper className={classes.root} elevation={1}>
            <Slider ref={slider => (this.slider = slider)} {...settings}>
              {mainimages.map(_img => {
                if (_img.selectstatus == 'selected') {
                  return (
                    <div>
                      <ImageDialog src={this.exchangeUrl(_img.img)} alt="pictuer" />
                    </div>                    
                  )
                }
              })}
            </Slider>
            <br />
          </Paper>
          <Paper className={classes.root} elevation={1}>
          <Typography component="p">
              下の写真を選んで表示順序が変更できます
            </Typography>
          {mainimages.map( (_img,index) => {
              return (
                <Button variant="contained" className={_img.selectstatus == 'selected' ? classes.bottonstyleselected : _img.selectstatus == 'disable' ? classes.bottonstyledisable : classes.bottonstyle} id={_img.propertyValueDisplayName} onClick={this.imgselectButtonClick.bind(this, index)}>
                  <img src={this.exchangeUrl(_img.img)}  width='50px' height='50px' class='mainimages'/>
                </Button>
              )
          })}
            <FileUpload userId={ambassadorId} itemId={this.state.item.itemId} fileList={this.setFilelist} acceptFile = 'image/*' dir = 'images' maxFileSize = '10485760' className={classes.FileUpload} />
            {price<50?<p>商品価格が設定できる最低価格{parseInt(50 * MarkupRatio, 10).toLocaleString()}を下回っているので登録できません</p>:<Button variant="contained" className='disable' onClick={this.submitonClick}>保存</Button>}
          </Paper>

          <Paper className={classes.root} elevation={1}>
            <Typography component="p">
              オプションをテストしてください。<br />
              どのオプションを選んでも単一価格が表示できない商品は公開しないでください（オプションの一部が欠品の場合は可）
            </Typography>
            <CommonDialog
                msg={"削除しますか？"}
                isOpen={deleteDlg}
                doYes={() => { this.itemdeletehandle() }}
                doNo={() => { this.setState({ deleteDlg: false }) }}
            />
            <Typography component="p">
              {this.state.item.skuModule.productSKUPropertyList
                ? this.state.item.skuModule.productSKUPropertyList.map((_lst, i1) => {
                return (
                  <div key={i1} className={_lst.skuPropertyId == 200007763 ? classes.displaynone : ''}>
                    <p>{_lst.order + " : " + _lst.skuPropertyName + "(" + _lst.skuPropertyId + ")"}:　{selectedpropertyValueLst[i1]}
                      {issuper
                        ? <>
                            <Button
                              className={classes.lineremovebottn}
                              onClick={this.linedeletebottnhandle.bind(this, i1)}
                              >
                              <RemoveCircleIcon
                                className={classes.removebottn}
                              />
                            </Button> 
                        </>
                        : <></>
                      }                      
                    </p>
                    <div className={classes.flexbox}>
                    { _lst.skuPropertyValues.map((_lst2, i2) => {
                      //console.log('render::' + i1+':'+ i2 +'selectstatus:'+_lst2.selectstatus);
                      return (
                        <div className={classes.optbox}>
                        {issuper
                          ? <>
                            <Button
                                className={classes.removebottn}
                                onClick = { this.optdeletebottnhandle.bind(this, i1, i2)}
                              >
                              <RemoveCircleIcon
                                className={classes.removebottn}
                                />
                              </Button>
                             </> :<></>
                        }
                        <Button variant="contained" className={_lst2.selectstatus == 'selected' ? classes.bottonstyleselected : _lst2.selectstatus == 'disable' ? classes.bottonstyledisable : classes.bottonstyle} id={_lst2.propertyValueId} onClick={this.selectButtonClick.bind(this, i1, i2)}>
                          ID: {_lst2.propertyValueId}<br />
                          {_lst2.skuPropertyImageSummPath
                            ? <><img src={this.exchangeUrl(_lst2.skuPropertyImageSummPath)} alt={_lst2.propertyValueDisplayName} width='50px' height='50px' /> <br />{_lst2.propertyValueDisplayName}</>
                            : _lst2.propertyValueDisplayName
                          }
                        </Button>
                        {issuper
                          ? <>
                            <Button
                                className={classes.editbottn}
                                onClick={this.opteditbottnhandle.bind(this, i1, i2, { img: _lst2.skuPropertyImageSummPath, name: _lst2.propertyValueDisplayName })}
                              >
                              <EditIcon
                                className={classes.editbottn}
                                />
                            </Button>
                          </>
                          : <></>
                        }  
                        </div>  
                      )
                    })}
                    </div>
                    {issuper
                      ? <>
                        <Button
                          id={"opt" + i1 }
                          name={"opt" + i1 }
                          sx={{ fontSize: "35px" }}
                          className={classes.itemaddbottn}
                          onClick={this.optaddbottnhandle.bind(this,i1)}
                        >
                          <AddBoxIcon
                            id={"_opt" + i1 }
                            name={"_opt" + i1 }
                            sx={{ fontSize: "35px" }}
                            className={classes.itemaddbottn}
                            onClick={this.optaddbottnhandle.bind(this,i1)}
                          />
                        </Button>
                      </>
                      : <></>}
                  </div>
                );
              }) : ""}
              オプション項目を追加します
            </Typography>
            {issuper
              ? <>
                <Button
                    className={classes.lineaddbottn}
                    onClick={this.lineaddbottnhandle.bind(this)}
                >
                  <AddBoxIcon
                    sx={{ fontSize: "35px" }}
                    className={classes.lineaddbottn}
                    onClick={e => {
                      this.lineaddbottnhandle.bind(this);
                      }}
                  />
                </Button>
                <AddOptionDialog
                  msg={"項目を追加します"}
                  isOpen={addLinDlg}
                  submit={this.addlin.bind(this)}
                  cancel={() => { this.setState({ addLinDlg: false }) }}
                  ambassadorId={ambassadorId }
                  itemId={this.state.item.itemId}
                  catNo={""}
                  dialogstyle={"addLinDlg"}
                />
                {(editOptDlg || addOptDlg)?
                <AddOptionDialog
                  key={ addOptDlgIndex}
                  msg={addOptDlg? "オプションを追加します::" + addOptDlgIndex :"オプションを編集します::" + editOptDlgIndex.join(',')}
                  isOpen={ editOptDlg || addOptDlg}
                  submit={this.addopt.bind(this)}
                  cancel={() => { this.setState({ addOptDlg: false, editOptDlg: false,editOptDlgIndex: [], addOptDlgIndex: 0}) }}
                  catNo={addOptDlgIndex}
                  editOptDlgIndex={editOptDlgIndex}
                  opt={ opt }
                  // message={()=>{console.log("opt::: " + i1)}}
                  dialogstyle={"addOptDlg"}
                />:<></>
                }
                <br />
                <TextField
                  id="priceBox"
                  name="priceBox"
                  label="原価"
                  style={{ margin: 8 }}
                  helperText=""
                  margin="normal"
                  variant="outlined"
                  autoComplete='off'
                  value={selectedpropertyValue.skuVal.skuActivityAmount ? selectedpropertyValue.skuVal.skuActivityAmount.value :selectedpropertyValue.skuVal.skuAmount ? selectedpropertyValue.skuVal.skuAmount.value : ""}
                  onBlur={e => this.priceBoxhandleonBlur(e)}
                  onChange={e => this.priceBoxhandleChange(e)}
                  required="true"
                  multiline="false"
                />
                <TextField
                  id="freightBox"
                  name="freightBox"
                  label="送料"
                  style={{ margin: 8 }}
                  helperText=""
                  margin="normal"
                  variant="outlined"
                  autoComplete='off'
                  value={freightAmount}
                  onBlur={e => this.freightBoxhandleonBlur(e)}
                  onChange={e => this.freightBoxhandleChange(e)}
                  required="true"
                  multiline="false"
                />
                <TextField
                  id="inventoryBox"
                  name="inventoryBox"
                  label="在庫数"
                  style={{ margin: 8 }}
                  helperText="9999で無制限"
                  margin="normal"
                  variant="outlined"
                  autoComplete='off'
                  value={selectedpropertyValue.skuVal.inventory}
                  onBlur={e => this.inventoryBoxhandleonBlur(e)}
                  onChange={e => this.inventoryBoxhandleChange(e)}
                  required="true"
                  multiline="false"
                />
                <TextField
                  id="freightBox"
                  name="freightBox"
                  label="配達日数"
                  style={{ margin: 8 }}
                  helperText=""
                  margin="normal"
                  variant="outlined"
                  autoComplete='off'
                  value={days}
                  onBlur={e => this.shipping_deliveryBoxhandleonBlur(e)}
                  onChange={e => this.shipping_deliveryBoxhandleChange(e)}
                  required="true"
                  multiline="false"
                />

              </>
              : <></>
            }

            <Typography variant="headline" component="h3">
              {pricetoLocaleString}
            </Typography>
            
            <Typography component="p">
                {freightAmountString}
            </Typography>
            <Typography component="p">
              { arrivalExpectedDate }
            </Typography>
            <Typography component="p">
              数量：１　/ 在庫数：{ skuAmountvalue.match&&skuAmountvalue.inventory?skuAmountvalue.inventory==9999?"在庫あり":skuAmountvalue.inventory:skuAmountvalue.inventory==0?"在庫がありません":"在庫確認中"}
            </Typography>
            <Typography component="p">
              { selectedpropertyValueLst.join(',')}
            </Typography>
            <FormGroup className={classes.tagsbox} style={{ "flex-flow": "row wrap" }}>
            {
              Array.isArray(taglst) ?
                taglst.map((_e, i1) => {
                  return (
                    <FormControlLabel style={{ "fontSize": "12px", "margin": "0" }} control={<Checkbox  style={{ "fontSize": "12px", "margin": "0" }} checked={this.state.item.tags?this.state.item.tags.some((e) => e == _e)?true:false:false} onChange={e => this.taghandleChange(e)} value={_e} />} label={_e} />
                  );
                }) : ""                    
            }
            <Button variant="contained" className={classes.bottonstyle} onClick={() => { this.setState({ commDlg: true }) }}>
              チェックしたハッシュをリストから削除<br />（入力中のデータは全てキャンセルされる）
            </Button>
            <CommonDialog
                msg={"削除しますか？"}
                isOpen={commDlg}
                doYes={() => { this.setTagList() }}
                doNo={() => { this.setState({ commDlg: false }) }}
            />
            </FormGroup>
            <TextField
              id="tags"
              name="tags"
              label="ハッシュタグ"
              style={{ margin: 8 }}
              helperText="#はなし 半角スペースで区切り"
              fullWidth
              margin="normal"
              variant="outlined"
              autoComplete='off'
              value={tagstr}
              onBlur={e => this.tagshandleonBlur(e)}
              onChange={e => this.tagshandleChange(e)}
              required="true"
              multiline="true"
            />
            <TextField
              id="memo"
              name="memo"
              label="メモ"
              style={{ margin: 8 }}
              helperText="メモは外部に公開されません"
              fullWidth
              margin="normal"
              variant="outlined"
              autoComplete='off'
//              msg={console.log('render memo:',this.state.item.memo)}
              value={memo}
              onChange={e => this.texthandleChange(e)}
              required="true"
              multiline="true"
              />
            {price<50?<p>商品価格が設定できる最低価格{parseInt(50 * MarkupRatio, 10).toLocaleString()}を下回っているので登録できません</p>:<Button variant="contained" className='disable' onClick={this.submitonClick}>保存</Button>}
          </Paper>
          <Paper className={classes.root} elevation={1}>
          <Typography component="h2">
              購入後ダウンロード
          </Typography>
          <Typography component="p">
              ファイルを選んで表示順序が変更できます
          </Typography>
          <Typography component="p">
              選ばれていないファイルはダウンロードできません
          </Typography>
            {files.map( (file,index) => {
                return (
                  <Button variant="contained" className={file.selectstatus == 'selected' ? classes.bottonstyleselected : file.selectstatus == 'disable' ? classes.bottonstyledisable : classes.bottonstyle} id={file.propertyValueDisplayName} onClick={this.filesselectButtonClick.bind(this, index)}>
                    {this.checkExt(decodeURI(file.file.split("%2F")[4].split("?")[0])) ?
                      <>
                        < MakeThumbnail imgUrl={this.exchangeUrl(decodeURI(file.file))} width="50" height="50" errorimg="/images/errorimg.jpg" />
                        <br />
                      </>
                      : <></>}
                    {decodeURI(file.file.split("%2F")[4].split("?")[0])}
                  </Button>
                )
            })}
            <FileUpload userId={ambassadorId} itemId={this.state.item.itemId} fileList={this.setFilelist} acceptFile = "" dir = 'files' maxFileSize = '10485760000' className={classes.FileUpload} />
            <FormControlLabel style={{ "fontSize": "12px", "margin": "0" }} control={<Checkbox  style={{ "fontSize": "12px", "margin": "0" }} checked={this.state.status=="available" ? true:false} onChange={e => this.statushandleChange(e)} value="available" />} label="公開する" />
            {price<50?<p>商品価格が設定できる最低価格{parseInt(50 * MarkupRatio, 10).toLocaleString()}を下回っているので登録できません</p>:<Button variant="contained" className='disable' onClick={this.submitonClick}>保存</Button>}
          </Paper>
        </div>
      </div>
    );
  }
}

// Material-ui関連
itemEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(itemEdit);