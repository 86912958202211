import React, { useEffect, useState } from 'react'
//const width = 50
//const height = 50

export default function MakeThumbnail(props: any) {
    const [png, setPng] = useState<string | null>(null)
    const { imgUrl, width, height ,errorimg} = props;

    useEffect(() => {
        try {
            const canvasElem = document.createElement('canvas')
            canvasElem.width = width
            canvasElem.height = height
            const ctx = canvasElem.getContext('2d')
            // draw
            if (ctx) {
                // Imageオブジェクトを生成する
                const img = new Image()
                img.crossOrigin = "anonymous"
                img.src = imgUrl
                //Imageの読み込みが完了した際の処理
                img.onload = () => {
                    // コンテキストにImageオブジェクトの画像を描画する
                    ctx.drawImage(img, 0, 0, width, height)
                    setPng(canvasElem.toDataURL())
                }
                // エラー処理
                img.onerror = () => {
                    throw new Error('error')
                }
            }
        }
        catch (error) {
            setPng(errorimg);
        }
    }, [])

    return (
        <img src={png ? png : errorimg} width={width} height={height } />
    )
}
