import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
const auth = firebase.auth();

import StripeConnectButton from "../components/StripeConnectButton";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

import { FiLogOut } from "react-icons/fi";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      margin: 10,
    },
    textContent: {
      // 新しいスタイル
      textAlign: "left",
      flex: 1,
      display: "inline-block", // テキストコンテンツをインラインブロックにします
      //width: "calc(100% - 135px)", // 画像の幅とマージンを差し引いた幅
      width: "90%",
      verticalAlign: "top", // テキストを上揃えにします
    },
    textLeft: {
      textAlign: "left",
      flex: 1,
    },
    textCenter: {
      textAlign: "center",
      flex: 1,
    },
    img100: {
      width: "100%",
    },
    imgCenter: {
      // 新規追加
      width: "90%",
      //margin: "15px", // テキストとの間隔
    },
    customTypography: {
      fontSize: "1.25rem", // ここで望むフォントサイズに調整します。h4とh5の中間のサイズに設定
      fontWeight: "bold", // ここで文字を太くします
    },
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
    clearBoth: {
      clear: "both",
    },
  });

interface CheckListProps extends WithStyles<typeof styles> {}

interface CheckListState {
  error: string;
  warning: string;
  info: string;
  success: string;
  urlcopy: boolean;
  user?: firebase.User | null;
}

class CheckList extends React.Component<CheckListProps, CheckListState> {
  constructor(props: CheckListProps) {
    super(props);
    this.state = {
      error: "",
      warning: "",
      info: "",
      success: "",
      urlcopy: false,
    };
  }

  componentDidMount = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          user: user,
        });
        console.log("log in");
      } else {
        console.log("not log in");
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={`${classes.root} ${classes.textLeft}`}>
        <Typography
          variant="h3"
          className={`${classes.textContent} ${classes.customTypography}`}
        >
          QRパーキング開業までのチェックリスト
        </Typography>
        <br />
        {this.state.error && <Alert severity="error">{this.state.error}</Alert>}
        {this.state.warning && (
          <Alert severity="warning">{this.state.warning}</Alert>
        )}
        {this.state.info && <Alert severity="info">{this.state.info}</Alert>}
        {this.state.success && (
          <Alert severity="success">{this.state.success}</Alert>
        )}

        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            現地でのチェックリスト:
          </Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            <input type="checkbox" />
            現地は携帯電話の電波が来ていますか？
            <br />
            （docomo,au,softbankなど）
          </Typography>
          <br />
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            <input type="checkbox" />
            現地は造成が必要ですか？
            <br />
            （道路との間に高低差がある、敷地の境界に傾斜がある、など）
          </Typography>
          <br />
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            <input type="checkbox" />
            路側帯にクルマを乗り入れるスペースはありますか？
          </Typography>
          <br />
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            <input type="checkbox" />
            敷地内にクルマが動けなくなる窪みや隆起はありませんか？
          </Typography>
          <br />
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            <input type="checkbox" />
            敷地内に通行の邪魔になる粗大ごみや雑草はありませんか？
          </Typography>
          <br />
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            <input type="checkbox" />
            敷地をすでに利用している人に周知してありますか？
          </Typography>
        </Paper>
        <br />
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            制度上のチェックリスト:
          </Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            <input type="checkbox" />
            固定資産税通知書で該当地が農地のままになっていませんか？
            <br />
            （農地として税制優遇を受けている場合、固定資産税が増える可能性があります）
            <br />
            （登記上農地のままになっているのは問題ありません）
          </Typography>
          <br />
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            <input type="checkbox" />
            路側帯の通行に許可が必要な場合があります
            <br />
            （道路管理者で確認できます。交差点内では警察の許可がない限りクルマの出入りができません。）
          </Typography>
          <br />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CheckList);
