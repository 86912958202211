import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import firebase from '../Firebase';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// スタイル
const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      margin: 10,
    },
    textLeft: {
      textAlign: 'left',
    },
    textCenter: {
      textAlign: 'center',
    },
  
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
});


class MailSignUp extends React.Component {

    state = {
        loading: false, //処理中にボタンにspinner表示する制御用
        values:{email:"",password:"",tel:""}
    }

    _isMounted = false;

    //Submitされたら
    handleOnSubmit = (values) => {
        //spinner表示開始
        if (this._isMounted) this.setState({ loading: true });
        //新規登録処理
        firebase.auth().createUserWithEmailAndPassword(values.email, values.password)
            .then(res => {
                //正常終了時
                //spinner表示終了
                if (this._isMounted) this.setState({ loading: false });
                //Homeに移動
                this.props.history.push("/"); //history.pushを使うためwithRouterしている
            })
            .catch(error => {
                //異常終了時
                if (this._isMounted) this.setState({ loading: false });
                alert(error);
            });
    }

    componentDidMount = () => {
        this.state.loading = true;
    }

    componentWillUnmount = () => {
        this.state.loading = false;
    }

    handleChange = (e) => {
        const params = this.state.values;
        params[e.target.name] = e.target.value;
        this.setState({ values:params });
        };

    render() {
        const { classes } = this.props;

        return (
            <div>
            <div className={classes.root} >
                <h2>新規登録</h2>
                <div className={classes.textLeft}>
                    <Paper className={classes.root} elevation={1}>
                        <Typography variant="headline" component="h3">
                                メールアドレスで登録
                        </Typography>
                        <form className={classes.root} noValidate autoComplete="off" onSubmit={this.handleOnSubmit}>
                            <TextField
                            type="email"
                            id="email"
                            name="email"
                            label="メールアドレス"
                            style={{ margin: 8 }}
                            margin="normal"
                            variant="outlined"
                            helperText="メールアドレス"
                            value={this.state.values.email}
                            autoComplete='off'
                            onChange={this.handleChange}
                            required="true"
                            />
                            <TextField
                            type="password"
                            name="password"
                            id="password"
                            name="password"
                            label="パスワード"
                            style={{ margin: 8 }}
                            margin="normal"
                            variant="outlined"
                            helperText="パスワード4文字以上"
                            value={this.state.values.password}
                            autoComplete='off'
                            onChange={this.handleChange}
                            required="true"
                            />
                            <TextField
                            type="tel"
                            name="tel"
                            id="tel"
                            label="携帯電話番号"
                            style={{ margin: 8 }}
                            margin="normal"
                            variant="outlined"
                            helperText="携帯電話番号"
                            value={this.state.values.tel}
                            autoComplete='off'
                            onChange={this.handleChange}
                            required="true"
                            />
                            <div style={{ textAlign: 'center' }}>
                                <Button color="success" type="submit" disabled={this.state.loading}>
                                        {this.state.loading?<CircularProgress />:""}
                                        新規登録
                                </Button>
                             </div>
                        </form>
                    </Paper>
                </div>
                </div>
            </div>
        );
    }
}

// Material-ui関連
MailSignUp.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };
  
  
// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(MailSignUp);
