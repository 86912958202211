import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// スタイル
const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  titleImage: {
    width: "100%",
    maxWidth: 700,
  },
  textLeft: {
    textAlign: "left",
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
});

class SpecifiedCommercialTransactionAct extends React.Component {
  render() {
    // Material-ui関連
    const { classes } = this.props;

    return (
      <div>
        <img
          src="/images/QRparkingLOGOIMG.jpg"
          alt="title"
          className={classes.titleImage}
        />

        <h2>特定商取引法に基づく表記</h2>
        <div className={classes.textLeft}>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              事業者の名称
            </Typography>
            <Typography component="p">
              鵜飼不動産株式会社　QRパーキング事業部
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              事業者の所在地
            </Typography>
            <Typography component="p">
              〒502-0813 岐阜県岐阜市福光東２丁目８番５号
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              事業者の連絡先　（システム障害などのご連絡）
            </Typography>
            <Typography component="p">
              tel:058-295-6688 e-mail:info01@qrparking.jp
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              運営統括責任者
            </Typography>
            <Typography component="p">鵜飼　智司</Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              現地駐車場での駐車場管理者へのご連絡
            </Typography>
            <Typography component="p">
              駐車場ごとの案内ページでご確認いただけます
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              サポートの対応時間
            </Typography>
            <Typography component="p">
              平日 9:00 - 17:00 (土日祝日　を除く)
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              商品以外の追加手数料
            </Typography>
            <Typography component="p">
              追加で請求される手数料等はありません。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              返金ポリシー
            </Typography>
            <Typography component="p">
              一度お支払いを頂いた駐車料金につきましては精算・返金には応じられません。
            </Typography>
            <Typography component="p">
              商品購入においてはご購入後２週間以内の欠陥のお申し出を除いて返品・返金には応じられません。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              代金の支払方法と時期
            </Typography>
            <Typography component="p">
              支払方法：クレジットカードによる決済がご利用頂けます。
              <br />
              支払時期：カード決済時にお支払いが確定致します。
              <br />
              終日料金の設定のある場合、終日とは翌日の２４時間までを指します。時間貸しで設定した料金は２４時間が経過するまでは、終日料金を上回る請求はされません。
              <br />
              月極料金の設定のある場合、１ヶ月はカレンダーの同日までを指します。終日で設定した料金は一ヶ月を経過するまでは、月極料金を上回る請求はされません。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              出庫手続き
            </Typography>
            <Typography component="p">
              出庫する場合は必ず出庫手続きをお願いします。
              <br />
              出庫処理をしていない場合、駐車駐車料金は延長加算され続けますのでご注意ください。
              <br />
              必ず駐車受付完了画面、メール、SMSにより通知されるURLリンクのページを開いて出庫処理を行ってください。
              <br />
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              <a
                id="OverchargeSettlementPolicy"
                name="OverchargeSettlementPolicy"
              >
                追加駐車料金の精算
              </a>
            </Typography>
            <Typography component="p">
              前払い分の予定駐車時間を過ぎた場合、追加のお支払いが必要になります。
              <br />
              追加のお支払いは自動的にカード決済されません。
              <br />
              出庫処理をしていない場合、駐車駐車料金は加算され続けますのでご注意ください。
              <br />
              必ず駐車受付完了画面、メール、SMSにより通知されるURLリンクのページを開いて精算処理を行ってください。
              <br />
              未精算のまま駐車場を離れてしまった場合も精算処理を行うことができます。
              <br />
              時間貸しで設定した料金は２４時間が経過するまでは、終日料金を上回る請求はされません。
              <br />
              終日で設定した料金は一ヶ月経過するまでは、月極料金を上回る請求はされません。
              <br />
              無断駐車・未精算には開示請求により所有者に請求を行います。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              <a id="Refundsupport" name="Refundsupport">
                場内での事故・事件の対応
              </a>
            </Typography>
            <Typography component="p">
              場内での事件や事故に関して、駐車場管理者及びQRパーキング運営は一切の責任を負いません。
              <br />
              お客様の被ったいかなる損害につきましても駐車料金の返金以上の対応は一切できかねます。
            </Typography>
          </Paper>
        </div>
      </div>
    );
  }
}

// Material-ui関連
SpecifiedCommercialTransactionAct.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(
  SpecifiedCommercialTransactionAct
);
