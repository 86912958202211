import React from 'react';
import { Link,} from 'react-router-dom';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

//import { useParams } from 'react-router-dom';
//import firebase, { db } from '../Firebase';

import { db } from '../Firebase';

// react-slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../slick-item.css"

import Button from '@material-ui/core/Button'

//import Checkout from '../components/Checkout';
import PurchaseButton from '../components/PurchaseButton';
import { ImageDialog } from '../components/ImageDialog.tsx';


//const MarkupRatio = 1;

// スタイル
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
  marginreftten:{
    marginLeft: 10
  },
  bottonstyle: {
    margin: 10,
    border: 'none',
    '-webkit-filter': 'grayscale(0)',
    '-moz-filter': 'grayscale(0)',
    '-ms-filter': 'grayscale(0)',
    '-o-filter': 'grayscale(0)',
    filter: 'grayscale(0)',
    opacity:1,
  },
  bottonstyleselected: {
    margin: 10,
    'border-style': 'solid',
    'border-width': '2px',
    'border-color': 'red',
    '-webkit-filter': 'grayscale(0)',
    '-moz-filter': 'grayscale(0)',
    '-ms-filter': 'grayscale(0)',
    '-o-filter': 'grayscale(0)',
    filter: 'grayscale(0)',
    opacity:1,
  },
  bottonstyledisable: {
    margin: 10,
    border: 'none',
    '-webkit-filter': 'grayscale(100)',
    '-moz-filter': 'grayscale(100)',
    '-ms-filter': 'grayscale(100)',
    '-o-filter': 'grayscale(100)',
    filter: 'grayscale(100)',
    opacity:0.5,
  },
  displaynone: {
    display: 'none'
  },
});

// react-slick
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  centerMode: false,
  centerPadding: '20px',
  fade: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

class I extends React.Component {
  state = {
    item: {
      itemId: 'initializeing',
      imageModule: { imagePathList: ["/images/Preloader_8.gif"] }, //Preloader_8.gif
      pageModule: { title: "loading" ,description:"loading"},
      priceModule: { minAmount: { value: 0 }, minActivityAmount: { value: 0 }},
      skuModule: { productSKUPropertyList: [{ skuPropertyName: "loading",order:"",skuPropertyId:"", skuPropertyValues: [{ skuPropertyImagePath: "", skuPropertyImageSummPath: "", propertyValueName: "" }] }], skuPriceList: [{ skuPropIds: "", skuVal: [{skuActivityAmount:{value:0}}]}]},
      freightAmount: { shipping_price: "", shipping_info: "", shipping_delivery: "" },
      ambassador: {MarkupRatio:2,ambassadordisplayName:""},
    },
    ambassador: {MarkupRatio:1},
    mainimages: ["/images/Preloader_8.gif"],
    skuAmountvalue: { min:0, max:0, match:false, inventory:-1 },
    selectedpropertyValueId: [],
    selectedpropertyValueLst:[],
  }

  //データ取得
  //uidで指定したメンバーの値を取得
  getItem = async (ambassadorid, itemId) => {
    const docRef = db.collection("users").doc(ambassadorid).collection("items").doc(itemId);
    const ambassadorRef = db.collection("users").doc(ambassadorid);
    //const docRef = db.collection("item").doc(itemId);
    const doc = await docRef.get();
    const ambassadordoc = await ambassadorRef.get();
    var skuAmountvalue = { min:0, max:0, match:false, inventory:-1 };
    
        //ドキュメントの存在確認
    if (doc.exists) {
      const dat = doc.data();
      const ambassadordat = ambassadordoc.data(); 
      console.log("doc.exists itemId:" + dat.itemId);
      var mainimages = dat.imageModule.imagePathList;
      /*
      dat.skuModule.productSKUPropertyList.map(_lst => {
        _lst.skuPropertyValues.map(_lst2 => {
          _lst2.selected = false;
        });
      });
      */
      if ('minActivityAmount' in dat.priceModule) {
        skuAmountvalue.min = dat.priceModule.minActivityAmount.value;
      }
      else if ('minAmount' in dat.priceModule) {
        skuAmountvalue.min = dat.priceModule.minAmount.value;
      }
      if ('maxActivityAmount' in dat.priceModule) {
        skuAmountvalue.max = dat.priceModule.maxActivityAmount.value;
      }
      else if ('maxAmount' in dat.priceModule) {
        skuAmountvalue.max = dat.priceModule.maxAmount.value;
      }
      if (skuAmountvalue.min == 0 && skuAmountvalue.max !=0) {
        skuAmountvalue.min = skuAmountvalue.max;
      }
      else if (skuAmountvalue.max == 0 && skuAmountvalue.min !=0){
        skuAmountvalue.max = skuAmountvalue.min;
      }
      if (dat.status == "available") {
        this.setState({
          item: dat,
          ambassador: ambassadordat,
          mainimages: mainimages,
          skuAmountvalue:skuAmountvalue,
        });
      } else {
        this.setState({
          item_no_data: "true"
        });          
      }
    } else {
      console.log("else")
      this.setState({
        item_no_data: "true"
      });
    }
  }

  //値を取得
  componentDidMount = () => {
    this.getItem(this.props.match.params.userId,this.props.match.params.itemId);
  }

  chkskuPriceList = (selectedpropertyValueId, dat) => {
    var res = { min:0, max:0, match:false, inventory:-1 };
    for (let i = 0; i < dat.skuModule.skuPriceList.length; i++){
      if ((dat.skuModule.skuPriceList[i].skuVal.inventory > 0 || (!selectedpropertyValueId[0])) && this.searchlist(dat.skuModule.skuPriceList[i].skuPropIds, selectedpropertyValueId)) {
        if (dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount) {
          if (dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value < res.min || res.min == 0) {
            res.min = dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value;
          }
          if (dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value > res.max || res.max == 0) {
            res.max = dat.skuModule.skuPriceList[i].skuVal.skuActivityAmount.value;
          }            
        } else {
          if (dat.skuModule.skuPriceList[i].skuVal.skuAmount.value < res.min || res.min == 0) {
            res.min = dat.skuModule.skuPriceList[i].skuVal.skuAmount.value;
          }
          if (dat.skuModule.skuPriceList[i].skuVal.skuAmount.value > res.max || res.max == 0) {
            res.max = dat.skuModule.skuPriceList[i].skuVal.skuAmount.value;
          }            
        }
        if (res.inventory < dat.skuModule.skuPriceList[i].skuVal.inventory) {
          res.inventory = dat.skuModule.skuPriceList[i].skuVal.inventory;//暫定的な在庫数をセット          
        }
      }          
    }
    console.log('dat.skuModule.productSKUPropertyList.length::' +dat.skuModule.productSKUPropertyList.length + ' selectedpropertyValueId::'+ selectedpropertyValueId.join(','));
    if (dat.skuModule.productSKUPropertyList.length == selectedpropertyValueId.filter(function (value) { return value != null}).length) {
      res.match = true;
    }
    else {
      res.inventory = -1
    }
    //console.log('res1::' + res.join(','));
    return res
  }

  searchlist = (skuPropIds, selectedpropertyValueId) => {
    var res = true;
    for (let i = 0; i < selectedpropertyValueId.length; i++) {
      if (selectedpropertyValueId[i]!=null) {
        if (skuPropIds.indexOf(selectedpropertyValueId[i]) == -1) {
          res = false;
        };
      }
    }
    console.log('res2::' + res + ' :: '+skuPropIds+'::'+selectedpropertyValueId.join(','));
    return res;
  }

  exchangeUrl = (s1) => {
    //https://firebasestorage.googleapis.com/v0/b/qrparking-7996b.appspot.com/o/userfiles%2FpjN1W1a1V5ZF09iBPcSa5Zo5xb22%2F9HW73oYCBEXXXEDqo8ms%2Fimages%2FS58b6e5204cb14962976c89cda7c87a2b7.jpg?alt=media&token=047c1454-af3c-4929-a70b-584a291e289b
    if (s1) {
      if (s1.indexOf("firebasestorage.googleapis.com") == -1) {
        return s1.replace(/https:\/\/(\w+)/g, "https://qrparking.jp/api/$1");
      }
      return s1
    }
    return null;
  }

  adda = (str) => {
    var regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
    var url = str.match(regexp_url);
    if (url) {
      return <a href = {url[0]} target= { "_blank" } > { url[0].substr(0,25) + '...' } </a >    
    }
    return str;
  }  


  selectButtonClick = (i1, i2, e) => {
    var dat = this.state.item;
    var mainimages = this.state.item.imageModule.imagePathList;
    var selectedpropertyValueId = this.state.selectedpropertyValueId;
    var skuAmountvalue = this.state.skuAmountvalue;
    var selectedpropertyValueLst = this.state.selectedpropertyValueLst;
    var spVId;
    var skuAv;
    this.slider.slickGoTo(0);
    if ((dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].selectstatus != 'disable' && dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].selectstatus != 'selected') /*|| (dat.skuModule.productSKUPropertyList[i1].skuPropertyId == 200007763 && (dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].propertyValueId == 201336100 || dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].propertyValueId == 201441035))*/) {
      selectedpropertyValueId[i1] = dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].propertyValueId;
      //console.log("selectstatus:::::" + dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].selectstatus);
    }
    else if(dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].selectstatus == ('selected')) {
      selectedpropertyValueId[i1] = null;
    }
    dat.skuModule.productSKUPropertyList.map((_lst,c1) => {
      _lst.skuPropertyValues.map((_lst2, c2) => {
        if ((_lst.skuPropertyId == dat.skuModule.productSKUPropertyList[i1].skuPropertyId && _lst2.propertyValueId == dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].propertyValueId) || (_lst.skuPropertyId == 200007763 && (_lst2.propertyValueId == 201336100 ||_lst2.propertyValueId == 201441035))) {
          if ((_lst2.selectstatus != 'disable' && _lst2.selectstatus != 'selected') || (_lst.skuPropertyId == 200007763 && (_lst2.propertyValueId == 201336100 || _lst2.propertyValueId == 201441035))) {
            //console.log("_lst2.selectstatus:" + _lst2.selectstatus);
            //console.log("selected selectedpropertyValueId:" + selectedpropertyValueId.join(','));
            _lst2.selectstatus = 'selected';
            selectedpropertyValueId[c1] = _lst2.propertyValueId;//送信国が中国の場合
            selectedpropertyValueLst[c1] = _lst2.propertyValueDisplayName;
            skuAmountvalue = this.chkskuPriceList(selectedpropertyValueId, dat);
            //console.log(c1+':'+ c2 +'selectstatus1::selected');
            if (_lst2.skuPropertyImagePath) {
              //mainimages[0] = 'https://qrparking.jp/api/' + _lst2.skuPropertyImagePath.replace('https://',"").replace("_50x50.jpg","").replace("_640x640.jpg","")+"_640x640.jpg";              
              mainimages[0] = _lst2.skuPropertyImagePath.replace("_50x50.jpg","_640x640.jpg");
            }
          }
          else if (_lst2.selectstatus == 'selected') {
            _lst2.selectstatus = 'chk';
            //console.log(c1+':'+ c2 +'selectstatus1::chk');
            selectedpropertyValueLst[c1] = null;
            skuAmountvalue = this.chkskuPriceList(selectedpropertyValueId, dat);
            if (_lst2.skuPropertyImagePath) {
              mainimages[0] = dat.imageModule.imagePathList[0];
            }
          }
        }
        else if (_lst2.selectstatus != 'selected' && _lst.skuPropertyId == dat.skuModule.productSKUPropertyList[i1].skuPropertyId && _lst2.propertyValueId != dat.skuModule.productSKUPropertyList[i1].skuPropertyValues[i2].propertyValueId) {
          if (selectedpropertyValueId[c1]) {
            _lst2.selectstatus = 'disable';
            //console.log(c1+':'+ c2 +'selectstatus2::disable');
          }
          else {
            _lst2.selectstatus = 'chk';
            //console.log(c1+':'+ c2 +'selectstatus2::chk');
          }
        }
        else {
          if (selectedpropertyValueId[c1] == null) {
            _lst2.selectstatus = 'chk';
            //console.log(c1+':'+ c2 +'selectstatus3::chk');
          }
        }
        if (_lst2.selectstatus == 'chk') {
          spVId = selectedpropertyValueId.slice(0, selectedpropertyValueId.length);
          spVId[c1] = _lst2.propertyValueId;
          //console.log("spVId:" + spVId.join(','));
          skuAv = this.chkskuPriceList(spVId, dat);
          //console.log('skuAv' + skuAv.join(','));
          if (skuAv.min == 0) {
            _lst2.selectstatus = 'disable';
            //console.log(c1+':'+ c2 +'selectstatus4::disable');
          }
          else {
            _lst2.selectstatus = 'enable';
            //console.log(c1+':'+ c2 +'selectstatus4::enable');
          }        
        }
      });
    });
    dat.priceModule = {
      maxActivityAmount: { value: skuAmountvalue.max },
      minActivityAmount: { value: skuAmountvalue.min },
    }
    this.setState({
      item: dat,
      mainimages: mainimages,
      skuAmountvalue: skuAmountvalue,
      selectedpropertyValueLst:selectedpropertyValueLst,
    });
  }


  render() {
    const { classes } = this.props;
    //const cthis = this;
    //const bottonstyle = this.state.bottonstyle;
    const selectedpropertyValueLst = this.state.selectedpropertyValueLst;
    const mainimages = this.state.mainimages;
    const MarkupRatio = this.state.item.MarkupRatio?this.state.item.MarkupRatio:this.state.ambassador.MarkupRatio;
    const ambassadordisplayName = this.state.item.ambassador.ambassadordisplayName;

    // ここ整理すること const res = data.replace(/[^0-9]/g, '');
    
    var title = this.state.item.pageModule.title ? this.state.item.pageModule.title : "";
    var description = this.state.item.pageModule.description ? this.state.item.pageModule.description.split(/(\n)/).map((item, index) => {
      return (
        <>
          { item.match(/\n/) ? <br /> : this.adda(item) }
        </>
      );
    }): "";
    //const description = this.state.item.pageModule.description;
    //const freightAmount = this.state.item.shippingModule.freightAmount.value;
    var freightAmount = 0;
    var freightAmountString = '';
    if (this.state.item.freightAmount.shipping_price) {
      freightAmountString = this.state.item.freightAmount.shipping_price.replace('配送', '送料').replace(' ','');
      freightAmount = parseInt(this.state.item.freightAmount.shipping_price.replace(/[^0-9]/g, ''));
      freightAmount = freightAmount ? freightAmount : 0;
    }
    else {
      freightAmount = 0;
      freightAmountString = '送料無料';
    }

    //送料無料対策
    freightAmountString = '送料無料';

    //本日から　i　日後のDate
    var nowDate = new Date();
    var days = this.state.item.freightAmount.shipping_delivery ? this.state.item.freightAmount.shipping_delivery : 21;
    var futureDate = new Date(nowDate.getTime() + days * 24 * 60 * 60 * 1000);
    var month = futureDate.getMonth() + 1;
    var day = futureDate.getDate();
    var arrivalExpectedDate = '到着予定日 ' + month + '月' + day + '日';

    var price;
    var pricetoLocaleString;
    var skuAmountvalue = this.state.skuAmountvalue;
    if (skuAmountvalue.min == skuAmountvalue.max) {
/*
      if (this.state.item.skuModule.productSKUPropertyList == undefined || this.state.item.skuModule.productSKUPropertyList.length == 0) {
        skuAmountvalue.match = true;
      }
*/
    //送料無料対策
      price = parseInt((skuAmountvalue.min * MarkupRatio) + freightAmount, 10);
      pricetoLocaleString = "￥"+ price.toLocaleString();
    }
    else {
    //送料無料対策
      pricetoLocaleString = "￥" + parseInt((skuAmountvalue.min * MarkupRatio)+freightAmount, 10).toLocaleString() + " - ￥" + parseInt((skuAmountvalue.max * MarkupRatio)+freightAmount, 10).toLocaleString();
    }
    if (this.state.item_no_data) {
      return (
        <div>
          <div class="overflow">
          <Paper className={classes.root} elevation={1}>
              <Typography component="p">
                申し訳ございません<br />
                お探しの商品は現在販売されておりません<br />
                左上のメニューバーから類似の商品をお探しください
              </Typography>
              <Typography component="p">
                {this.state.error}
              </Typography>
          </Paper> 
          </div>
        </div>
      );
    }
    return (
      <div>
        <div class="overflow">{ title }</div>
        <div className={classes.textcenter}>
          <Paper className={classes.root} elevation={1}>
            <Slider ref={slider => (this.slider = slider)} {...settings}>
              {this.state.mainimages.map(_img => {
                return (
                  <div>
                    <ImageDialog src={this.exchangeUrl(_img)} alt="pictuer" />
                  </div>
                )
              })}
            </Slider>
            <br />
          </Paper>
          <Paper className={classes.root} elevation={1}>
          <Typography component="p">
              {description}
            </Typography>
            <Typography component="p">
              {this.state.item.skuModule.productSKUPropertyList?this.state.item.skuModule.productSKUPropertyList.map((_lst,i1) => {
                return (
                  <div className={_lst.skuPropertyId == 200007763 ? classes.displaynone : ''}>
                    <p>{_lst.skuPropertyName}:{selectedpropertyValueLst[i1]}</p>
                    {_lst.skuPropertyValues.map((_lst2, i2) => {
                      //console.log('render::' + i1+':'+ i2 +'selectstatus:'+_lst2.selectstatus);
                      return (
                        <Button variant="contained" className={_lst2.selectstatus == 'selected' ? classes.bottonstyleselected : _lst2.selectstatus == 'disable' ? classes.bottonstyledisable : classes.bottonstyle} id={_lst2.propertyValueDisplayName} onClick={this.selectButtonClick.bind(this, i1, i2)}>
                          {_lst2.skuPropertyImageSummPath
                            ? <img src={this.exchangeUrl(_lst2.skuPropertyImageSummPath)} alt={_lst2.propertyValueDisplayName}  width='50px' height='50px'/>
                            : _lst2.propertyValueDisplayName
                          }
                        </Button>
                      )
                    })}
                  </div>
                );
              }):""}
            </Typography>
            <Typography variant="headline" component="h3">
              {pricetoLocaleString}
            </Typography>
            <Typography component="p">
                {freightAmountString}
            </Typography>
            <Typography component="p">
              { arrivalExpectedDate }
            </Typography>
            <Typography component="p">
              数量：１　/ 在庫数：{ skuAmountvalue.match&&skuAmountvalue.inventory?skuAmountvalue.inventory==9999?"在庫あり":skuAmountvalue.inventory:skuAmountvalue.inventory==0?"在庫がありません":"在庫確認中"}
            </Typography>
            <Typography component="p">
              { selectedpropertyValueLst.join(',')}
            </Typography>
            <React.Fragment>
              <p>納期は目安です　<a href="https://qrparking.jp/specifiedcommercialtransactionact#arrivalExpectedDate" target="_blank">遅延や返金に関するポリシー</a>を必ずご確認ください</p>
              <p>購入ボタンはオプションをすべて選択すると押せるようになります</p>
              <p>ワンサイズ・ワンカラーでも確認のため選択してください</p>
              {//unhandled error StripeInvalidRequestError: You passed an empty string for 'line_items[0][price_data][product_data][description]'. We assume empty values are an attempt to unset a parameter; however 'line_items[0][price_data][product_data][description]' cannot be unset. You should remove 'line_items[0][price_data][product_data][description]' from your request or supply a non-empty value. 
              }
              {(skuAmountvalue.match && skuAmountvalue.inventory && price)
                ? <PurchaseButton price={price} name={title} image={this.exchangeUrl(mainimages[0])} description={selectedpropertyValueLst && selectedpropertyValueLst.length>0?selectedpropertyValueLst.join(','):"non_selection"} ambassadorid={this.props.match.params.userId} ambassadordisplayName={ambassadordisplayName} productid={this.props.match.params.itemId} arrivalExpectedDate={arrivalExpectedDate} freightAmountString={freightAmountString} label="購入"/>
                : <Button variant="contained" className='disable'>購入</Button>
              }
            <p>購入ボタンを押したら決済画面が表示されるまで少しお待ち下さい</p>
            <p>欠品の場合は選択しても購入ボタンが押せません</p>
            <p>ページの有効期限が切れた場合は反応がなくなるので再表示してください</p>
            </React.Fragment>
          </Paper>
        </div>
        <Typography component="subtitle2">
          <Link to="/specifiedcommercialtransactionact">特定商取引法に基づく表記</Link>
        </Typography><br/>　
      </div>
    );
  }
}

// Material-ui関連
I.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(I);