//
// 本来のFileUpload.tsxから改変してあるので注意
// https://qiita.com/tetsurotayama/items/5129f0cfb21f9ec9b9a0
import React, { useState, useEffect, useCallback } from "react";

//import * as firebase from 'firebase/app';
//import { firebaseApp } from './firebase_config';
import firebase from '../Firebase';
import "firebase/storage";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import {
  Typography,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  Button,
  IconButton,
  Paper,
  CircularProgress
} from '@material-ui/core/';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InfoIcon from '@material-ui/icons/Info';

import { useDropzone } from 'react-dropzone'
import { DirectionsBike } from "@mui/icons-material";

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      margin: 'auto',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      '& > *': {
        margin: theme.spacing(3),
      },
      width: '100%',
      'max-width':'100%',
    },
    dropzone: {
      width: "100%",
      boxSizing: "border-box",
      borderWidth: 2,
      borderColor: "#666666",
      borderStyle: "dashed",
      borderRadius: 5,
      verticalAlign: "top",
      "font-size":"14px"
    },
    thumbsContainer: {
      width:"100%"
//      marginTop: 16,
    },
    gridList: {
      width: "100%",
//      height: 50,
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    titleBar: {
      background:
        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
      color: 'white',
    },
    upButton: {
      color: "secondary",
//      margin: theme.spacing(3),
      margin: 0,
    },
    circular: {
      textAlign: 'center',
    }
  }),
);

// propsは無し
type Props = {};

/*
// Dropzoneの設定
const acceptFile = 'image/*';
const maxFileSize = 1048576;
*/

// previewを追加
type MyFile = File & {
  preview: string;
};

export default function FileUpload(props: any) {
  console.log("FileUpload page start.");

  // State
  const [files, setFiles] = useState<MyFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { userId, itemId, fileList, acceptFile, dir, maxFileSize } = props;
  const classes = useStyles(props);

  /*
  ドロップした時の処理
  */
  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log('onDrop');

    // previewの追加
    setFiles(acceptedFiles.map(
      file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

  }, [])

  const sha256 = async (text: string) => {
    const uint8 = new TextEncoder().encode(text);
    const digest = await crypto.subtle.digest('SHA-256', uint8);
    const res = Array.from(new Uint8Array(digest)).map(v => v.toString(16).padStart(2, '0')).join('');
    return res
  }

  const getImageFileType = (file: any) => {
    const result = file.type.indexOf( 'image/' );
    if (result != -1) {
      return <img src={file.preview} alt={file.name} />
    }
    //console.log("fileType=" + fileType + " headerStr=" + headerStr + " headerHex=" + headerHex);
    return <></>;
  } //END getImageFileType()

  // Dropzone
  const { getRootProps, getInputProps, isDragActive }
    = useDropzone({ onDrop, accept: acceptFile , minSize: 0, maxSize: maxFileSize })

  const onUpload = async () => {
    console.log('onUpload start');

    // ローディングをOn。progressを初期化
    setUploading(true);
    setProgress(0);

    function uploadImageAsPromise(file:any) {
      console.log('uploadImageAsPromise start');

      // アップロード先のファイルパスの作成
      //const file_name = async () => { await sha256(file.name.split('.')[0]) + file.name.split('.').slice(-1)[0]; }
      const file_name = file.name;
      const storageRef = firebase.storage().ref().child('/userfiles/' + userId + '/' + itemId + '/' + dir + '/' + file_name);

      return new Promise(function (resolve, reject) {
        //Upload file
        var task = storageRef.put(file);

        //Update progress bar
        task.on(firebase.storage.TaskEvent.STATE_CHANGED,
          function progress(snapshot) {
            var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(percent + "% done");
          },
          function error(err) { // 失敗時
            console.log("upload error");
            reject(err);
          },
          function complete() { // 成功時
            console.log('upload complete.');
            task.then(function (snapshot: firebase.storage.UploadTaskSnapshot) {
              resolve(snapshot.ref.getDownloadURL());
            })
          }
        );

      }).then(function (downloadURL) {
        console.log("Finished uploading file: " + file_name);

        // progressを更新する
        setProgress(oldProgress => (oldProgress + 1));
        return downloadURL;
      }).catch(function () {
        console.log("Error:uploadImageAsPromise");
      });
    }

    // 複数のファイルアップロードをPromise.allで並列に実行する
    const result = await Promise.all(files.map((file) => { return uploadImageAsPromise(file); }));

    console.log("Upload result");
    console.log(result);
    fileList(result,dir);

    // ローディングを終了し、リストを空に
    setUploading(false);
    setProgress(0);
    setFiles([]);

    //alert("送信されました");

  }

  // アップロード中はCircularを表示する
  if (uploading === true) {
    const percent = Math.round((progress / files.length) * 100)
    console.log("Loadingの表示。Progreass:" + progress + " Percent:" + percent);

    return (
      <Grid container className={classes.root} spacing={3} justify="center">
        <Grid item xs={6} className={classes.root}>
          <Paper variant="outlined" elevation={3} className={classes.paper}>
            <CircularProgress className={classes.circular} variant="determinate" value={percent} />
          </Paper>
        </Grid>
      </Grid>
    )

  } else {
    // タイルを敷き詰められるように、一部画像のサイズは大きくする
    const tile_cols = 3;
    let tile_featured: any[] = [];
    switch (files.length % tile_cols) {
      case 0:
        tile_featured = [];
        break;
      case 1:
        tile_featured = [0, files.length - 1];
        break;
      case 2:
        tile_featured = [0];
        break;
    }

    // サムネイルの作成
    const thumbs = files.map((file, index) => (

      <GridListTile key={file.preview} cols={tile_featured.indexOf(index) >= 0 ? 2 : 1} rows={1}>
        {getImageFileType(file)}
        <GridListTileBar
          title={decodeURI(file.name)}
          subtitle={file.size}
          actionIcon={
            <IconButton aria-label={`star ${file.name}`} className={classes.icon}>
              <InfoIcon />
            </IconButton>
          }
          actionPosition="left"
          className={classes.titleBar}
        />
      </GridListTile>
    ));

    const diabled_button = (files.length === 0);

    return (
      <Grid container className={classes.root} spacing={3} justify="center">
        <Grid item xs={6}  style={{ 'width':'100%','maxWidth':'100%','flexBasis':'auto'}} > 
          <Paper variant="outlined" elevation={3} className={classes.paper}>
            <div>
              <Paper className={classes.dropzone} {...getRootProps()}>
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <><p>ドロップしてください ...</p><p>↓ UPLOAD ボタンでアップロード開始</p></> :
                    <><p>画像等追加はタップ・クリック・ドラッグ</p><p>↓ UPLOAD ボタンでアップロード開始</p></>
                }
              </Paper>
              <Button onClick={onUpload} variant="outlined" color="primary" disabled={diabled_button} className={classes.upButton} startIcon={<CloudUploadIcon />} >Upload</Button>
              <aside className={classes.thumbsContainer}>
                <GridList cellHeight={200} className={classes.gridList} cols={tile_cols}>
                  {thumbs}
                </GridList>
              </aside>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}