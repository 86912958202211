import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Route関連
import { Link } from 'react-router-dom'

// リンクの扱いを変更
const isExternalUrl = url => /^https?:\/\//.test(url);

const ResponsiveDrawerListItem = ({ to, onClick, icon, text }) => (
  <ListItem
    button
    component={isExternalUrl(to) ? 'a' : Link} // 外部リンクの場合は 'a' タグを使用
    to={!isExternalUrl(to) ? to : undefined}   // 外部リンクではない場合にのみ 'to' 属性を設定
    href={isExternalUrl(to) ? to : undefined}  // 外部リンクの場合には 'href' を使用
    onClick={onClick}
  >
  <ListItemIcon>{icon}</ListItemIcon>
  <ListItemText primary={text} />
  </ListItem>
);

ResponsiveDrawerListItem.propTypes = {
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
};

export default ResponsiveDrawerListItem;
