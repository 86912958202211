import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';

const styles = {
  // Cards
  cateboton: {
    'min-width': '0px',
    'padding':'0px',
    'font-size': '12px',
    'position': 'relative',
    'top': '-3px',
  },
  catemenu: {
    'height': '12px',
    'line-height':'0px',
    'padding':'0px 3px 5px 1px',
    'border-radius': '8px',
    'border': '1px solid rgba(128,0,128,0.7)',
    'display': 'inline-block',
    'border-width': '1px',
    'margin': '2px 2px',
    'background-clip': 'padding - box',
  },
  catemenucurrent: {
    'height': '12px',
    'line-height':'0px',
    'padding':'0px 3px 5px 1px',
    'border-radius': '8px',
    'border': '1px solid rgba(128,0,128,0.7)',
    'display': 'inline-block',
    'border-width': '1px',
    'margin': '2px 2px',
    'background-clip': 'padding - box',
    'background-color': 'rgba(255, 255, 128, 0.5)',
},
  cateicon: {
    'color': 'rgba(0,0,0,0.5)',
    'width': '14px',
    'font-size':'20px',
    'min-width': '0px',
    'padding': '0px 8px 1px 8px',
    'top': '-1px',
    'left': '0px',
    'position': 'relative',
  },
  mylisticon: {
    'color': 'rgba(0,0,0,0.5)',
    'width': '14px',
    'font-size':'20px',
    'min-width': '0px',
    'padding': '0px 8px 1px 8px',
    'top': '-1px',
    'left': '0px',
    'position': 'relative',
  },
  cateiconfav: {
    'color': 'rgba(255,0,200,0.5)',
    'width': '14px',
    'font-size':'20px',
    'min-width': '0px',
    'padding': '0px 8px 1px 8px',
    'top': '-1px',
    'left': '0px',
    'position': 'relative',
  }
};

function MenuItem(props) {
  // <MenuItem CatId="0" icon="home" categoryEnName="Root" categoryName="Top" />
  const { categoryId, icon, categoryEnName, categoryName, classes } = props;
  var catdat={categoryId:categoryId, categoryEnName:categoryEnName, categoryName:categoryName,}
  const seticon = (icon) => {
    switch (icon) {
      case 'home':
        return (
          <HomeIcon className={classes.cateicon} color="secondary" />
        )
      case 'mylist':
        return (
          <ViewListRoundedIcon sx={{ fontSize:"12px" }} className={classes.cateicon} />
        )
      case 'upper':
        return (
          <ArrowUpwardIcon className={classes.cateicon} />
       )
      case 'fav':
        return (
          <FavoriteRoundedIcon className={classes.cateiconfav} />
        )
      case 'curfav':
        return (
          <FavoriteRoundedIcon className={classes.cateiconfav} />
        )
      case 'curdef':
        return (
          <FavoriteBorderRoundedIcon className={classes.cateicon} />
        )
  
      default:
        return (
          <FavoriteBorderRoundedIcon className={classes.cateicon} />
        )
    }
  }

  return (
      <Typography className={icon=='curfav'||icon=='curdef'?classes.catemenucurrent :classes.catemenu}>
        <Button className={classes.cateicon} onClick={()=> props.iconOnClick(catdat) }>{seticon(icon)}</Button>
        <Button className={classes.cateboton} onClick={()=> props.categoryNameClick(catdat) }>{categoryName}</Button>
      </Typography>
  );
}

MenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuItem);