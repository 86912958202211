import * as actionTypes from '../utils/actionTypes';
import axios from 'axios' // API取得用


// Notification操作
export const setNotification = (variant, message) => ({
  type: actionTypes.SET_NOTIFICATION,
  variant: variant,
  message: message,
});
export const closeNotification = (variant, message) => ({
  type: actionTypes.CLOSE_NOTIFICATION,
});

// 非同期取得操作
export const getItems = (year, cours) => {
  return (dispatch) => {
    dispatch(getItemsRequest());
    
    return axios.get('https://api.moemoe.tokyo/anime/v1/master/' + year + '/' + cours)
      .then(response => dispatch(getItemsSuccess(response.data)))
      .catch(error => dispatch(getItemsFailure(error)))
  };
};

export const getItemsRequest = () => ({
  type: actionTypes.GET_ITEMS_REQUEST,
});

export const getItemsSuccess = (json) => ({
  type: actionTypes.GET_ITEMS_SUCCESS,
  items: json,
});

export const getItemsFailure = (error) => ({
  type: actionTypes.GET_ITEMS_FAILURE,
  error: error,
});